import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Context } from '../../common/Context/Context'
import Logo from '../../Assets/Logo/Asset 4.svg'


const Header = () => {
    const [SideMenuOpen, setSideMenuOpen] = useState(false)
    const { user, setuser, usersPath, guestPath } = useContext(Context)
    const navigate = useNavigate()
    const location = useLocation()


    useEffect(() => {
        setSideMenuOpen(false)
    }, [location])

    useEffect(() => {
        console.log('current path ---------------', location.pathname)
        // console.log(!usersPath.some(itm => itm.path.replace(':id').includes(location.pathname)))
        // return
        if (user == 'agent' || user == 'student' || user == 'staff') {

            if (usersPath.some(itm => itm.path.replace(':id').includes(!location.pathname))) {
                navigate('/')
            }
        }
        else {
            if (guestPath.some(itm => itm.path.replace(':id').includes(!location.pathname))) {
                navigate('/')
            }
        }

    }, [location.pathname])

    return (
        <header>
            <div id="header-sticky" class={`header__area ${location.pathname == '/' ? 'header__transparent__home' : 'header__transparent'} header__padding-2 `}>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-4 col-6">
                            <div class="header__left d-flex">
                                <div class="logo">
                                    <Link to="/">
                                        <img src={Logo} style={{ height: '1.5rem' }} alt="logo" />
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-8 col-6">
                            <div class="header__right d-flex justify-content-end align-items-center">
                                <div class="main-menu main-menu-2 d-none d-xl-block">
                                    <nav id="mobile-menu">
                                        <ul>
                                            <li>
                                                <Link style={location.pathname == '/' ? {} : { color: '#0e1133' }} to="/">Home</Link>

                                            </li>
                                            <li>
                                                <Link style={location.pathname == '/' ? {} : { color: '#0e1133' }} to="/university">universities</Link>

                                            </li>
                                            <li>
                                                <Link style={location.pathname == '/' ? {} : { color: '#0e1133' }} to="/course">Courses</Link>

                                            </li>
                                            {/* {(!user || user == 'student') &&
                                            <li>
                                                <Link style={location.pathname == '/' ? {} : { color: '#0e1133' }} to="/counselling">Counselling</Link>

                                            </li>
                                            } */}
                                            <li>
                                                <Link style={location.pathname == '/' ? {} : { color: '#0e1133' }} to="/blog">Blogs</Link>

                                            </li>



                                            {(user == 'agent' || user == 'student' || user == 'staff') &&
                                                <>
                                                    {!user.includes('student') ?
                                                        <li>
                                                            <Link style={location.pathname == '/' ? {} : { color: '#0e1133' }} to="/applications">Applications</Link>
                                                        </li>

                                                        : <li>
                                                            <Link style={location.pathname == '/' ? {} : { color: '#0e1133' }} to="/my-application">My Applications</Link>
                                                        </li>
                                                    }
                                                    <li>

                                                        <Link style={location.pathname == '/' ? {} : { color: '#0e1133' }} to="/profile">Profile</Link>
                                                    </li>





                                                </>
                                            }


                                            {/* <li><button type='button' className='btn btn-danger'>{user ? user : "Guest"}</button></li> */}



                                        </ul>
                                    </nav>
                                </div>
                                {!(user == 'agent' || user == 'student' || user == 'staff') ?
                                    <>
                                        <div class="header__btn header__btn-2 ml-50 d-none d-sm-block"><Link style={location.pathname == '/' ? {} : { color: '#0e1133' }} class="e-btn"
                                            to="/sign-in">Sign In</Link></div>
                                        <div class="sidebar__menu d-xl-none">
                                            <div class="sidebar-toggle-btn ml-30" onClick={() => setSideMenuOpen(true)} id="sidebar-toggle"><span
                                                class="line"></span><span class="line"></span><span class="line"></span>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div class="header__btn header__btn-2 ml-50 d-none d-xl-block ">
                                            <Link class="e-btn" to="/add-new-application">Add New Application</Link>
                                        </div>
                                        <div style={{ marginLeft: '1rem' }}>
                                            <Link style={location.pathname == '/' ? {} : { color: '#0e1133' }} to={'/profile'} state={{ activeTab: 'Notification' }}>
                                                <img src="https://img.icons8.com/ios-glyphs/30/0095C7/appointment-reminders--v2.png" />
                                            </Link>
                                        </div>
                                        <div class="sidebar__menu d-xl-none">
                                            <div class="sidebar-toggle-btn ml-30" onClick={() => setSideMenuOpen(true)} id="sidebar-toggle"><span
                                                class="line"></span><span class="line"></span><span class="line"></span>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class={SideMenuOpen ? "sidebar__area open" : "sidebar__area"}>
                <div class="sidebar__wrapper">
                    <div class="sidebar__close"><button onClick={() => setSideMenuOpen(false)} class="sidebar__close-btn" id="sidebar__close-btn"><span>
                        <i><svg width={10}
                            aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times"
                            class="svg-inline--fa fa-times fa-w-11 " role="img"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                            <path fill="currentColor"
                                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                            </path>
                        </svg></i></span><span>close</span></button></div>
                    <div class="sidebar__content">
                        <div class="logo mb-40"></div>
                        <div class="mm-menu">
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>

                                </li>
                                <li>
                                    <Link to="/university">universities</Link>

                                </li>
                                <li>
                                    <Link to="/course">Courses</Link>

                                </li>
                                {/* {(!user || user == 'student') &&
                                <li>
                                    <Link to="/counselling">Counselling</Link>

                                </li>
                                } */}
                                <li>
                                    <Link to="/blog">Blogs</Link>

                                </li>
                                {!user &&
                                    <div className="header__btn header__btn-2 d-block d-xl-none ">
                                        <Link className="e-btn w-100" to="/sign-in">Sign In</Link>
                                    </div>
                                }

                                {/* <li><Link to="/about">About</Link></li> */}


                                {user &&
                                    <>
                                        {(user == 'agent' || 'staff') ?
                                            <li>
                                                <Link to="/applications">Applications</Link>
                                            </li>

                                            : <li>
                                                <Link to="/my-application">My Applications</Link>
                                            </li>
                                        }
                                        <li>

                                            <Link to="/profile">Profile</Link>
                                        </li>

                                        <div class="header__btn header__btn-2 d-block d-xl-none ">
                                            <Link class="e-btn" to="/add-new-application">Add New Application</Link>
                                        </div>


                                    </>
                                }




                            </ul>
                        </div>
                        {/* <div class="sidebar__search p-relative mt-40 ">
                            <form action="#"><input type="text" placeholder="Search..." /><button type="submit"><i><svg
                                aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search"
                                class="svg-inline--fa fa-search fa-w-16 " role="img"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="currentColor"
                                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z">
                                </path>
                            </svg></i></button></form>
                        </div> */}
                    </div>
                </div>
            </div>
            <div class="body-overlay"></div>
        </header>
    )
}

export default Header