import { GET_API } from "./APIS"
import { protocol, host } from './config'
export const ManagePagination = async (path, param) => {


    let newPath = path.replace(protocol, '').replace(host, '')
    console.log({ newPath })


    // // console.log('apiv1.vidyaportal.com' === 'apiV1.vidyaportal.com')
    // console.log( newPath.includes('apiV1.vidyaportal.com') )
    // console.log(  newPath.replace('apiV1.vidyaportal.com', '1') )

    // return
    let result = GET_API('', param, newPath)
    return await result.then((res) => {

        if (res) {
            return res
        } else {
            return 0
        }
    })
}