import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Img5 from '../../Assets/img/course/teacher/teacher-1.jpg'
import { Context } from '../../common/Context/Context'

const EventDetail = (props) => {
    const { user } = useContext(Context)
    const { pathname } = useLocation()

    // console.log(props.selectedCourse.includes(props.CourseData.id))
    // console.log(props.CourseData.id)
    // console.log({user})

    return (

        <div className="course__item white-bg fix">
            <div className="course__item white-bg fix" >
                <div className="course__thumb w-img p-relative fix">

                    {/* <Link to={`#`}> */}
                        {props.image &&
                            <img src={props.image} style={{
                                // height: '400px',
                                width: '100%',
                                objectFit: 'cover'
                            }} />
                        }
                    {/* </Link> */}
                    <div className="course__tag"><a className='text-white'>Event Date : {props.event_date }</a></div>
                </div>
                <div className="course__content">
                    
                    
                    <h3 className="course__title">
                    
                        Title : { props.name}
                       
                    </h3>

                    
                    <div className="course__meta d-flex align-items-center justify-content-between">
                            <div className="course__lesson d-flex justify-content-between w-100" style={{ flexWrap: 'wrap' }}>
                                <span style={{ marginRight: '1rem' }}>
                                    <p>{props.description}</p>
                                    </span>
                                    </div>

                        </div>
                        <div className="course__meta d-flex align-items-center justify-content-between">

                        <a href={`${props.item.redirect_link}`} className="btn btn-primary">{props.item.button_name?props.item.button_name:"See More"}</a>
                        
                        {props.item.latitude&&<a  className="btn btn-primary" target='_blank' href={`https://www.google.com/maps/@${props.item.latitude},${props.item.longitude},15z`} >Get Location</a>}
                        </div>

                        
                    
                </div>
                
            </div>
        </div>
    )

}


export default EventDetail