import React, { useContext, useEffect, useState } from 'react'
import { RadioButtonField } from '../../Components/InputField/CheckBoxField'
import UniversityDetail from '../Home/UniversityDetail'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { BreadCrumb } from '../../Components/BreadCrumb/BreadCrumb'
import { UseForm } from '../../common/Hooks/UseForm'
import { Context } from '../../common/Context/Context'
import Collapsible from 'react-collapsible'
import { ManageCourse } from './CourseLogic'
import NoData from '../../Components/NoData/NoData'
import Skeleton from 'react-loading-skeleton'
import { ManagePagination } from '../../Functionalities/PaginationLogic'
import { protocol } from '../../Functionalities/config'
import Loading from '../../Components/Lottie/Loading'
import { RWebShare } from "react-web-share";




const Courses = () => {
    const Navigate = useNavigate()
    const { pathname } = useLocation()

    const { id } = useParams()
    const { user, isMobile } = useContext(Context)
    const [data, setdata] = useState({
        level: '',
        country: '',
        intakes: '',
        course: ''
    })
    const [searchText, setsearchText] = useState('')
    const [CourseData, setCourseData] = useState([])
    const [allCourse, setallCourse] = useState([])
    const [loading, setloading] = useState(true)
    const [Prev, setPrev] = useState('')
    const [Next, setNext] = useState('')
    const [currentPage, setcurrentPage] = useState('')
    const [TotalPages, setTotalPages] = useState('')
    const [selectShareActive, setselectShareActive] = useState(false)
    const [selectCourses, setselectCourses] = useState([])
    const [selectAll, setselectAll] = useState(true)
    useEffect(() => {
        GetCourse()
    }, [data])

    useEffect(() => {
        console.log(pathname)
        const newpath = pathname
        console.log(`${pathname}?${data.country ? `country=${data.country}` : ''}${data.intakes ? `intake=${data.intakes}` : ''}${data.searchText ? `search_text=${data.searchText}` : `&search_text=${data.course.replace('...', '')}`}`)

        // Navigate(`/ ${ newpath }`)
    }, [data])


    const GetCourse = () => {
        let params = '&country=' + data.country + '&intake=' + data.intakes + '&level=' + data.level
        params += searchText ? '&search_text=' + searchText : '&search_text=' + data.course.replace('...', '')

        let result = ManageCourse('get', '', params)
        result.then((res) => {

            if (res.data.results) {
                console.log(res)
                setCourseData(res.data.results)
                setloading(false)
                setNext(res.data.next)
                setPrev(res.data.previous)
                setcurrentPage(res.data.current_page)
                setTotalPages(res.data.num_pages)
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }
    const handleSearch = (e) => {
        e.preventDefault()
        // document.querySelectorAll('input[type=radio]').forEach(itm => itm.id == data.country ? itm.checked = true : false)
        // console.log(document.querySelectorAll('input[type=radio]').forEach(itm => itm))
        GetCourse()
    }

    const handleCLick = () => {
        user ? Navigate('/apply-course') : Navigate('/sign-in')
    }

    const HandlePrev = () => {
        let path = Prev.replace('http:', protocol.replace('//', ''))

        let result = ManagePagination(path, '')

        result.then(function (response) {
            console.log((response.data));
            if (response.data.results) {
                setCourseData(response.data.results)
                setNext(response.data.next)
                setPrev(response.data.previous)
                setloading(false)
                setcurrentPage(response.data.current_page)
                setTotalPages(response.data.num_pages)
                window.scrollTo(0, 0)

            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const HandleNext = () => {
        let path = Next.replace('http:', protocol.replace('//', ''))

        let result = ManagePagination(path, '')

        result.then(function (response) {
            console.log((response.data));
            if (response.data.results) {
                setCourseData(response.data.results)
                setNext(response.data.next)
                setPrev(response.data.previous)
                setloading(false)
                window.scrollTo(0, 0)
                setcurrentPage(response.data.current_page)
                setTotalPages(response.data.num_pages)

            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }


    const Intakes = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]


    const Countries = [
        "Canada",
        'Ireland',
        'Australia',
        'United',
        'Singapore',
        'USA',
    ]

    const Level = [
        'UG',
        'PG',
        'DIPLOMA'
    ]


    const clearFilter = (e) => {
        e.preventDefault()

        setsearchText('');
        setdata({
            ...data,
            level: '',
            country: '',
            intakes: '',
            course: ''
        })

        document.querySelectorAll('input[type=radio]').forEach(el => el.checked = false);
    }

    const courses = ['BA', 'MA', 'BBA', 'MBA', 'BSc', 'MSc', 'LLB', 'LLM', 'BEng', 'MEng']

    useEffect(() => {
        console.log(selectCourses)
    }, [selectCourses])

    // const shareData = {
    //     title: 'MDN',
    //     text: 'Learn web development on MDN!',
    //     url: 'https://developer.mozilla.org'
    // }
    const [shareData, setshareData] = useState("")

    let array = []





    useEffect(() => {
        let share = ''

        const SelectedCourseArr = CourseData.filter(itm => selectCourses.includes(itm.id))

        for (let i = 0; i < SelectedCourseArr.length; i++) {
            share = share +
                SelectedCourseArr[i].course_name + '\n' +
                SelectedCourseArr[i].by_university.name + '\n' +
                'Tution Fees:' + '\n' +
                SelectedCourseArr[i].fees + '\n' +
                'Course Duration :' + '\n' +
                SelectedCourseArr[i].duration + '\n' +
                'Academic Course Level :' + '\n' +
                SelectedCourseArr[i].level + '\n' +
                'Location :' + '\n' +
                SelectedCourseArr[i].by_university.address + '\n' +
                'Intake:' + SelectedCourseArr[i].intakes + '\n' + '\n' + '\n' + '\n'


            // console.log('course', i + share)
        }
        // console.log('af loop------------------', share)
        setshareData(share)
    }, [selectCourses])



    const handleSelectAll = (e) => {
        e.preventDefault()
        setselectAll(!selectAll)
        setselectCourses([])
        console.log("Select all ", CourseData)

        // let share="";
        for (let i = 0; i < CourseData.length; i++) {
            //     console.log(CourseData[i].course_name)
            array.push(CourseData[i].id)


            //     share = share +
            //     CourseData[i].course_name + '\n' +
            //     CourseData[i].by_university.name + '\n' +
            //     'Tution Fees:' + '\n' +
            //     CourseData[i].fees + '\n' +
            //     'Course Duration :' + '\n' +
            //     CourseData[i].duration + '\n' +
            //     'Academic Course Level :' + '\n' +
            //     CourseData[i].level + '\n' +
            //     'Location :' + '\n' +
            //     CourseData[i].by_university.address + '\n' +
            //     'Intake:' + CourseData[i].intakes + '\n' + '\n' + '\n' + '\n'

            //     console.log("i  ",i)    
            // }
            // console.log("Share ",share)
            // setshareData(share)

            // for (let i = 0; i < CourseData.length; i++) {
            //     array.push(CourseData[i].id)
        }

        setselectCourses(array)

    }
    console.log({ array })
    console.log({ selectCourses })
    console.log(shareData)

    // console.log(CourseData.filter(itm=> selectCourses.includes(itm.id)).length)
    return (

        <section className="course__area ">
            <div className="container pt-50">
                <BreadCrumb currentPage={'Courses'} />

                <div className="row pt-lg-70 pb-120" style={isMobile ? { flexDirection: 'column-reverse' } : {}}>
                    <div className="col-xxl-9 col-xl-9 col-lg-8">
                        <div className="row">
                            <div className='col-12 d-flex justify-content-end'>
                                {(selectShareActive && selectCourses.length <= 5) &&
                                    <button className='btn btn-outline-dark mb-3' style={{ marginRight: '.5rem' }} onClick={handleSelectAll} >{'Select All'}</button>
                                }
                                {(selectShareActive && !!selectCourses.length) &&
                                    <RWebShare
                                        data={{
                                            text: shareData,
                                            url: window.location.href,
                                            title: "Courses"
                                        }}
                                        onClick={() => console.log(shareData)}

                                    >
                                        <button className='btn btn-outline-dark mb-3 ' style={{ marginRight: '.5rem' }} > <div>{ShareIcon} <span className='ml-2'>Share</span></div></button>
                                    </RWebShare>
                                }
                                {CourseData.length >= 1 &&
                                    <button className='btn btn-outline-dark mb-3' onClick={() => { setselectShareActive(!selectShareActive); setselectCourses([]) }}> {selectShareActive ? 'Cancel' : <div>{ShareIcon} <span className='ml-2'>Share</span></div>}</button>
                                }
                            </div>

                            {(CourseData.length >= 1 && !loading) ? CourseData.map(item => (

                                <div className=" col-md-6 mb-30">

                                    <UniversityDetail
                                        PrimaryBtnName={'Apply Now'}
                                        courseName={item.course_name}
                                        duration={item.duration}
                                        fees={item.fees}
                                        courseId={item.id}
                                        level={item.level}
                                        intakes={item.intakes}
                                        University={item.by_university}
                                        pageUrl={(user == 'agent' || user == 'student' || user == 'staff') ? '/apply-course' : '/sign-in'}
                                        CourseData={item}
                                        isSelectActive={selectShareActive}
                                        selectedCourse={selectCourses}
                                        handleSelect={e => {
                                            // console.log(e.target.checked === true && !selectCourses.includes(item.id))
                                            if (e.target.checked === true && !selectCourses.includes(item.id)) {
                                                setselectCourses([...selectCourses, item.id])
                                            } else {
                                                setselectCourses(selectCourses.filter(itm => item.id !== itm))
                                            }
                                        }}
                                    />
                                </div>
                            ))
                                :
                                <>
                                    {[1, 2, 3, 4].map((item, idx) => (

                                        <div className=" col-md-6 mb-4" key={idx}>
                                            {loading && <Skeleton className='h-200' />}
                                        </div>
                                    ))}

                                    {loading ?
                                        <Loading />
                                        :
                                        <NoData />
                                    }
                                </>
                            }
                        </div>

                        {(Next || Prev) &&
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="">

                                    <button className='btn btn-primary' onClick={HandlePrev} disabled={!Prev} >Prev</button>
                                </div>
                                <div className="mx-3">
                                    {`${currentPage}/${TotalPages}`}
                                </div>
                                <div className="">
                                    <button className='btn btn-primary' onClick={HandleNext} disabled={!Next}>Next</button>
                                </div>
                            </div>
                        }



                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 mb-40">
                        <div className="course__sidebar pl-10">
                            <div className="course__sidebar-course">
                                <div className="course__sidebar-search mb-5">
                                    <form action="#">
                                        <input type="text" placeholder="Search for Course..." onChange={(e) => setsearchText(e.target.value)} value={searchText} />
                                        <button type="button" onClick={handleSearch} ><i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z">
                                            </path>
                                        </svg></i>
                                        </button>
                                    </form>
                                </div>
                                <div className="course__sidebar-widget grey-bg">
                                    <div className="course__sidebar-info">
                                        <Collapsible open={false} trigger={<div className='d-flex justify-content-between align-items-center'><h3 className="course__sidebar-title mb-0">Graduation Level</h3> <div>{ArrowDown}</div></div>}>


                                            <ul className='mt-20'>
                                                {Level.map(item => (

                                                    <RadioButtonField name={'level'} value={data.level} onchange={(e) => setdata({ ...data, level: e.target.id })} label={item} />
                                                ))}
                                            </ul>
                                        </Collapsible>
                                    </div>
                                </div>
                                <div className="course__sidebar-widget grey-bg">
                                    <div className="course__sidebar-info">
                                        <Collapsible open={false} trigger={<div className='d-flex justify-content-between align-items-center'><h3 className="course__sidebar-title mb-0">Course </h3> <div>{ArrowDown}</div></div>} >



                                            <div className="course__sidebar-search mb-3">

                                                {/* <form action="#">
                                                    <input type="text" className='bg-white px-3' placeholder="Search for Course" />
                                                    <button type="submit">
                                                        <i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" /></svg></i>
                                                    </button>
                                                </form> */}
                                            </div>
                                            <div className='row'>

                                                {courses.map(item => (
                                                    <div className="col-6">

                                                        <RadioButtonField name={'course'} value={data.course} onchange={(e) => setdata({ ...data, course: e.target.id })} label={item} />
                                                    </div>
                                                ))}
                                            </div>

                                        </Collapsible>
                                    </div>
                                </div>
                                <div className="course__sidebar-widget grey-bg">
                                    <div className="course__sidebar-info">
                                        <div className="d-flex justify-content-between">


                                        </div>
                                        <Collapsible open={false} trigger={<div className='d-flex justify-content-between align-items-center'><h3 className="course__sidebar-title mb-0">Countries</h3> <div>{ArrowDown}</div></div>} >
                                            <ul className='mt-20'>
                                                {Countries.map(item => (

                                                    <RadioButtonField name={'country'} value={data.country} onchange={(e) => {
                                                        if (e.target.id.includes('United')) {
                                                            setdata({ ...data, country: 'United' })
                                                        } else {
                                                            setdata({ ...data, country: e.target.id })
                                                        }
                                                    }} label={item.includes('United') ? 'United Kingdom' : item} />
                                                ))}
                                            </ul>
                                        </Collapsible>
                                    </div>
                                </div>
                                <div className="course__sidebar-widget grey-bg">
                                    <div className="course__sidebar-info">
                                        <Collapsible open={false} trigger={<div className='d-flex justify-content-between align-items-center'><h3 className="course__sidebar-title mb-0">Intakes</h3> <div>{ArrowDown}</div></div>}>


                                            <ul className='mt-20'>
                                                {Intakes.map(item => (

                                                    <RadioButtonField name={'intakes'} value={data.intakes} onchange={(e) => setdata({ ...data, intakes: e.target.id })} label={item} />
                                                ))}
                                            </ul>
                                        </Collapsible>
                                    </div>
                                </div>


                                <button className='btn btn-primary w-100' onClick={clearFilter}>Clear all</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default Courses
export const ArrowDown = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width={15} height={15}>
    <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
</svg>
export const ArrowUp = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width={15} height={15}>
    <path d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z" />
</svg>
export const ShareIcon = <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={15} height={15} viewBox="0 0 50 50" fill='currentColor'><path d="M 40 0 C 34.535156 0 30.078125 4.398438 30 9.84375 C 30 9.894531 30 9.949219 30 10 C 30 13.6875 31.996094 16.890625 34.96875 18.625 C 36.445313 19.488281 38.167969 20 40 20 C 45.515625 20 50 15.515625 50 10 C 50 4.484375 45.515625 0 40 0 Z M 28.0625 10.84375 L 17.84375 15.96875 C 20.222656 18.03125 21.785156 21 21.96875 24.34375 L 32.3125 19.15625 C 29.898438 17.128906 28.300781 14.175781 28.0625 10.84375 Z M 10 15 C 4.484375 15 0 19.484375 0 25 C 0 30.515625 4.484375 35 10 35 C 12.050781 35 13.941406 34.375 15.53125 33.3125 C 18.214844 31.519531 20 28.472656 20 25 C 20 21.410156 18.089844 18.265625 15.25 16.5 C 13.71875 15.546875 11.929688 15 10 15 Z M 21.96875 25.65625 C 21.785156 28.996094 20.25 31.996094 17.875 34.0625 L 28.0625 39.15625 C 28.300781 35.824219 29.871094 32.875 32.28125 30.84375 Z M 40 30 C 37.9375 30 36.03125 30.644531 34.4375 31.71875 C 31.769531 33.515625 30 36.542969 30 40 C 30 40.015625 30 40.015625 30 40.03125 C 29.957031 40.035156 29.917969 40.058594 29.875 40.0625 L 30 40.125 C 30.066406 45.582031 34.527344 50 40 50 C 45.515625 50 50 45.515625 50 40 C 50 34.484375 45.515625 30 40 30 Z" /></svg>
