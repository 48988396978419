import React from 'react'

export const RadioButtonField = (props) => {
    return (
        <li>
            <div className="course__sidebar-check mb-10 d-flex align-items-center">
                <input type="radio" className="m-check-input" name={props.name} onChange={props.onchange} value={props.value} id={props.label} />
                <label className="m-check-label" htmlFor={props.label}>{props.label}</label>
            </div>
        </li>
    )
}
