import React from 'react'

const NoData = () => {
    return (
        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: 'column', height:'50vh'}}>
            <div>
                {nodata}
            </div>

            <div>
                No Results Found
            </div>
        </div>
    )
}

export default NoData
export const nodata = <svg xmlns="http://www.w3.org/2000/svg" width="319.823" height="91.772" viewBox="0 0 319.823 91.772">
    <g id="noun-paper-1751165" transform="translate(219.766 18.746) rotate(9)">
        <path id="Path_238" data-name="Path 238" d="M7.433,6.371V1.062a1.062,1.062,0,1,0-2.124,0V5.309H1.062a1.062,1.062,0,1,0,0,2.124H6.371A1.062,1.062,0,0,0,7.433,6.371Z" transform="translate(4.778 4.778)" fill="#eaeaea" />
        <path id="Path_239" data-name="Path 239" d="M9.557,0h-8.5a1.062,1.062,0,1,0,0,2.124h8.5A1.062,1.062,0,0,0,9.557,0Z" transform="translate(15.928 13.804)" fill="#eaeaea" />
        <path id="Path_240" data-name="Path 240" d="M18.052,0H1.062a1.062,1.062,0,0,0,0,2.124h16.99a1.062,1.062,0,0,0,0-2.124Z" transform="translate(7.433 30.794)" fill="#eaeaea" />
        <path id="Path_241" data-name="Path 241" d="M18.052,0H1.062a1.062,1.062,0,0,0,0,2.124h16.99a1.062,1.062,0,0,0,0-2.124Z" transform="translate(7.433 22.299)" fill="#eaeaea" />
        <path id="Path_242" data-name="Path 242" d="M10.619,44.6H31.325a2.655,2.655,0,0,0,2.655-2.655v-6.9a1.062,1.062,0,0,0-2.124,0v6.9a.531.531,0,0,1-.531.531H2.655a.531.531,0,0,1-.531-.531V7.964l7.784-5.84H31.325a.531.531,0,0,1,.531.531V28.671a1.062,1.062,0,0,0,2.124,0V2.655A2.655,2.655,0,0,0,31.325,0H9.907A2.122,2.122,0,0,0,8.633.425L.85,6.265A2.124,2.124,0,0,0,0,7.964v33.98A2.655,2.655,0,0,0,2.655,44.6h7.964Z" transform="translate(0 0)" fill="#eaeaea" />
    </g>
    <g id="noun-paper-1751165-2" data-name="noun-paper-1751165" transform="matrix(0.951, 0.309, -0.309, 0.951, 287.506, 38.856)" opacity="0.485">
        <path id="Path_238-2" data-name="Path 238" d="M7.433,6.371V1.062a1.062,1.062,0,1,0-2.124,0V5.309H1.062a1.062,1.062,0,1,0,0,2.124H6.371A1.062,1.062,0,0,0,7.433,6.371Z" transform="translate(4.779 4.778)" fill="#eaeaea" />
        <path id="Path_239-2" data-name="Path 239" d="M9.557,0h-8.5a1.062,1.062,0,1,0,0,2.124h8.5A1.062,1.062,0,0,0,9.557,0Z" transform="translate(15.928 13.804)" fill="#eaeaea" />
        <path id="Path_240-2" data-name="Path 240" d="M18.052,0H1.062a1.062,1.062,0,0,0,0,2.124h16.99a1.062,1.062,0,0,0,0-2.124Z" transform="translate(7.433 30.794)" fill="#eaeaea" />
        <path id="Path_241-2" data-name="Path 241" d="M18.052,0H1.062a1.062,1.062,0,0,0,0,2.124h16.99a1.062,1.062,0,0,0,0-2.124Z" transform="translate(7.433 22.299)" fill="#eaeaea" />
        <path id="Path_242-2" data-name="Path 242" d="M10.619,44.6H31.325a2.655,2.655,0,0,0,2.655-2.655v-6.9a1.062,1.062,0,0,0-2.124,0v6.9a.531.531,0,0,1-.531.531H2.655a.531.531,0,0,1-.531-.531V7.964l7.784-5.84H31.325a.531.531,0,0,1,.531.531V28.671a1.062,1.062,0,0,0,2.124,0V2.655A2.655,2.655,0,0,0,31.325,0H9.907A2.122,2.122,0,0,0,8.633.425L.85,6.265A2.124,2.124,0,0,0,0,7.964v33.98A2.655,2.655,0,0,0,2.655,44.6h7.964Z" transform="translate(0 0)" fill="#eaeaea" />
    </g>
    <g id="noun-paper-1751165-3" data-name="noun-paper-1751165" transform="translate(63.814 24.061) rotate(-9)">
        <path id="Path_238-3" data-name="Path 238" d="M7.433,6.371V1.062a1.062,1.062,0,1,0-2.124,0V5.309H1.062a1.062,1.062,0,0,0,0,2.124H6.371A1.062,1.062,0,0,0,7.433,6.371Z" transform="translate(4.778 4.778)" fill="#eaeaea" />
        <path id="Path_239-3" data-name="Path 239" d="M9.557,0H1.062a1.062,1.062,0,1,0,0,2.124H9.557A1.062,1.062,0,0,0,9.557,0Z" transform="translate(15.928 13.804)" fill="#eaeaea" />
        <path id="Path_240-3" data-name="Path 240" d="M18.052,0H1.062a1.062,1.062,0,0,0,0,2.124h16.99a1.062,1.062,0,0,0,0-2.124Z" transform="translate(7.433 30.794)" fill="#eaeaea" />
        <path id="Path_241-3" data-name="Path 241" d="M18.052,0H1.062a1.062,1.062,0,0,0,0,2.124h16.99a1.062,1.062,0,0,0,0-2.124Z" transform="translate(7.433 22.299)" fill="#eaeaea" />
        <path id="Path_242-3" data-name="Path 242" d="M10.619,44.6H31.325a2.655,2.655,0,0,0,2.655-2.655v-6.9a1.062,1.062,0,1,0-2.124,0v6.9a.531.531,0,0,1-.531.531H2.655a.531.531,0,0,1-.531-.531V7.964l7.784-5.84H31.325a.531.531,0,0,1,.531.531V28.671a1.062,1.062,0,0,0,2.124,0V2.655A2.655,2.655,0,0,0,31.325,0H9.907A2.122,2.122,0,0,0,8.633.425L.849,6.265A2.124,2.124,0,0,0,0,7.964v33.98A2.655,2.655,0,0,0,2.655,44.6h7.964Z" transform="translate(0 0)" fill="#eaeaea" />
    </g>
    <g id="noun-paper-1751165-4" data-name="noun-paper-1751165" transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 49.356)" opacity="0.485">
        <path id="Path_238-4" data-name="Path 238" d="M7.433,6.371V1.062a1.062,1.062,0,1,0-2.124,0V5.309H1.062a1.062,1.062,0,0,0,0,2.124H6.371A1.062,1.062,0,0,0,7.433,6.371Z" transform="translate(4.778 4.778)" fill="#eaeaea" />
        <path id="Path_239-4" data-name="Path 239" d="M9.557,0H1.062a1.062,1.062,0,1,0,0,2.124H9.557A1.062,1.062,0,0,0,9.557,0Z" transform="translate(15.928 13.804)" fill="#eaeaea" />
        <path id="Path_240-4" data-name="Path 240" d="M18.052,0H1.062a1.062,1.062,0,0,0,0,2.124h16.99a1.062,1.062,0,0,0,0-2.124Z" transform="translate(7.433 30.794)" fill="#eaeaea" />
        <path id="Path_241-4" data-name="Path 241" d="M18.052,0H1.062a1.062,1.062,0,0,0,0,2.124h16.99a1.062,1.062,0,0,0,0-2.124Z" transform="translate(7.433 22.299)" fill="#eaeaea" />
        <path id="Path_242-4" data-name="Path 242" d="M10.619,44.6H31.325a2.655,2.655,0,0,0,2.655-2.655v-6.9a1.062,1.062,0,1,0-2.124,0v6.9a.531.531,0,0,1-.531.531H2.655a.531.531,0,0,1-.531-.531V7.964l7.784-5.84H31.325a.531.531,0,0,1,.531.531V28.671a1.062,1.062,0,0,0,2.124,0V2.655A2.655,2.655,0,0,0,31.325,0H9.907A2.122,2.122,0,0,0,8.633.425L.849,6.265A2.124,2.124,0,0,0,0,7.964v33.98A2.655,2.655,0,0,0,2.655,44.6h7.964Z" transform="translate(0 0)" fill="#eaeaea" />
    </g>
    <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(135.311 1.759) rotate(11)">
        <path id="Path_244" data-name="Path 244" d="M54.215,27.107A27.107,27.107,0,1,1,27.107,0,27.107,27.107,0,0,1,54.215,27.107Z" fill="none" stroke="#b5e3f2" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
        <path id="Path_245" data-name="Path 245" d="M14.74,14.74,0,0" transform="translate(46.252 46.252)" fill="none" stroke="#b5e3f2" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
    </g>
    <g id="Group_160" data-name="Group 160" transform="translate(143.796 25.96)">
        <path id="Path_246" data-name="Path 246" d="M13901.05,2158.322l4.822,4.824" transform="translate(-13901.05 -2158.322)" fill="none" stroke="#b5e3f2" stroke-linecap="round" stroke-width="2" />
        <path id="Path_247" data-name="Path 247" d="M0,0,4.822,4.823" transform="translate(4.823) rotate(90)" fill="none" stroke="#b5e3f2" stroke-linecap="round" stroke-width="2" />
    </g>
    <g id="Group_161" data-name="Group 161" transform="translate(165.165 25.96)">
        <path id="Path_246-2" data-name="Path 246" d="M13901.05,2158.322l4.822,4.824" transform="translate(-13901.051 -2158.322)" fill="none" stroke="#b5e3f2" stroke-linecap="round" stroke-width="2" />
        <path id="Path_247-2" data-name="Path 247" d="M0,0,4.822,4.823" transform="translate(4.823) rotate(90)" fill="none" stroke="#b5e3f2" stroke-linecap="round" stroke-width="2" />
    </g>
    <path id="Path_248" data-name="Path 248" d="M13929.613,2207.171s5.607-4.329,11.066-4.329,10.768,4.329,10.768,4.329" transform="translate(-13783.407 -2163.743)" fill="none" stroke="#b5e3f2" stroke-linecap="round" stroke-width="2" />
</svg>