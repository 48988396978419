import React, { useContext, useEffect, useState } from 'react'
import { RadioButtonField } from '../../Components/InputField/CheckBoxField'
import UniversityDetail from '../Home/UniversityDetail'
import CourseImg from '../../Assets/img/course/course-1.jpg'
import { BreadCrumb } from '../../Components/BreadCrumb/BreadCrumb'
import { UseForm } from '../../common/Hooks/UseForm'
import Collapsible from 'react-collapsible'
import { Context } from '../../common/Context/Context'
import { ManageUniversity } from './UniversityLogic'
import { ManageCourse } from '../Course/CourseLogic'
import NoData from '../../Components/NoData/NoData'
import { useLocation } from 'react-router-dom'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Loading from '../../Components/Lottie/Loading'
import { protocol } from '../../Functionalities/config'
import { ManagePagination } from '../../Functionalities/PaginationLogic'



const University = () => {
    const { isMobile } = useContext(Context)
    const [FilterData, setFilterData] = useState({
        searchText: '',
        country: '',
        intakes: '',
        course: '',
    })
    const { state } = useLocation()
    const [data, setdata] = useState([])
    const [searchText, setsearchText] = useState('')
    const [CourseData, setCourseData] = useState([])
    const [courseSearch, setcourseSearch] = useState('')
    const [loading, setloading] = useState(true)
    const [Prev, setPrev] = useState('')
    const [Next, setNext] = useState('')
    const [currentPage, setcurrentPage] = useState('')
    const [TotalPages, setTotalPages] = useState('')

    useEffect(() => {
        if (state?.country) {
            // console.log(state.country)
            setFilterData({ ...FilterData, country: state.country })
            document.querySelectorAll('input[type=radio]').forEach(itm => itm.id == state.country ? itm.checked = true : false)

        }
    }, [state])


    useEffect(() => {
        // console.log({ FilterData })
        if (!state?.country) {
            GetUniversity()

        }
    }, [FilterData])

    useEffect(() => {
        if (state?.country) {
            GetUniversity(`country=${state?.country}`)

        }
    }, [state?.country])


    const GetUniversity = (param) => {
        let params = ''
        if (param) {
            params += param + '&intake=' + FilterData.intakes

        } else {

            params += 'country=' + FilterData.country + '&intake=' + FilterData.intakes
        }
        params += '&search_text=' + searchText 
        params += FilterData.course.toLowerCase()
        if (FilterData.course) {
            params += '&course=' + FilterData.course.toLowerCase()
        }
        else {
            params += '&course=' + courseSearch
        }

        // return console.log({params})
        let result = ManageUniversity('get', '', params)

        result.then((res) => {
            console.log('university data', res)
            if (res.data.results) {
                setdata(res.data.results)
                setloading(false)
                setNext(res.data.next)
                setPrev(res.data.previous)

                setcurrentPage(res.data.current_page)
                setTotalPages(res.data.num_pages)
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }


    const handleUniversitySearch = (e) => {
        e.preventDefault()
        if (courseSearch) {
            document.querySelectorAll('input[type=radio]').forEach(itm => itm.name.includes('course') ? itm.checked = false : '')
            setFilterData({ ...FilterData, course: "" })
        }
        GetUniversity()
    }
    const HandlePrev = () => {
        let path = Prev.replace('http:', protocol.replace('//', ''))

        let result = ManagePagination(path, '')

        result.then(function (response) {
            console.log((response.data));
            if (response.data.results) {
                setdata(response.data.results)

                setcurrentPage(response.data.current_page)
                setTotalPages(response.data.num_pages)
                setNext(response.data.next)
                setPrev(response.data.previous)
                setloading(false)
                window.scrollTo(0, 0)

            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const HandleNext = () => {
        let path = Next.replace('http:', protocol.replace('//', ''))

        let result = ManagePagination(path, '')

        result.then(function (response) {
            console.log((response.data));
            if (response.data.results) {
                setdata(response.data.results)
                setNext(response.data.next)
                setPrev(response.data.previous)

                setcurrentPage(response.data.current_page)
                setTotalPages(response.data.num_pages)
                setloading(false)
                window.scrollTo(0, 0)

            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }


    const Intakes = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]
    const Countries = [
        "Canada",
        'Ireland',
        'Australia',
        'United',
        'Singapore',
        'USA',
    ]


    const clearFilter = (e) => {
        e.preventDefault()

        setsearchText('');
        setcourseSearch('')
        setFilterData({
            ...FilterData,
            country: '',
            intakes: '',
            course: ''
        })

        document.querySelectorAll('input[type=radio]').forEach(el => el.checked = false);
    }
    const courses = ['BA', 'MA', 'BBA', 'MBA', 'BSc', 'MSc', 'LLB', 'LLM', 'BEng', 'MEng']


    return (

        <section className="course__area">
            <div className="container pt-50">
                <BreadCrumb currentPage={'University'} />
                <div className="row  pb-120" style={isMobile ? { flexDirection: 'column-reverse' } : {}}>
                    <div className="col-xxl-9 col-xl-9 col-lg-8">
                        <div className="row">
                            {(data.length >= 1 && !loading) ? data?.map(item => (

                                <div className=" col-md-6 mb-4">

                                    <UniversityDetail id={item.id} image={item.image[0].docfile} name={item.name} country={item.country} />
                                </div>
                            ))
                                :
                                <>
                                    {[1, 2, 3, 4].map((item, idx) => (

                                        <div className=" col-md-6 mb-4" key={idx}>
                                            {loading &&
                                                <>

                                                    <Skeleton className='h-200' />
                                                </>
                                            }
                                        </div>
                                    ))}

                                    {loading ?
                                        <Loading />
                                        :
                                        <NoData />
                                    }
                                </>
                            }


                        </div>

                        {(Next || Prev) &&
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="">

                                    <button className='btn btn-primary' onClick={HandlePrev} disabled={!Prev} >Prev</button>
                                </div>
                                <div className="mx-3">
                                    {`${currentPage}/${TotalPages}`}
                                </div>
                                <div className="">
                                    <button className='btn btn-primary' onClick={HandleNext} disabled={!Next}>Next</button>
                                </div>
                            </div>
                        }


                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 mb-40">
                        <div className="course__sidebar pl-10">
                            <div className="course__sidebar-course">
                                <div className="course__sidebar-search mb-5">
                                    <form action="#">
                                        <input type="text" placeholder="Search for University..." onChange={(e) => setsearchText(e.target.value)}
                                            value={searchText} /><button type="button" onClick={handleUniversitySearch}><i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z">
                                                </path>
                                            </svg></i>
                                        </button>
                                    </form>
                                </div>
                                <div className="course__sidebar-widget grey-bg">
                                    <div className="course__sidebar-info">
                                        <div className="d-flex justify-content-between">


                                        </div>
                                        <Collapsible open={false} trigger={<div className='d-flex justify-content-between align-items-center'><h3 className="course__sidebar-title mb-0">Countries</h3> <div>{ArrowDown}</div></div>} >
                                            <ul className='mt-20'>
                                                {Countries.map(item => (

                                                    <RadioButtonField name={'country'} value={FilterData.country} onchange={(e) => {
                                                        if (e.target.id.includes('United')) {
                                                            setFilterData({ ...FilterData, country: 'United' })
                                                        } else {
                                                            setFilterData({ ...FilterData, country: e.target.id })
                                                        }
                                                    }} label={item.includes('United') ? 'United Kingdom' : item} />
                                                ))}
                                            </ul>
                                        </Collapsible>
                                    </div>
                                </div>
                                {/* <div className="course__sidebar-widget grey-bg">
                                    <div className="course__sidebar-info">
                                        false


                                            <ul className='mt-20'>
                                                {Intakes.map(item => (

                                                    <RadioButtonField name={'intakes'} value={FilterData.intakes} onchange={(e) => setFilterData({ ...FilterData, intakes: e.target.id })}
                                                        label={item} />
                                                ))}
                                            </ul>
                                        </Collapsible>
                                    </div>
                                </div> */}
                                <div className="course__sidebar-widget grey-bg">
                                    <div className="course__sidebar-info">
                                        <Collapsible open={false} trigger={<div className='d-flex justify-content-between align-items-center'><h3 className="course__sidebar-title mb-0">Course</h3> <div>{ArrowDown}</div></div>} >

                                            <div className="course__sidebar-search mb-3">

                                                <form action="#">
                                                    <input type="text" className='bg-white px-3' placeholder="Search for Course" onChange={(e) => setcourseSearch(e.target.value)} value={courseSearch} />
                                                    <button type="submit" onClick={handleUniversitySearch}>
                                                        <i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" /></svg></i>
                                                    </button>
                                                </form>
                                            </div>
                                            {/* {CourseData.filter((itm, idx) => idx < 10).map(item => (
                                                <RadioButtonField name={'course'} value={data.course} onchange={(e) => setFilterData({ ...FilterData, course: e.target.id })}
                                                    label={item.course_name.slice(0, 18) + '...'} />
                                            ))} */}
                                            <div className='row'>

                                                {courses.map(item => (
                                                    <div className="col-6">

                                                        <RadioButtonField name={'course'} value={FilterData.course} onchange={(e) => { setFilterData({ ...FilterData, course: e.target.id, }); setcourseSearch('') }} label={item} />
                                                    </div>
                                                ))}
                                            </div>

                                        </Collapsible>
                                    </div>
                                </div>
                                <button className='btn btn-primary w-100' onClick={clearFilter}>Clear all</button>


                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>


    )
}

export default University
export const ArrowDown = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width={15} height={15}>
    <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
</svg>
export const ArrowUp = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width={15} height={15}>
    <path d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z" />
</svg>