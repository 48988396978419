import React, { useContext, useEffect, useState } from 'react'
import { BreadCrumb } from '../../Components/BreadCrumb/BreadCrumb'
// import "/slick/slick.css"; 
// import "/slick/slick-theme.css";
import Slider from "react-slick";
import UniversityDetail from '../Home/UniversityDetail';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../common/Context/Context';
import { ManageUniversity } from './UniversityLogic';
import { ManageCourse } from '../Course/CourseLogic';
import NoData from '../../Components/NoData/NoData';
import Skeleton from 'react-loading-skeleton';
import Loading from '../../Components/Lottie/Loading'
import { protocol } from '../../Functionalities/config';
import { ManagePagination } from '../../Functionalities/PaginationLogic';


const UniversityDetails = () => {
    const { user } = useContext(Context)
    const { id } = useParams()

    const [data, setdata] = useState([])
    const [CourseData, setCourseData] = useState([])
    const [searchText, setsearchText] = useState('')
    const [loading, setloading] = useState(true)

    const [Prev, setPrev] = useState('')
    const [Next, setNext] = useState('')
    const [currentPage, setcurrentPage] = useState('')
    const [TotalPages, setTotalPages] = useState('')

    useEffect(() => {
        id && GetUniversity()
        id && GetCourse()
    }, [])


    const GetUniversity = () => {
        let params = `id=${id}`

        let result = ManageUniversity('get', '', params)

        result.then((res) => {
            console.log({ res })
            if (res.data.results) {
                setdata(res.data.results[0])
                setloading(false)

            }
        })
            .catch((err) => {
                console.log(err)
            })
    }

    const GetCourse = () => {
        let params = 'university_id=' + id + '&search_text=' + searchText

        let result = ManageCourse('get', '', params)
        result.then((res) => {

            if (res.data.results) {
                console.log(res)
                setCourseData(res.data.results)
                // setloading(false)
                setNext(res.data.next)
                setPrev(res.data.previous)
                setcurrentPage(res.data.current_page)
                setTotalPages(res.data.num_pages)
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }

    const HandlePrev = () => {
        let path = Prev.replace('http:', protocol.replace('//', ''))

        let result = ManagePagination(path, '')

        result.then(function (response) {
            console.log((response.data));
            if (response.data.results) {
                setCourseData(response.data.results)
                setNext(response.data.next)
                setPrev(response.data.previous)
                setloading(false)
                setcurrentPage(response.data.current_page)
                setTotalPages(response.data.num_pages)
                window.scrollTo(0, 0)

            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const HandleNext = () => {
        let path = Next.replace('http:', protocol.replace('//', ''))

        let result = ManagePagination(path, '')

        result.then(function (response) {
            console.log((response.data));
            if (response.data.results) {
                setCourseData(response.data.results)
                setNext(response.data.next)
                setPrev(response.data.previous)
                setloading(false)
                window.scrollTo(0, 0)
                setcurrentPage(response.data.current_page)
                setTotalPages(response.data.num_pages)

            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }


    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };
    const Navigate = useNavigate()




    const handleSearch = (e) => {
        e.preventDefault()

        GetCourse()
    }
    const handleClearSearch = (e) => {
        e.preventDefault()
        setsearchText('')
        GetCourse()
    }


    return (
        <div>
            <section className="page__title-area page__title-heightd-flex align-items-center mt-60" style={{ position: 'relative', overflowX: 'hidden' }}>
                {(data && !loading) ?
                    <Slider {...settings}>
                        {data.image?.map(item => (

                            <div>
                                <img src={item.docfile} className='w-100' style={{ height: '25rem', objectFit: 'cover' }} alt="" />
                            </div>
                        ))}

                    </Slider>
                    :
                    <>
                        <Skeleton className='h-400' />
                        <Loading />
                    </>
                }
                <div className="container" >
                    <div className="row" style={{
                        position: 'absolute',
                        bottom: '45px'
                    }}>
                        <div className="col-xxl-12">
                            <BreadCrumb currentPage={data.name} previousPage={'University'} previousPageUrl={'/university'} />

                        </div>
                    </div>
                </div>



            </section>

            <section >
                <div style={{ position: 'relative' }} className="container py-5">
                    <h3> About</h3>
                    <p>
                        {data.description}
                    </p>
                    <h3> Location</h3>
                    <p>
                        {data.address}
                    </p>
                    <div className='bg-white p-2' style={{
                        position: 'absolute',
                        top: '-30px',
                        right: '10px',
                        borderRadius: '4px',
                        boxShadow: '0px 0px 10px #e0dfdf'
                    }}>
                        <a href={data.website} className='d-flex justify-content-center align-items-center text-primary' style={{
                            flexDirection: 'column',
                            fontSize: 'smaller'
                        }}>
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width={"25.891"} height={"25.891"} viewBox="0 0 21.891 21.891">
                                    <g id="noun-website-1276202" transform="translate(-103.6 -33.602)">
                                        <path id="Path_197" data-name="Path 197" d="M114.544,33.6a10.945,10.945,0,1,0,10.947,10.944A10.951,10.951,0,0,0,114.544,33.6Zm.5,1.164a2.511,2.511,0,0,1,.976.6,6.508,6.508,0,0,1,1.368,2.06c.137.3.265.629.383.973h-2.727Zm-.995,0V38.4H111.32c.119-.344.247-.669.383-.973a6.509,6.509,0,0,1,1.368-2.06,2.514,2.514,0,0,1,.975-.6Zm-1.9.12a7.861,7.861,0,0,0-1.354,2.136,13.173,13.173,0,0,0-.526,1.381h-3.556a9.941,9.941,0,0,1,5.438-3.517Zm4.788,0a9.945,9.945,0,0,1,5.438,3.517h-3.557a13.177,13.177,0,0,0-.526-1.381A7.855,7.855,0,0,0,116.939,34.886ZM106.026,39.4h3.961a21.033,21.033,0,0,0-.594,4.651h-4.785a9.9,9.9,0,0,1,1.419-4.651Zm4.992,0h3.03v4.651h-3.659a19.939,19.939,0,0,1,.629-4.651Zm4.024,0h3.03a19.947,19.947,0,0,1,.628,4.651h-3.659Zm4.06,0h3.963a9.9,9.9,0,0,1,1.419,4.651H119.7A21.056,21.056,0,0,0,119.1,39.4Zm-14.495,5.646h4.785a21.121,21.121,0,0,0,.578,4.589h-3.984a9.9,9.9,0,0,1-1.38-4.589Zm5.781,0h3.659v4.589H111a20.014,20.014,0,0,1-.612-4.589Zm4.654,0H118.7a20.034,20.034,0,0,1-.612,4.589h-3.047Zm4.654,0h4.788a9.9,9.9,0,0,1-1.381,4.589h-3.985a21.135,21.135,0,0,0,.578-4.589Zm-13.032,5.584h3.586a13.3,13.3,0,0,0,.546,1.446,7.856,7.856,0,0,0,1.355,2.136,9.94,9.94,0,0,1-5.487-3.582Zm4.634,0h2.749v3.7a2.51,2.51,0,0,1-.975-.6,6.514,6.514,0,0,1-1.368-2.06c-.145-.322-.281-.669-.406-1.037Zm3.744,0h2.749c-.125.368-.26.715-.406,1.037a6.511,6.511,0,0,1-1.368,2.06,2.511,2.511,0,0,1-.976.6Zm3.8,0h3.587a9.944,9.944,0,0,1-5.488,3.581,7.853,7.853,0,0,0,1.354-2.136,13.252,13.252,0,0,0,.546-1.445Z" fill="var(--bs-primary)" fillRule="evenodd" />
                                    </g>
                                </svg>
                            </i>
                            <div style={{ height: '16px' }}>visit</div>
                            <div style={{ height: '16px' }}>Website</div>


                        </a>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-50">
                            <div className="course__sidebar-search ">
                                <form action="#">
                                    <input type="text" placeholder="Search for Course..." onChange={e => setsearchText(e.target.value)} value={searchText}
                                    /><button type="button" onClick={handleSearch} ><i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z">
                                        </path>
                                    </svg></i>
                                    </button>
                                </form>
                            </div>
                            {/* <button className='btn btn-outline-dark'>Clear Search</button> */}
                            </div>

                                
                        <div className="row">
                            {(CourseData.length >= 1 && !loading) ?

                                CourseData.map(item => (

                                    <div className=" col-md-6 mb-30">
                                        <UniversityDetail
                                            PrimaryBtnName={'Apply Now'}
                                            courseName={item.course_name}
                                            duration={item.duration}
                                            fees={item.fees}
                                            courseId={item.id}
                                            level={item.level}
                                            intakes={item.intakes}
                                            pageUrl={(user == 'agent' || user == 'student' || user == 'staff') ? '/apply-course' : '/sign-in'}
                                            CourseData={item}
                                            University={item.by_university}
                                        />
                                    </div>
                                ))

                                :
                                // [1, 2].map((item, idx) => (

                                //     <div className=" col-md-6 mb-4" key={idx}>
                                //         {loading && <Skeleton className='h-200' />}
                                //     </div>
                                // ))


                                loading ?
                                    <Loading />
                                    :
                                    <NoData />
                            }

                        </div>


                        {(Next || Prev) &&
                            <div className="d-flex justify-content-center align-items-center mb-30">
                                <div className="">

                                    <button className='btn btn-primary' onClick={HandlePrev} disabled={!Prev} >Prev</button>
                                </div>
                                <div className="mx-3">
                                    {`${currentPage}/${TotalPages}`}
                                </div>
                                <div className="">
                                    <button className='btn btn-primary' onClick={HandleNext} disabled={!Next}>Next</button>
                                </div>
                            </div>
                        }

                    </div>

                </div>

            </section>

        </div>
    )
}

export default UniversityDetails