import React from 'react'

export const SelectField = (props) => {

    console.log(props.option)
    return (
        <div className="country-select">
            <label>{props.label} {props.isRequired ? <span className="required"> * </span> : ''}</label>
            <select name={props.name} value={props.value} onChange={props.onchange}>
                <option value='' selected disabled>{props.defaultSelected}</option>
                {props.defaultValue ?
                    props.option?.map(item => (
                        <option value={item.id} >{item.value}</option>
                    ))
                    :
                    props.option.map(item => (
                        <option value={item.id} >{item.value}</option>
                    ))
                }



            </select>
        </div>
    )
}
