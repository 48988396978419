import React from 'react'
import * as moment from 'moment';

const Agreement = ({ name,
    email,
    mobile,
    companyName,
    companyAddress, }) => {


    // const date = new Date()
    // console.log(moment().format('MMMM Do YYYY'))
    return (
        <div >
            <h5 style={{ textDecoration: 'underline', fontWeight: '600', textAlign: 'center' }}>CHANNEL PARTNER AGREEMENT</h5>
            <br />

            Agreement made between VIDYA PORTAL, an educational agency, having its registered office Perinthalmanna, Malappuram represented by its designated partner   Mr: JISHNU SANKAR PJ
            <br />
            <span style={{ fontWeight: '600' }}>And</span>
            <br />
            <div className='w-100'>
                ChannelPartner
                <br />
                NAME: <span style={{ margin: '0 .5rem', fontWeight: '600' }}>{name}</span>
                <br />

                EMAIL: <span style={{ margin: '0 .5rem', fontWeight: '600' }}>{email}</span>
                <br />
                MOB NO: <span style={{ margin: '0 .5rem', fontWeight: '600' }}>{mobile}</span>
                <br />
                COMPANY NAME: <span style={{ margin: '0 .5rem', fontWeight: '600' }}>{companyName}</span>
                <br />
                COMPANY ADDRESS: <span style={{ margin: '0 .5rem', fontWeight: '600' }}>{companyAddress}</span>
                <br />
                <br />
            </div>
            VIDYA PORTAL is an education agency which provides guidance on study abroad for selecting apt country, institution and course. It also provides facilities and guidance to get better overseas education as well as education with in the country.
            <br />
            <br />
            The highlights of VIDYA PORTAL is that it has several successful years of experience as an internationally acclaimed educational consultant with strong national and international relations. It is closely associated with reputed national and international educational institutions. It has deep knowledge and information about various courses and institutions with partnered institutions over several countries. VIDYA PORTAL is referred in this agreement as VIDYA PORTAL
            <br />
            <br />
            The Channel Partner is a well established educational consultant in its area of operations with deep rooted relationship with general public and is delivering quality service by helping students to choose their study/course. Channel Partner is referred in this agreement as CP.
            <br />
            That the Channel Partner is desirous to obtaining a partnership with VIDYA PORTAL for getting best opportunity for aspirant students selected by the Channel Partner for their study abroad and within the country. By this agreement the Channel Partner will get the opportunity to provide wide choice of trendy educational programs and causes for their prospective students in world class institutions and known national institutions to which Channel Partner may not have direct access at all. The experience of Vidya Portal can be utilized by the Channel Partner to increase its client base. As a preferred partner of many institutions abroad and India VIDYA PORTAL enjoys better commission structure at higher slabs and there by the Channel Partner can earn better.
            <br />
            <br />
            This agreement does not give the Channel Partner the right to present itself as a partner or representative of any educational institutions. This agreement only covers the students found out by the Channel Partner and stream lined by the VIDYA PORTAL. This agreement does not hinder the independent growth of the Channel Partner any way.
            <br />
            <br />
            <h5 style={{ textDecoration: 'underline', fontWeight: '600' }}>The Responsibilities of Vidya Portal.</h5>
            <br />
            <ol type='1'>
                <li style={{ listStyle: 'unset' }}>
                    Reviewing the eligibility criteria of the aspirant applicants found out by the Channel Partner, initiating procedures for getting a conditional/ unconditional offer letter from the concerned institution and forwarding the same to Channel Partner immediately on receipt at no cost to the Channel Partner.
                </li>
                <li style={{ listStyle: 'unset' }}>
                    Providing guidance and counselling to the Channel Partner if needed upon request by the Channel Partner to its aspirant candidates.

                </li>
                <li style={{ listStyle: 'unset' }}>
                    Can complete the online application of the aspirant students on behalf of the Channel Partner upon the request.
                </li>
                <li style={{ listStyle: 'unset' }}>
                    Assist documentation and Visa to the students.
                </li>
                <li style={{ listStyle: 'unset' }}>
                    Provide username and password to the Channel Partner or their authorized employee inorder to create portal accounts for the candidates and store scanned copy of documents in the respective candidate folder. VIDYA PORTAL will update the status of the offer letter applications timely.

                </li>
                <li style={{ listStyle: 'unset' }}>
                    VIDYA PORTAL may provide assistance for meeting the post landing needs of the students upon requests either directly or through international carrier cell of the concerned institution or by third party engagements. The expenses for the services if any is changeable.
                </li>
            </ol>


            <br />
            <br />
            <h5 style={{ textDecoration: 'underline', fontWeight: '600' }}>Responsibilities of Channel Partner </h5>
            <br />
            <ol type='1'>
                <li style={{ listStyle: 'unset' }}>
                    Assist their students in all aspects including the remittance of fee, application fee, tuition fee, caution fee etc..as prescribed by the educational institutions through the recommended channels of Vidya Portal.
                </li>
                <li style={{ listStyle: 'unset' }}>
                    To resolve any unforeseen dispute with the students and the institutions guiding and safe guarding the interest of the students.
                </li>
                <li style={{ listStyle: 'unset' }}>Screen the eligibility of the students and the capacity to pursue the desired program and make them understand the expenses needed for the curriculum.
                </li>
                <li style={{ listStyle: 'unset' }}>
                    Provide VIDYA PORTAL the application forms of their concerned institution duly complete in all respects using the desired formats and fonts with signatures of the applicants where ever necessary and all required documents stipulated by the institutions.

                </li>
                <li style={{ listStyle: 'unset' }}>
                    Ensure that the documents of the candidates forwarded to the email of Vidya Portal or uploaded through the portal are legitimate, legible and in an ordinary format with attestation/ notarization where ever necessary. The original documents to be submitted through email are the following.
                    <ol type="a" style={{ margin: '1rem 0' }}>
                        <li style={{ listStyle: 'unset' }}>
                            Passport (Scanned Copy)
                        </li>
                        <li style={{ listStyle: 'unset' }}>Academic Transcripts (Scanned copy)
                        </li>
                        <li style={{ listStyle: 'unset' }}>IELTS Certificate (Scanned copy) LOR/Reference Letters. </li>
                        <li style={{ listStyle: 'unset' }}>SOP/Personal Statement (Must be an original-written by the student)
                        </li>
                        <li style={{ listStyle: 'unset' }}>
                            Vidya Portal Authorization From at the time of application.
                        </li>
                    </ol>
                </li>
                <li style={{ listStyle: 'unset' }}>
                    If the documents uploaded are fake or not legible Vidya Portal has no liability what so ever with the students and the entire liability rest with Channel Partner and Vidya Portal has the option to terminate the agreement.
                </li>
                <li>The Channel Partner has to make their students understand and accept privacy policy, data protection and terms of service of Vidya Portal.</li>
                <li>The Channel Partner shall timely communicate Vidya Portal any change in program, institution, preference and personal details of the candidates as early as possible.</li>
                <li>The Channel Partner and their authorized employee shall learn and understand the policies, rules and regulations with regard to the studies of Indian students in the concerned country, international student rights during and after studies, work opportunities and can part this knowledge to their students and can use the website recommended by Vidya Portal for guidance.</li>
                <li>Ensure that their students must be applying to the University for the 1st time (duplicate application to the same University cannot be processed)
                </li>
                <li>Channel Partner should communicate with University through Vidya Portal.</li>
                <li>The confidentiality of communication between Channel Partner and Vidya Portal should be maintained by both parties.
                </li>
                <li>Vidya Portal will assist visa documents and will not take responsibility on visa rejection.
                </li>
                <li>Channel Partner shall not use Intellectual Property, brand name trade marks counselling materials of Vidya Portal in any manner what so ever.
                </li>

                <li>Revenue sharing of different educational institutions between Vidya Portal and Channel Partner is detailed below:-
                </li>
            </ol>
            <br />
            <br />
            The Channel Partner’s share of revenue will be settled within 15 days of receiving commission from the institution after adjusting any dues from the Channel Partner.
            <br />
            <br />
            The Channel Partner hereby ensures that 50% of its candidates will pay the tuition fee demanded by the institutions and if the Channel Partner fails in its undertaking then Vidya Portal has got the right to charge Rs.1000/- for rejected offer letter and this amount will be deducted from the Channel Partner’s revenue.
            <br />
            Dishonest practice on the part of the Channel Partner cannot be tolerated by Vidya Portal and Vidya Portal has got the right to cancel this agreement with no commitments to the students.
            <br />
            <br />

            The Channel Partner has no individual right of publicity/advertisement. That Channel Partner has no right to advertise that they are doing business with VIDYA PORTAL or its partner Universities. Anyhow Channel Partner has got duty to inform the finally chosen student that application has gone through VIDYA PORTAL

            <br />
            <br />
            <br />
            Channel Partner has no claim over the works of VIDYA PORTAL and if any claim is put forwarded then VIDYA PORTAL will consider the claim has breach of contract and can terminate this contract.

            <br />
            In case no commission is receivable from an institution to Vidya Portal or its authorized agent, then the Channel Partner shall make sure that applicable service fee is collected from the candidates and remitted to Vidya Portal
            <br />
            Incertain cases in addition to the commissions announced by the institution Vidya Portal may also announce special offers for institutions/programs/intake session and the benefits can be enjoyed by Channel Partner.

            <br />
            All communications to the students are to be done by the Channel Partner.

            <br />
            <br />
            <h5 style={{ textDecoration: 'underline', fontWeight: '600' }}>COMMISSION RELEASE PROCEDURE</h5>
            Commission will be released within 2 to 12 weeks after the student enrolled to the university. It will be released to the bank account of the Channel Partner.

            <br />
            <br />
            <h5 style={{ textDecoration: 'underline', fontWeight: '600' }}>Arbitration Clause.
            </h5>

            <br />
            <br />

            In the event of any dispute between VIDYA PORTAL and Channel Partner then the parties have a choice to appoint arbitrators to resolve the dispute and Indian Arbitration and conciliation Act 1996 is applicable.

            <br />

            The parties should maintain relationship in a cordial manner and shall not disclose its privacy to any other parties.

            <br />
            In the event of any party of committing any breach of any of the covenants or its part covered by this agreement, the opposite party reserves the right to terminate this agreement by serving notice. If any loss is caused to the party upon such breach the party who sustained the loss can claim it from the other side.
            <br />
            This agreement is signed by the parties on this the day of
            <br />
            <span >{moment().format('Do MMMM YYYY')} </span> at Perinthalmanna

        </div >
    )
}

export default Agreement