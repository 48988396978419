import React, { useContext, useEffect, useState } from 'react'
import { BreadCrumb } from '../../Components/BreadCrumb/BreadCrumb'
// import "/slick/slick.css"; 
// import "/slick/slick-theme.css";
import Slider from "react-slick";
// import UniversityDetail from '../Home/UniversityDetail';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../common/Context/Context';
// import { ManageUniversity } from './UniversityLogic';
import { ManageBlogs } from './BlogsLogic';
// import NoData from '../../Components/NoData/NoData';
import Skeleton from 'react-loading-skeleton';
import Loading from '../../Components/Lottie/Loading'
// import { protocol } from '../../Functionalities/config';
// import { ManagePagination } from '../../Functionalities/PaginationLogic';
import { url } from "../../Functionalities/config";


const BlogDetails = () => {
    const { user } = useContext(Context)
    const { id } = useParams()

    const [data, setdata] = useState([])
    const [CourseData, setCourseData] = useState([])
    const [searchText, setsearchText] = useState('')
    const [loading, setloading] = useState(true)

    const [Prev, setPrev] = useState('')
    const [Next, setNext] = useState('')
    const [currentPage, setcurrentPage] = useState('')
    const [TotalPages, setTotalPages] = useState('')

    useEffect(() => {
        id && GetBlog()
    }, [])


    const GetBlog = () => {
        var axios = require('axios');
        var config = {
            method: 'get',
            url: url + `/blogs/?news_id=${id}`,
        };
        console.warn("config", config);
        axios(config)
            .then(function (response) {
                console.log("blogggg---", response.data.results);
                setdata(response.data.results[0])
                setloading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // const GetCourse = () => {
    //     let params = 'university_id=' + id + '&search_text=' + searchText

    //     let result = ManageCourse('get', '', params)
    //     result.then((res) => {

    //         if (res.data.results) {
    //             console.log(res)
    //             setCourseData(res.data.results)
    //             // setloading(false)
    //             setNext(res.data.next)
    //             setPrev(res.data.previous)
    //             setcurrentPage(res.data.current_page)
    //             setTotalPages(res.data.num_pages)
    //         }
    //     })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }


    // console.log("data", data);

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };
    const Navigate = useNavigate()




    // const handleSearch = (e) => {
    //     e.preventDefault()

    //     GetCourse()
    // }
    // const handleClearSearch = (e) => {
    //     e.preventDefault()
    //     setsearchText('')
    //     GetCourse()
    // }


    return (
        <div>

            <section >
                <div style={{ position: 'relative' }} className="container py-5">
                    <h3 style={{ paddingTop: 40, paddingBottom: 10 }}> {data.title}</h3>
                    <p >
                        {data.content}
                    </p>

                    {data.image?.map(item => (

                        <div style={{}}>
                            <img src={item.docfile}  className='w-100' style={{ height: '55rem', objectFit: 'initial' }} alt="" />
                        </div>
                    ))}
                </div>

                

            </section>

        </div>
    )
}

export default BlogDetails