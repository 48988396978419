import React, { useContext, useEffect, useState } from 'react'
import Collapsible from 'react-collapsible'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Img1 from '../../Assets/img/page-title/page-title-shape-1.png'
import Img2 from '../../Assets/img/page-title/page-title-shape-2.png'
import Img3 from '../../Assets/img/page-title/page-title-shape-4.png'
import Img4 from '../../Assets/img/page-title/page-title-shape-5.png'
import { Context } from '../../common/Context/Context'
import { UseForm } from '../../common/Hooks/UseForm'
import { Validation } from '../../common/Validations/validation'
import { CheckBoxField } from '../../Components/InputField/CheckBoxField'
import { SelectField } from '../../Components/InputField/SelectField'
import { TextField } from '../../Components/InputField/TextField'
import { ShowFailedMessage, ShowSuccessMessage, ValidateEmail } from '../../Functionalities/functions'
import { ManageCourse } from '../Course/CourseLogic'
import { ManageUniversity } from '../University/UniversityLogic'
import { ManageApplications } from './ApplicationLogic'

export const ApplyCourse = () => {
    const navigate = useNavigate();
    const { user, StudentUploadedDocs } = useContext(Context)
    const { state, pathname } = useLocation()
    const [btnDisable, setbtnDisable] = useState(false)
    const [value, setValue] = useState({
        name: '',
        mobile: '',
        email: '',
        country: '',
        university_prefered: '',
        course_prefered: '',
        intakes_prefered: '',
        passport_no: '',
        files: [],
        up_docs: [],
    })
    const [testData, settestData] = useState({})
    const [FileArray, setFileArray] = useState([])
    const [universities, setuniversities] = useState([])
    const [CourseData, setCourseData] = useState([])
    const [intakes, setIntakes] = useState([])







    useEffect(() => {
        value.country && GetUniversity();
        value.university_prefered && GetCourse()
    }, [value])

    useEffect(() => {
        setValue({ ...value, course_prefered: '', intakes_prefered: '', university_prefered: '' })
    }, [value.country])


    const GetUniversity = () => {
        let param = `${value.country && `country=${value.country}`}`
        param += '&pagination=0'
        let result = ManageUniversity('get', '', param)

        result.then((res) => {
            console.log(res.data)

            if (res.data) {
                setuniversities(res.data)

            } else {

                setuniversities([])
            }
        })

            .catch((err) => {
                console.log(err)
            })
    }
    const GetCourse = () => {
        let params = `university_id=${value.university_prefered}`
        params += '&pagination=0'

        let result = ManageCourse('get', '', params)
        result.then((res) => {

            if (res.data) {
                console.log(res)
                setCourseData(res.data)
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }

    const countries = [
        { id: "Canada", value: "Canada" },
        { id: 'Ireland', value: 'Ireland' },
        { id: 'Australia', value: 'Australia' },
        { id: 'United Kingdom', value: 'United Kingdom' },
        { id: 'Singapore', value: 'Singapore' },
        { id: 'United States', value: 'USA' },

        { id: 'Malta', value: 'Malta' },
        { id: 'Finland', value: 'Finland' },
        { id: 'Newzeland', value: 'Newzeland' },
        { id: 'Switzerland', value: 'Switzerland' },
        { id: 'Germany', value: 'Germany' },
        { id: 'France', value: 'France' },
    ]

    // university
    const universityOptions = []
    for (let i = 0; i < universities.length; i++) {
        let universityObj = {
            id: universities[i].id,
            value: universities[i].name
        }
        universityOptions.push(universityObj)

    }

    const courses = []
    for (let i = 0; i < CourseData.length; i++) {
        let courseObj = {
            id: CourseData[i].id,
            value: CourseData[i].course_name
        }
        courses.push(courseObj)
    }

    // const intakes = []
    // for (let i = 0; i < CourseData.length; i++) {
    //     let intakesObj = {
    //         id: CourseData[i].intakes,
    //         value: CourseData[i].intakes,
    //     }
    //     intakes.push(intakesObj)
    // }



    const handleSubmit = (e) => {
        e.preventDefault()
        setbtnDisable(true)
        let validation = ''

        if (user == 'student') {
            validation = Validation(value, ['country', 'university_prefered', 'course_prefered', 'intakes_prefered', 'passport_no'])

        } else {
            validation = Validation(value, ['name', 'mobile', 'email', 'country', 'university_prefered', 'course_prefered', 'intakes_prefered', 'passport_no'])

            if (!ValidateEmail(value.email)) {
                setbtnDisable(false)

                ShowFailedMessage('Invalid email')
                return
            }
        }


        if (!validation) {
            setbtnDisable(false)
            return
        }
        if (!value.intakes_prefered) {
            setbtnDisable(false)
            ShowFailedMessage('please choose intake')
            return
        }

        let result = ManageApplications('add', value, '')

        result.then((res) => {
            console.log(res)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)

                if (user == 'agent' || user == 'staff') {

                    setValue({
                        name: '',
                        university_prefered: state.by_university.id,
                        course_prefered: state.id,
                        intakes_prefered: state.intakes,
                        country: state.by_university.country,

                        mobile: '',
                        email: '',
                        passport_no: '',
                        files: []
                    })
                    setbtnDisable(false)

                    navigate('/applications')

                } else {

                    setValue({
                        name: '',
                        mobile: '',
                        email: '',
                        country: '',
                        university_prefered: '',
                        course_prefered: '',
                        intakes_prefered: '',
                        passport_no: ''

                    })

                    navigate('/my-application')
                    setbtnDisable(false)
                }
            } else {
                ShowFailedMessage(res.data.Message)
                setbtnDisable(false)
            }
        })
            .catch((err) => {
                console.log(err)
                setbtnDisable(false)
            })
    }

    let fileTypes = []
    const uploadMultipleFile = (e) => {

        console.log(e.target.files)
        if (e.target.files) {

            let size = 0;
            let totalSize = 0;
            for (let i = 0; i < e.target.files.length; i++) {
                size = e.target.files[i].size
                totalSize += size
            }

            let limit = 1024 * 1024 * 20
            if (totalSize <= limit) {


                for (let i = 0; i < e.target.files.length; i++) {
                    fileTypes = [...fileTypes, e.target.files[i].type]
                }
                console.log(fileTypes)
                if (fileTypes.includes('video/x-matroska')) {
                    ShowFailedMessage('unsupported format ')
                } else {


                    let SelectedFile = e.target.files;

                    let temp_array = []

                    for (let i = 0; i < SelectedFile.length; i++) {
                        temp_array.push(SelectedFile[i])

                    }
                    temp_array.push(...FileArray)
                    setFileArray(temp_array)


                    console.log(SelectedFile)
                }

            } else {
                ShowFailedMessage('File too Big, please select files less than 20mb')

            }
        }

    }
    useEffect(() => {

        setValue({ ...value, files: FileArray })

    }, [FileArray])


    const removeItem = (value) => {
        console.log(value)


        if (value.id) {

            setValue({ ...value, files: value.files.filter((e) => e != value) })
        } else {
            let arr = [...FileArray]
            console.log({ arr })

            arr = arr.filter(function (item) {
                return item !== value
            })
            console.log({ arr })

            setFileArray(arr)
        }
    }

    useEffect(() => {

        setValue({ ...value })
        // }
    }, [value.course_prefered])



    useEffect(() => {
        if (pathname == '/add-new-application' && user == 'agent') {
            console.log({ state })
            setValue({
                name: state?.name,
                university_prefered: '',
                course_prefered: '',
                intakes_prefered: '',
                country: '',

                mobile: state?.mobile,
                email: state?.email,
                passport_no: state?.passport,
                files: [],
                up_docs: state?.documents,
            })


        } else {
            if (pathname == '/add-new-application') {

                console.log({ state })
                if (state?.id) {
                    setValue({
                        name: '',
                        university_prefered: state.courses.by_university.id,
                        course_prefered: state.courses.id,
                        intakes_prefered: state.intakes,
                        country: state.courses.by_university.country,

                        mobile: '',
                        email: '',
                        passport_no: '',
                        files: [],
                        up_docs: StudentUploadedDocs
                    })
                }
            }else if (pathname == '/apply-course') {
                if (state?.id) {
                    setValue({
                        name: '',
                        university_prefered: state.by_university.id,
                        course_prefered: state.id,
                        intakes_prefered: state.intakes,
                        country: state.by_university.country,

                        mobile: '',
                        email: '',
                        passport_no: '',
                        files: [],
                        up_docs: StudentUploadedDocs
                    })
                }
            }
        }
    }, [state, StudentUploadedDocs])

    useEffect(() => {
        console.log(StudentUploadedDocs)
        if (user == 'student' && !state?.id) {
            setValue({ ...value, up_docs: StudentUploadedDocs })
        }
    }, [StudentUploadedDocs])

    useEffect(() => {
        if (value.course_prefered && CourseData.length) {
            console.log(value.course_prefered)
            console.log(CourseData)
            console.log(CourseData.find(itm => itm.id === value.course_prefered)?.intakes)
            const intake = CourseData.find(itm => itm.id === value.course_prefered)?.intakes
            setValue({ ...value, intakes_prefered: intake })
        }
    }, [value.course_prefered])


    const RequiredDocs = [
        'Passport copy',
        'SSLC Mark list',
        'Plus Two Mark List',
        'Degree Consolidated Mark List',
        'Degree Provisional',
        'Degree Original Certificate',
        'Updated CV',
        'MOI(Medium Of Instruction)',
        'Letter Of Recommendation From HOD',
        'Letter Of Recommendation From Principal',
        'SOP(Statement Of Purpose)',
        'Experience Letter'
    ]

    console.log('context up_docs----', StudentUploadedDocs)
    console.log('up_docs----', value.up_docs)


    let intake = []
    useEffect(() => {
        // setValue({...value})
        // alert('calling')
        CourseData.map(item => {
            let div = item.intakes.split("/")
            let sep = 'AUG / SEP / OCT / NOV';
            for (let i = 0; i < div.length; i++) {
                // console.log("kk", div[i]);
                intake.push({
                    id: div[i] == 'January' || div[i] == 'january' ? 'JAN / FEB / MARCH' : div[i] == 'May' || div[i] == 'may' ? 'MAY / JUNE / APRIL / JULY' : sep,
                    value: div[i] == 'January' || div[i] == 'january' ? 'JAN / FEB / MARCH' : div[i] == 'May' || div[i] == 'may' ? 'MAY / JUNE / APRIL / JULY' : sep,
                })
            }
        })
        console.log({ intake })
        var clean = intake.filter((value, index, self) => self.findIndex((m) => m.id === value.id) === index);
        console.log({ clean })
        setIntakes(clean)
    }, [CourseData])


    return (

        <section className="checkout-area pt-120 pb-70">
            <div className="page__title-shape">
                <img className="page-title-shape-5 d-none d-sm-block" src={Img1} alt="img not found" />
                <img className="page-title-shape-6" src={Img2} alt="img not found" />
                <img className="page-title-shape-7" src={Img3} alt="img not found" />
                <img className="page-title-shape-8" src={Img4} alt="img not found" />
            </div>

            <div className="container">
                <form action="#">
                    <div className="row justify-content-center" >

                        <div className="col-lg-6 p-5" style={{ boxShadow: '0 40px 80px 0 rgba(2,2,26,.14)' }}>
                            <div className="checkbox-form">
                                <h3>Apply For Course</h3>
                                <div className="row">
                                    {(user == 'agent' || user == 'staff') &&
                                        <>
                                            <div className="col-md-6">
                                                <TextField value={value.name} name={'name'} onchange={(e) => setValue({ ...value, name: e.target.value })} label={'Name'} placeholder={'Enter Name'} isRequired={true} type={'text'} />

                                            </div>
                                            <div className="col-md-6">
                                                <TextField value={value.mobile} name={'mobile'} onchange={(e) => setValue({ ...value, mobile: e.target.value.slice(0, 10) })} label={'Mobile'} placeholder={'Enter Mobile'} isRequired={true} type={'number'} />
                                            </div>


                                            <div className="col-md-12">
                                                <TextField value={value.email} name={'email'} onchange={(e) => setValue({ ...value, email: e.target.value })} label={'Email'} placeholder={'Enter Email'} isRequired={true} type={'text'} />

                                            </div>
                                        </>
                                    }
                                    <div className="col-md-12">
                                        <SelectField value={value.country} name={'country'} onchange={(e) => setValue({ ...value, country: e.target.value })} label={'Country preferred'} defaultSelected={'Choose Country'} option={countries} />
                                    </div>
                                    <div className="col-md-12">
                                        <SelectField value={value.university_prefered} name={'university_prefered'} onchange={(e) => setValue({ ...value, university_prefered: e.target.value })} label={'University preferred'} defaultSelected={'Choose University'} option={universityOptions} />
                                    </div>

                                    <div className="col-md-12">
                                        <SelectField value={value.course_prefered} name={'course_prefered'} onchange={(e) => setValue({ ...value, course_prefered: e.target.value })} label={'Course preferred'} defaultSelected={'Choose Course'} option={courses} />
                                        {/* <div className="checkout-form-list"><input type="text" placeholder="Apartment, suite, unit etc. (optional)" /></div> */}
                                    </div>
                                    <div className="col-md-12">
                                        <SelectField value={value.intakes_prefered} name={'intakes_prefered'} onchange={(e) => setValue({ ...value, intakes_prefered: e.target.value })} label={'Intakes preferred'} defaultSelected={'Choose Intake'} option={intakes} defaultValue={CourseData.find(itm => itm.id == value.course_prefered)?.intakes} />
                                    </div>
                                    <div className="col-md-12">
                                        <TextField type={'text'} value={value.passport_no} name={'passport_no'} onchange={(e) => setValue({ ...value, passport_no: e.target.value })} label={'Passport number'} placeholder={'Enter your Passport No'} />
                                    </div>


                                    <div className=" row">
                                        <TextField label={'upload Doc'} type={'file'} multiple={true} buttonName={'Upload File'} id={'docUpload'} isRequired={false} onchange={(e) => uploadMultipleFile(e)} isDisabled={false} isMultiple={true} acceptFile={"image/*,application/*"} />

                                    </div>

                                    {value.files?.length ?

                                        <div className="mb-3 row">


                                            <div className='col-12'>
                                                <div className="row">
                                                    {value.files.map(item => (

                                                        <div className="col-6 mb-2">

                                                            <div className="d-flex align-items-center" style={{
                                                                flexDirection: 'column',
                                                                background: 'rgb(233, 236, 239)',
                                                                padding: '8px',
                                                                borderRadius: '5px',
                                                                height: '3.75rem',
                                                                justifyContent: 'center',
                                                                position: 'relative',
                                                                textAlign: 'center',
                                                                cursor: 'pointer'
                                                            }}>

                                                                <i style={{ position: 'absolute', top: 2, right: 2, padding: '3px' }} onClick={() => removeItem(item)} class="fas fa-times"></i>
                                                                <i class="far fa-file-alt"></i>
                                                                <p className='mb-0' style={{
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    width: '6rem'
                                                                }}>{item.name?.length >= 10 ? `${item.name.split(0, 10)}...` : item.name}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                    }
                                    {value.up_docs?.length ?

                                        <div className="mb-3 row">
                                            <label>Previously Uploaded</label>


                                            <div className='col-12'>
                                                <div className="row">
                                                    {value.up_docs.filter(itm => itm.uploader == 'user').map(item => (

                                                        <div className="col-6 mb-2">
                                                            <a href={item.docfile} download target={'_blank'}>

                                                                <div className="d-flex align-items-center" style={{
                                                                    flexDirection: 'column',
                                                                    background: 'rgb(233, 236, 239)',
                                                                    padding: '8px',
                                                                    borderRadius: '5px',
                                                                    height: '3.75rem',
                                                                    justifyContent: 'center',
                                                                    position: 'relative',
                                                                    textAlign: 'center',
                                                                    cursor: 'pointer'
                                                                }}>

                                                                    {/* <i style={{ position: 'absolute', top: 2, right: 2, padding: '3px' }} onClick={() => removeItem(item)} class="fas fa-times"></i> */}
                                                                    <i class="far fa-file-alt"></i>
                                                                    <p className='mb-0' style={{
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        width: '6rem'
                                                                    }}>{item.name?.length >= 10 ? `${item.name.split(0, 10)}...` : item.name}</p>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                    }

                                    <div className='py-2' style={{ background: '#f0f8ff', borderRadius: '5px' }}>
                                        <div style={{ textDecoration: 'underline' }}>Required Docs</div>
                                        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                                            {RequiredDocs.map(item => (
                                                <p style={{ fontSize: '13px', lineHeight: '19px' }} className='mb-0'>{item} <span className='mx-1'>|</span> </p>

                                            ))}
                                        </div>
                                    </div>




                                    <button className='mt-3 btn btn-primary w-100' type='button' disabled={btnDisable} onClick={handleSubmit} >Apply</button>



                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>


    )
}
