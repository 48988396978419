import { DELETE_API, GET_API, POST_API, PUT_API } from "../../Functionalities/APIS"

export const ManageCourse = async (keyword, data, params) => {


    let path = '/courses/'

    if (keyword == "get") {


        let result = GET_API("", params, path)
        return await result.then((response) => {
            if (response) {
                return response
            } else {

                return 0
            }
        })
    }

    if (keyword.includes('add')) {

        let Data = data.course
        var formData = new FormData()
        // if (data.id) {
        //     formData.append("id", data.id)
        // }



        formData.append("course_name", Data.name)
        formData.append("by_university", data.id)
        formData.append("fees", Data.coursFee)
        formData.append("intakes", Data.intakes)
        formData.append("description", Data.description)
        formData.append("duration", Data.duration)
        formData.append("level", Data.level)




        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }


        // return
        let result = POST_API(formData, "", path);

        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });

    }


    if (keyword == "delete") {
        // path = "/slot"
        var formData = new FormData()

        let result = DELETE_API("", "", params, path);

        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }

}

