import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Img5 from '../../Assets/img/course/teacher/teacher-1.jpg'
import { Context } from '../../common/Context/Context'
import Like from '../../Assets/img/blog/like.png'
import Liked from '../../Assets/img/blog/liked.png'
import { ManageBlogs } from './BlogsLogic'
import { url } from "../../Functionalities/config";
import { ManageUsers } from '../Profile/UserLogic'

const BlogsList = (props) => {
    const { user } = useContext(Context)
    const { pathname } = useLocation()
    const token = localStorage.getItem('vidya_token')
    const [id, setuserdata] = useState('')
    const navigate = useNavigate();

    // console.log(props.selectedCourse.includes(props.data.id))
    // console.log(props.data.id)
    // console.log("props", (JSON.parse(props.likeid)).includes(id), id, props.likeid)

    useEffect(() => {
        GetUserData()
    },[])


    const GetUserData = () => {
        var axios = require('axios');
        var config = {
        method: 'get',
        url: url+`/user/user_profile/`,
        headers: {
            Authorization: `Token ${token}`
        }
        };
        axios(config)
        .then(function (res) {
            if (res.data) {
                // localStorage.setItem('userdetails', res.data[0].id)
                console.log("s-----", res.data[0].id);
                setuserdata(res.data[0].id)
            } 
        })
        .catch(function (error) {
            console.log(error);
        });
    }


    const getLike = (item) => {
        // console.warn("token", token, item.id, (JSON.parse(props.likeid)).includes(id), props.likeid, userdata.id);
    //     let value= item
    //     let path = '/blogs/'
    //     let result = ManageBlogs('patch', value, '', path)

    //     result.then((res) => {
    //         console.log(res)
    //         if (res.data.results) {
    //         //     setreferralData(res.data.results)
    //         console.log("resss====1", res.data.results);

    //         } else {
    //         //     setreferralData([])
    //         console.log("resss====2", res.data.results);
    //         }
    //     })
    //     .catch((err) => {
    //         console.log(err)
    //     })
        var axios = require('axios');
        var formData = new FormData();

        formData.append('news_id', item.id);
        formData.append('keyword', (JSON.parse(props.likeid)).includes(id)? 'unlike' : 'like');
        var config = {
        method: 'patch',
        url: url+'/blogs/',
        headers: { 
            Authorization: `Token ${token}`, 
        },
        data : formData
        };
        axios(config)
        .then(function (response) {
            // console.log("like res",response.data);
            console.log("resss====1", response.data);
            props.call()
        })
        .catch(function (error) {
            console.log(error);
            // console.log("resss====2", response.data.results);
        });
    }

    return (
        // <section class="blog__area pt-115 pb-130" style={{width: '1200px'}}>

        <div className="course__item white-bg fix" style={props.isSelectActive ? { border: `1px solid ${props.selectedCourse?.includes(props.data.id) ? '#0095c7' : ' #dbdbdb'}` } : {}}>
            <div className="course__item white-bg fix" style={{justifyContent: 'start'}}>
                <div className="course__thumb w-img p-relative fix">

                    <Link to={`/blog-details/${props.id}`}>
                        {props.image &&
                            <img src={props.image} style={{
                                height: '220px',
                                objectFit: 'cover'
                            }} />
                        }
                    </Link>

                </div>
                <div className="blog__content">
                    {props.courseName &&
                        <div className="course__meta d-flex align-items-center justify-content-between">
                            <div className="course__lesson d-flex justify-content-between w-100" style={{ flexWrap: 'wrap' }}>
                                <span style={{ marginRight: '1rem' }}><i>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="book" className="svg-inline--fa fa-book fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ width: '0.875em' }}>
                                        <path fill="currentColor" d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z">
                                        </path>
                                    </svg></i>{props.duration}</span>

                                <span>
                                    Fee: {props.fees}
                                </span>
                            </div>

                        </div>
                    }
                    <h3 className="course__title">
                        {/* {props.name &&
                            <Link to={pathname == '/university' ? `/university-details/${props.id}` : ''}>{props.name}</Link>
                        } */}
                        <div class="blog__tag"><a href={`/blog-details/${props.id}`}>{props.name}</a></div>
                    </h3>
                    {props.content &&
                        <>
                            <div className="course__teacher d-flex" style={{
                                flexDirection: 'column',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                    <div className='blog_para text-wrap'>
                                    {props.content}
                                    </div>
                            </div>


                        </>
                    }
                    
                   
                </div>
                {props.PrimaryBtnFunc &&
                    <div className="course__more d-flex justify-content-between align-items-center">
                        <div className="course__btn">
                            <button onClick={props.PrimaryBtnFunc} className="link-btn btn" >{props.PrimaryBtnName}<i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                                </path>
                            </svg></i><i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                                </path>
                            </svg></i></button></div>
                    </div>
                }
                
            </div>
            {props.pageUrl &&
                    <div className="course__more d-flex justify-content-between align-items-center" style={{backgroundColor: '#fff'}}>
                        <div className="course__btn">
                            {props.isSelectActive ?

                                <div>
                                    <input type="checkbox" onChange={props.handleSelect} checked={props.selectedCourse.includes(props.id) ? true : false} />
                                </div>
                                :
                                // <Link to={props.pageUrl} state={user ? props.data : '/sign-in'} className="link-btn btn" >PrimaryBtnName<i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                //     <path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                                //     </path>
                                // </svg></i><i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                //     <path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                                //     </path>
                                // </svg></i></Link>

                                // id != '' &&
                                <div style={{display: 'flex', flexDirection: 'row', }} onClick={() => id != ''? getLike(props.data) : navigate('/sign-in')}>
                                    <div class="thumb">
                                        <img src={(JSON.parse(props.likeid)).includes(id)? Liked : Like } style={{
                                                height: '22px',
                                                width: '17px',
                                                objectFit: 'cover'
                                            }} />
                                    </div>
                                    <div style={{paddingLeft: 10, paddingTop: 3, }}>
                                    {'  '+props.likes}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
        </div> 

        // <div class="container">
        //     <div class="row">
        //         <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
        //             <div class="blog__item white-bg mb-30 transition-3 fix">
        //                 <div class="blog__thumb w-img fix">
        //                     <a href="blog-details.html">
        //                     <Link to={`/university-details/${props.id}`}>
        //                         {props.image &&
        //                             <img src={props.image} style={{
        //                                 height: '220px',
        //                                 objectFit: 'cover'
        //                             }} />
        //                         }
        //                     </Link>
        //                         {/* <img src="assets/img/blog/blog-1.jpg" alt="img not found" /> */}
        //                     </a>
        //                 </div>
        //                 <div class="blog__content">
        //                     <div class="blog__tag"><a href="blog-details.html">{props.name}</a></div>
        //                     <h3 class="blog__title"><a href="blog-details.html">{props.content}</a></h3>
        //                     <div class="blog__meta d-flex align-items-center justify-content-between">
        //                         <div class="blog__author d-flex align-items-center">
        //                             <div class="blog__author-thumb mr-10"><img
        //                                     src="assets/img/blog/author/author-1.jpg" alt="img not found" />
        //                             </div>
        //                             <div class="blog__author-info">
        //                                 <h5>Jim Séchen</h5>
        //                             </div>
        //                         </div>
        //                         <div class="blog__date d-flex align-items-center"><i><svg aria-hidden="true"
        //                                     focusable="false" data-prefix="fas" data-icon="clock"
        //                                     class="svg-inline--fa fa-clock fa-w-16 " role="img"
        //                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        //                                     <path fill="currentColor"
        //                                         d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z">
        //                                     </path>
        //                                 </svg></i><span>April 02, 2022</span></div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
                
        //     </div>
        // </div>
        // </section>
    )

}


export default BlogsList;

