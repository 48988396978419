import React from 'react'
import { Link, useLocation } from 'react-router-dom'


export const BreadCrumb = (props) => {
    const location = useLocation()

    return (
        <div className="col-xxl-12 ">
            <div className="page__title-wrapper mt-110 mb-30" style={!location.pathname.includes('/university-details') ? { mixBlendMode: 'difference' } : {}}>
                <h3 className="page__title">{props.currentPage}</h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>
                        {props.previousPage &&
                            <li className="breadcrumb-item">
                                <Link to={props.previousPageUrl}>{props.previousPage}</Link>
                            </li>
                        }
                        <li className="breadcrumb-item active" aria-current="page">{props.currentPage}</li>
                    </ol>
                </nav>
            </div>
        </div>

    )
}
