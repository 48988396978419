import axios from "axios";
import { url } from "./config";
import { ShowFailedMessage } from "./functions";


export const GET_API = async (headers, params, path) => {
    const token = localStorage.getItem('vidya_token')

    if (!headers) {
        if (token) {
            headers = { 'Authorization': `Token ${token}` }
        }


    }
    if (params != '') {
        console.log('Params in GET API', params)
        var config = {
            method: 'get',
            url: url + path + '?' + params,
            headers: headers,

        };
    }
    else {
        console.log('No params in GET API', params)
        var config = {
            method: 'get',
            url: url + path,
            headers: headers,

        };
    }

    console.log('GET API config', config)

    let response = await axios(config)
        .then(function (res) {
            console.log("GET API response", res)
            return res
        })
        .catch(function (error) {
            console.log("GET API error", error)
            if (error.response) {
                return error.response
            } else {
                return { status: 503, message: "Assumming Server is not running" }
            }
        });

    return ResponseValidation(response)
}


export const POST_API = async (data, headers, path) => {
    const token = localStorage.getItem('vidya_token')

    if (!headers) {

        headers = { 'Authorization': `Token ${token}` }

    }

    var config = {
        method: 'post',
        url: url + path,
        headers: headers,
        data: data
    };
    console.log("POST API config", config)

    console.log(url)
    let response = await axios(config)
        .then(function (response) {
            console.log("POST API response", response);
            return response
        })
        .catch(function (error) {
            console.log("POST API error", error);
            if (error.response) {
                return error.response
            } else {
                return { status: 503 }
            }
        });
    return ResponseValidation(response)
}

export const PATCH_API = async (data, headers, path) => {
    const token = localStorage.getItem('vidya_token')

    if (!headers) {

        headers = { 'Authorization': `Token ${token}` }

    }

    var config = {
        method: 'patch',
        url: url + path,
        headers: headers,
        data: data
    };
    console.log('PATCH API config', config)
    let response = await axios(config)
        .then(function (response) {
            console.log('PATCH API response', response)
            return response
        })
        .catch(function (error) {
            console.log('PATCH API error', error)
            if (error.response) {
                return error.response
            } else {
                return { status: 503 }
            }
        });
    return ResponseValidation(response)
}



export const PUT_API = async (data, headers, path, params) => {

    const token = localStorage.getItem('vidya_token')

    if (!headers) {

        headers = { 'Authorization': `Token ${token}` }

    }
    // console.log(token)
    var config = {
        method: 'put',
        url: url + path,
        headers: headers,
        data: data
    };
    console.log('PUT API config', config)
    let response = await axios(config)
        .then(function (response) {
            console.log("PUT API response", response)
            return response
        })
        .catch(function (error) {
            console.log("PUT API error", error)
            if (error.response) {
                return error.response
            } else {
                return { status: 503 }
            }
        });
    return ResponseValidation(response)
}


export const DELETE_API = async (data, headers, params, path) => {
    const token = localStorage.getItem('vidya_token')

    if (!headers) {

        headers = { 'Authorization': `Token ${token}` }

    }
    var config = {
        method: 'delete',
        url: url + path + "?" + params,
        headers: headers,
        data: data
    };

    console.log("DELETE API config", config)

    let response = await axios(config)
        .then(function (response) {
            console.log("DELETE API response", response)
            return response
        })
        .catch(function (error) {
            console.log("DELETE API error", error)
            if (error.response) {
                return error.response
            } else {
                return { status: 503 }
            }
        });

    return ResponseValidation(response)
}


export const ResponseValidation = (response) => {
    // console.log("Response validation ", response)
    let status_code = null;
    let response_data = null;
    let message = null;

    if (response) {
        if (response.status) {
            console.log("res status", response.status)
            if (response.status === 200) {
                message = "Success";
                status_code = response.status;
                response_data = response;
            } else if (response.status === 401) {
                
                window.location.replace("/");
                message = "Unauthorised user, you dont have permission to access this";
                status_code = response.status;
                response_data = 0;
                ShowFailedMessage(message)
                localStorage.removeItem('vidya_token')
                localStorage.removeItem('user')
            } else if (response.status === 404) {
                message = "Page Not found";
                status_code = response.status;
                response_data = 0;
                console.log(JSON.stringify(response_data))
            } else if (response.status === 500) {
                message = "Internal Server Error";
                status_code = response.status;
                response_data = 0;
                ShowFailedMessage(message)
            } else if (response.status === 503) {
                message = "Seems like server is not running";
                status_code = response.status;
                response_data = 0;
                ShowFailedMessage(message)
                //   showMessage( message, "danger", "Server not running!", "top", "top-right", 0 );
            } else {

                message = "Unhandled Condition met";
                response_data = 0;
                const response_message = JSON.stringify(response.data)
                if (response_message.match("Unable to log in with provided credentials.")) {
                    // showMessage( "invalid username or email ", "danger", "Unhandled!", "top", "top-right", 0 );
                    ShowFailedMessage('invalid username or email')
                } else if (response_message.match("delivery boy's accounts not cleared")) {
                    ShowFailedMessage(response.data.Error)
                    // showMessage( response.data.Error, "danger", "Cannot Delete", "top", "top-right", 0 );
                }
            }
            // console.log("validated data is", response_data);
            return response_data;
        } else {
            message = "unexpected response got";
            console.log("Response validation - No response status", response)
            // showMessage( JSON.stringify(response.data), "danger", "Unexpected response!", "top", "top-right", 0 );
            return 0;
        }
    }
    else {
        console.log("Response validation - no response", response)
        return 0;
    }
};