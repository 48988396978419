import React, { useContext, useEffect, useState } from 'react'
import Collapsible from 'react-collapsible'
import Skeleton from 'react-loading-skeleton'
import { Context } from '../../common/Context/Context'
import { UseForm } from '../../common/Hooks/UseForm'
import { BreadCrumb } from '../../Components/BreadCrumb/BreadCrumb'
import { RadioButtonField } from '../../Components/InputField/CheckBoxField'
import Loading from '../../Components/Lottie/Loading'
import NoData from '../../Components/NoData/NoData'
import { protocol } from '../../Functionalities/config'
import { ManagePagination } from '../../Functionalities/PaginationLogic'
import { ManageApplications } from '../ApplyCourse/ApplicationLogic'
import { ApplicationComponent } from './ApplicationComponent'




export const MyApplication = () => {


  const [data, setdata] = useState({
    country: '',
    intakes: '',
    course: '',
    status: '',
  })
  const { user, isMobile } = useContext(Context)
  const [searchText, setsearchText] = useState('')

  const [Applications, setApplications] = useState([])
  const [Next, setNext] = useState('')
  const [Prev, setPrev] = useState('')
  const [loading, setloading] = useState(true)

  const [currentPage, setcurrentPage] = useState('')
  const [TotalPages, setTotalPages] = useState('')

  useEffect(() => {
    GetApplication()
  }, [data])


  const GetApplication = () => {

    let params = ''

    if (user == 'agent') {
      params = '&country=' + data.country + '&intake=' + data.intakes
      params += '&search_text=' + searchText + `&course=` + data.course + `&status=` + data.status
    }



    // return
    let result = ManageApplications('get', '', params)

    result.then((res) => {
      console.log(res)
      // setClearFilter(false)
      if (res.data.results.length >= 1) {
        setApplications(res.data.results)

        setNext(res.data.next)
        setPrev(res.data.previous)
        setloading(false)

        setcurrentPage(res.data.current_page)
        setTotalPages(res.data.num_pages)
      } else {
        setloading(false)
        setApplications([])
        setNext()
        setPrev()

        setcurrentPage('')
        setTotalPages('')
      }
    })

      .catch((err) => {
        console.log(err)
      })
  }
  const HandlePrev = () => {
    let path = Prev.replace('http:', protocol.replace('//', ''))

    let result = ManagePagination(path, '')

    result.then(function (response) {
      console.log((response.data));
      if (response.data.results) {
        setApplications(response.data.results)
        setNext(response.data.next)
        setPrev(response.data.previous)
        setloading(false)
        window.scrollTo(0, 0)
        setcurrentPage(response.data.current_page)
        setTotalPages(response.data.num_pages)

      }
    })
      .catch(function (error) {
        console.log(error);
      });
  }

  const HandleNext = () => {
    let path = Next.replace('http:', protocol.replace('//', ''))

    let result = ManagePagination(path, '')

    result.then(function (response) {
      console.log((response.data));
      if (response.data.results) {
        setApplications(response.data.results)
        setNext(response.data.next)
        setPrev(response.data.previous)
        setloading(false)
        window.scrollTo(0, 0)
        setcurrentPage(response.data.current_page)
        setTotalPages(response.data.num_pages)

      }
    })
      .catch(function (error) {
        console.log(error);
      });
  }

  const clearFilter = (e) => {
    e.preventDefault()

    setsearchText('');
    setdata({
      ...data,
      country: '',
      intakes: '',
      course: '',
      status: ''
    })
    document.querySelectorAll('input[type=radio]').forEach(el => el.checked = false);

    GetApplication()
  }

  const Intakes = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const Countries = [
    "Canada",
    'Ireland',
    'Australia',
    'United',
    'Singapore',
    'USA',
  ]
  const courses = ['BA', 'MA', 'BBA', 'MBA', 'BSc', 'MSc', 'LLB', 'LLM', 'BEng', 'MEng']


  const status = [
    { value: 'Application received', id: 1 },
    { value: 'Pre-screening interview pending', id: 1.1 },
    { value: 'Information documents processing', id: 1.2 },
    { value: 'Application processed', id: 2 },
    { value: 'Conditional offer issued', id: 2.1 },
    { value: 'Unconditional offer issued', id: 3 },
    { value: 'TT copy send', id: 4 },
    { value: 'Waiting for interview', id: 4.1 },
    { value: 'CAS received', id: 4.2 },
    { value: 'Visa copy uploaded', id: 5 },
  ]

  console.log(data.status)
  return (
    <>
      <div className='container pt-50'>
        <BreadCrumb currentPage={(!user.includes('student')) ? 'Applications' : 'My Application'} />
        <div className="row  pb-120" style={isMobile ? { flexDirection: 'column-reverse' } : {}}>

          <div className={!user.includes('student') ? "col-xxl-9 col-xl-9 col-lg-8" : "col-12"}>
            <div className="course__tab-conent">
              <div className="react-tabs__tab-panel react-tabs__tab-panel--selected" role="tabpanel" id="react-tabs-1" aria-labelledby="react-tabs-0">
                <div className="row">
                  {(Applications.length >= 1 && !loading) ? Applications.map(item => (


                    <ApplicationComponent
                      studentName={item.name ? item.name : ''}
                      passportNo={item.passport}
                      columnClass={'col-12'}
                      id={item.id}
                      status={item.status}
                      course={item.courses.course_name}
                      university={item.courses.by_university.name}
                      duration={item.courses.duration}
                      intake={item.courses.intakes}
                      innerPage={true}
                      fee={item.courses.fees} />
                  ))
                    :
                    <>
                      {[1, 2, 3, 4].map((item, idx) => (

                        <div className={!user.includes('student') ? 'col-12 mb-4' : 'col-12 mb-4'} key={idx}>
                          {loading && <Skeleton className='h-200' />}
                        </div>
                      ))}
                      {loading ? <Loading /> : <NoData />}
                    </>
                  }

                </div>

                {(Next || Prev) &&
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="">

                      <button className='btn btn-primary' onClick={HandlePrev} disabled={!Prev} >Prev</button>
                    </div>
                    <div className="mx-3">
                      {`${currentPage}/${TotalPages}`}
                    </div>
                    <div className="">
                      <button className='btn btn-primary' onClick={HandleNext} disabled={!Next}>Next</button>
                    </div>
                  </div>
                }

              </div>
            </div>
          </div>
          {!user.includes('student') &&
            <div className="col-xxl-3 col-xl-3 col-lg-4 mb-40">
              <div className="course__sidebar pl-10">
                <div className="course__sidebar-course">
                  <div className="course__sidebar-search mb-5">
                    <form action="#">
                      <input type="text" placeholder="Search for Applicant name..." name={'searchText'} onChange={e => setsearchText(e.target.value)} value={searchText} /><button type="button" onClick={() => GetApplication()}><i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z">
                        </path>
                      </svg></i>
                      </button>
                    </form>
                  </div>
                  <div className="course__sidebar-widget grey-bg">
                    <div className="course__sidebar-info">
                      <Collapsible open={false} trigger={<div className='d-flex justify-content-between align-items-center'><h3 className="course__sidebar-title mb-5">Course</h3> <div>{ArrowDown}</div></div>} >



                        <div className="course__sidebar-search mb-3">

                          {/* <form action="#">
                                                    <input type="text" className='bg-white px-3' placeholder="Search for Course" />
                                                    <button type="submit">
                                                        <i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" /></svg></i>
                                                    </button>
                                                </form> */}
                        </div>
                        <div className='row'>

                          {courses.map(item => (
                            <div className="col-6">

                              <RadioButtonField name={'course'} value={data.course} onchange={(e) => setdata({ ...data, course: e.target.id })} label={item} />
                            </div>
                          ))}
                        </div>

                      </Collapsible>
                    </div>
                  </div>
                  <div className="course__sidebar-widget grey-bg">
                    <div className="course__sidebar-info">
                      <div className="d-flex justify-content-between">


                      </div>
                      <Collapsible open={false} trigger={<div className='d-flex justify-content-between align-items-center'><h3 className="course__sidebar-title mb-5">Countries</h3> <div>{ArrowDown}</div></div>} >
                        <ul className='mt-20'>
                          {/* {Countries.map(item => (

                            <RadioButtonField name={'country'} value={data.country} onchange={(e) => setdata({ ...data, country: e.target.id })} label={item} />
                          ))} */}
                          {Countries.map(item => (

                            <RadioButtonField name={'country'} value={data.country} onchange={(e) => {
                              if (e.target.id.includes('United')) {
                                setdata({ ...data, country: 'United' })
                              } else {
                                setdata({ ...data, country: e.target.id })
                              }
                            }} label={data.country.includes('United') ? 'United Kingdom' : item.country} />
                          ))
                          }

                        </ul>
                      </Collapsible>
                    </div>
                  </div>
                  <div className="course__sidebar-widget grey-bg">
                    <div className="course__sidebar-info">
                      <Collapsible open={false} trigger={<div className='d-flex justify-content-between align-items-center'><h3 className="course__sidebar-title mb-0">Status</h3> <div>{ArrowDown}</div></div>}>


                        <ul className='mt-20'>
                          {status.map(item => (

                            <RadioButtonField name={'status'} value={data.status} onchange={() => setdata({ ...data, status: item.value })} label={item.value} />
                          ))}
                        </ul>
                      </Collapsible>
                    </div>
                  </div>
                  <button className='btn btn-primary w-100' onClick={clearFilter}>Clear all</button>


                </div>
              </div>
            </div>
          }
        </div>
      </div>

    </>
  )
}

export const ArrowDown = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width={15} height={15}>
  <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
</svg>
export const ArrowUp = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width={15} height={15}>
  <path d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z" />
</svg>