import { DELETE_API, GET_API, PATCH_API, POST_API, PUT_API } from "../../Functionalities/APIS"

export const ManageNotifications = async (keyword, data, params) => {
    let path = '/notifications/'
    // console.log("ManageCustomer Data :", data)


    if (keyword.includes('patch')) {


        let formData = new FormData()



        formData.append('notification_id', data.id)


        





        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }
        // return
        let result = PATCH_API(formData, '', path)
        return await result.then((response) => {
            console.log('response=======>>', response)
            if (response) {
                console.log("Response return ", response)
                return response
            } else {
                return 0
            }
        })

    }


    if (keyword == "add") {
        let formData = new FormData()

        formData.append('application_id', data.application_id)
        formData.append('heading', data.title)
        formData.append('content', data.body)










        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        // return

        let result = POST_API(formData, '', path);

        return await result.then((response) => {
            if (response) {
                return response
            } else {
                return 0
            }
        })
    }

    if (keyword == "update") {
        let formData = new FormData()



        let result = PUT_API(formData, '', path)
        return await result.then((response) => {
            if (response) {
                return response
            } else {
                return 0
            }
        })
    }

    if (keyword == "get") {


        let result = GET_API("", params, path)
        return await result.then((response) => {
            if (response) {
                return response
            } else {

                return 0
            }
        })
    }

    if (keyword == "delete") {
        let formData = new FormData()
        formData.append('id', data.id)

        let result = DELETE_API(formData, '', '', path);

        return await result.then((response) => {
            if (response) {
                return response
            } else {
                return 0
            }
        })
    }



}

