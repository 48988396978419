import React, { useContext, useEffect, useState } from 'react'
import { BreadCrumb } from '../../Components/BreadCrumb/BreadCrumb'
import Collapsible from 'react-collapsible'
import { ManageBlogs } from './BlogsLogic'
import NoData from '../../Components/NoData/NoData'
import Skeleton from 'react-loading-skeleton'
import BlogsList from './BlogsList'
import Loading from '../../Components/Lottie/Loading'
import { url } from "../../Functionalities/config";
import { RWebShare } from "react-web-share";
import { Context } from '../../common/Context/Context'

const Blogs = () => {
    const { user } = useContext(Context)
    const [loading, setloading] = useState(true)
    const [data, setdata] = useState([])
    const [id, setId] = useState('')
    const [selectShareActive, setselectShareActive] = useState(false)
    const [selectCourses, setselectCourses] = useState([])
    const [selectAll, setselectAll] = useState(true)
    const [shareData, setshareData] = useState("")


    useEffect(() => {
        GetBlogs()
    },[])

    const GetBlogs = () => {
        var axios = require('axios');
        var config = {
          method: 'get',
          url: url+'/blogs/',
        };
        console.warn("config", config);
        axios(config)
        .then(function (res) {
            console.log("blog",res.data.results)
            setdata(res.data.results)
            setloading(false)
        })
        .catch(function (error) {
          console.log(error);
          setloading(false)
        });
      }

    const handleSelectAll = (e) => {
        let array = []
        e.preventDefault()
        setselectAll(!selectAll)
        setselectCourses([])
        console.log("Select all ", data)

        // let share="";
        for (let i = 0; i < data.length; i++) {
            //     console.log(data[i].course_name)
            array.push(data[i].id)
        }

        setselectCourses(array)

    }

    useEffect(() => {
        let share = ''

        const SelectedCourseArr = data.filter(itm => selectCourses.includes(itm.id))

        for (let i = 0; i < SelectedCourseArr.length; i++) {
            share = share + 
             SelectedCourseArr[i].title + '\n' +
             SelectedCourseArr[i].name + '\n'
        }
        // console.log('af loop------------------', share)
        setshareData(share)
    }, [selectCourses])



    // const GetBlogs = () => {
    //     // let params = ''

    //     let result = ManageBlogs('get', '', '')
    //     result.then((res) => {

    //         if (res.data.results) {
    //             console.log("blog",res.data.results)
    //             setdata(res.data.results)
    //             setloading(false)
    //         }
    //     })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    return (
        <section className="course__area">
            <div className="container pt-50">
                <BreadCrumb currentPage={'Blogs'} />
                <div className="row  pb-120" >
                    <div className="col-xxl-9 col-xl-9 col-lg-8">
                        <div className="row">

                        <div className='col-12 d-flex justify-content-end'>
                                {(selectShareActive && selectCourses.length <= 5) &&
                                    <button className='btn btn-outline-dark mb-3' style={{ marginRight: '.5rem' }} onClick={handleSelectAll} >{'Select All'}</button>
                                }
                                {(selectShareActive && !!selectCourses.length) &&
                                    <RWebShare
                                        data={{
                                            text: shareData,
                                            url: window.location.href,
                                            title: "Courses"
                                        }}
                                        onClick={() => console.log(shareData)}

                                    >
                                        <button className='btn btn-outline-dark mb-3 ' style={{ marginRight: '.5rem' }} > <div>{ShareIcon} <span className='ml-2'>Share</span></div></button>
                                    </RWebShare>
                                }
                                {data.length >= 1 &&
                                    <button className='btn btn-outline-dark mb-3' onClick={() => { setselectShareActive(!selectShareActive); setselectCourses([]) }}> {selectShareActive ? 'Cancel' : <div>{ShareIcon} <span className='ml-2'>Share</span></div>}</button>
                                }
                            </div>

                            {(data.length >= 1 && !loading) ? data?.map(item => (

                                <div className=" col-md-6 mb-4">

                                    <BlogsList 
                                        isSelectActive={selectShareActive}
                                        call={() => GetBlogs()} 
                                        userId={id} 
                                        id={item.id} 
                                        image={item.image[0].docfile} 
                                        name={item.title.trim()} 
                                        content={item.content.trim()} 
                                        data={item} 
                                        likeid={item.like} 
                                        likes={item.number_of_likes}
                                        selectedCourse={selectCourses}
                                        pageUrl={(user == 'agent' || user == 'student' || user == 'staff') ? '/apply-course' : '/sign-in'}
                                        handleSelect={e => {
                                            // console.log(e.target.checked === true && !selectCourses.includes(item.id))
                                            if (e.target.checked === true && !selectCourses.includes(item.id)) {
                                                setselectCourses([...selectCourses, item.id])
                                            } else {
                                                setselectCourses(selectCourses.filter(itm => item.id !== itm))
                                            }
                                        }}
                                        />
                                </div>
                            ))
                                :
                                <>
                                    {[1, 2, 3, 4].map((item, idx) => (

                                        <div className=" col-md-6 mb-4" key={idx}>
                                            {loading &&
                                                <>

                                                    <Skeleton className='h-200' />
                                                </>
                                            }
                                        </div>
                                    ))}

                                    {loading ?
                                        <Loading />
                                        :
                                        <NoData />
                                    }
                                </>
                            }


                        </div>

                        {/* {(Next || Prev) &&
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="">

                                    <button className='btn btn-primary' onClick={HandlePrev} disabled={!Prev} >Prev</button>
                                </div>
                                <div className="mx-3">
                                    {`${currentPage}/${TotalPages}`}
                                </div>
                                <div className="">
                                    <button className='btn btn-primary' onClick={HandleNext} disabled={!Next}>Next</button>
                                </div>
                            </div>
                        } */}


                    </div>
                    

                </div>
            </div>


            

        </section>
    )
}
export default Blogs;
export const ShareIcon = <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={15} height={15} viewBox="0 0 50 50" fill='currentColor'><path d="M 40 0 C 34.535156 0 30.078125 4.398438 30 9.84375 C 30 9.894531 30 9.949219 30 10 C 30 13.6875 31.996094 16.890625 34.96875 18.625 C 36.445313 19.488281 38.167969 20 40 20 C 45.515625 20 50 15.515625 50 10 C 50 4.484375 45.515625 0 40 0 Z M 28.0625 10.84375 L 17.84375 15.96875 C 20.222656 18.03125 21.785156 21 21.96875 24.34375 L 32.3125 19.15625 C 29.898438 17.128906 28.300781 14.175781 28.0625 10.84375 Z M 10 15 C 4.484375 15 0 19.484375 0 25 C 0 30.515625 4.484375 35 10 35 C 12.050781 35 13.941406 34.375 15.53125 33.3125 C 18.214844 31.519531 20 28.472656 20 25 C 20 21.410156 18.089844 18.265625 15.25 16.5 C 13.71875 15.546875 11.929688 15 10 15 Z M 21.96875 25.65625 C 21.785156 28.996094 20.25 31.996094 17.875 34.0625 L 28.0625 39.15625 C 28.300781 35.824219 29.871094 32.875 32.28125 30.84375 Z M 40 30 C 37.9375 30 36.03125 30.644531 34.4375 31.71875 C 31.769531 33.515625 30 36.542969 30 40 C 30 40.015625 30 40.015625 30 40.03125 C 29.957031 40.035156 29.917969 40.058594 29.875 40.0625 L 30 40.125 C 30.066406 45.582031 34.527344 50 40 50 C 45.515625 50 50 45.515625 50 40 C 50 34.484375 45.515625 30 40 30 Z" /></svg>
