import { POST_API, GET_API, PUT_API, DELETE_API, PATCH_API } from '../../Functionalities/APIS'

export const ManageUsers = async (keyword, data, params, path) => {
    console.log(params,)
    let headers = ""
    // if(token){
    //     headers = { 'Authorization': token }
    // }


    if (keyword == "add") {

        if (path.includes('supervisor')) {
            var formData = new FormData()
            if (data.id) {
                formData.append("id", data.id)
            }


            formData.append("name", data.name)
            formData.append("email", data.email)
            formData.append("mobile", data.mobile)
            formData.append("password", data.password)
            formData.append("re_password", data.re_password)
            formData.append("for_who", data.for_who)
        }

        if (path.includes('register')) {
            var formData = new FormData()
            if (data.id) {
                formData.append("id", data.id)
            }


            formData.append("keyword", data.is_blocked ? 'block_agent' : 'unblock_agent')


        }
        if (path.includes('myreferrals')) {
            var formData = new FormData()

            if (data) {

                formData.append("application_id", data)
            }
        }
        if (path.includes('mystaffs')) {
            
            var formData = new FormData();
            formData.append('name', data.name);
            formData.append('email', data.email);
            formData.append('mobile', data.mobile);
            formData.append('password', data.password);
            formData.append('re_password', data.re_password);
        }




        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        // return

        let result = POST_API(formData, "", path);

        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }

    if (keyword == "update") {


        var formData = new FormData();

        formData.append("id", data.id)
        formData.append("keyword", data.keyword)
        formData.append("coins", data.coin_value)
        formData.append("description", data.description)

        if (data.generate_coupon) {
            formData.append("generate_coupon", 1)
        } else {
            formData.append("generate_coupon", 0)
        }



        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        // return
        let result = PUT_API(formData, "", path);

        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }
    if (keyword == "patch") {

        console.log('handle_by', data.handled_by)

        if (path.includes('list_users')) {
            var formData = new FormData()


            if (data.handled_by) {

                formData.append("agent_id", data.id)
                data.is_approved ? formData.append("keyword", data.handled_by && 'agent_reassign') : formData.append("keyword", data.handled_by && 'agent_approval')
                formData.append("supervisor_id", data.handled_by)
            }
            else {
                formData.append("agent_id", data.id)
                formData.append("keyword", data.is_blocked ? 'block_agent' : 'unblock_agent')

            }



        }



        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        // return
        let result = PATCH_API(formData, "", path);

        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }

    if (keyword == "get") {

        let result = GET_API(headers, params, path, data);
        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }



    if (keyword == "delete") {
        // path = "/slot"
        var formData = new FormData()

        let result = DELETE_API("", "", params, path);

        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }
}