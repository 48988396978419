import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../Assets/Logo/LogoIcon.svg'
import { Context } from '../../common/Context/Context'
import { version } from '../../Functionalities/config'

const Footer = (Supervisor) => {
    const { user } = useContext(Context)

    // console.log("sup=====", Supervisor.Supervisor);
    return (
        <footer>
            <div className="footer__area grey-bg-2">
                <div className="footer__top pt-md-190 pt-50 pb-40">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <div className="footer__widget mb-50">
                                    <div className="footer__widget-head mb-22">
                                        <div className="footer__logo"><Link to="/"><img src={Logo} alt="vidhya Logo" /></Link></div>
                                    </div>
                                    <div className="footer__widget-body footer__widget-body-2">
                                        <p>VIDYA PORTAL is an education agency which provides guidance on study abroad for selecting apt country, institution and course. It also provides facilities and guidance to get better overseas education as well as education with in the country</p>
                                        <div className="footer__social">
                                            <ul>
                                                <li><a href={Supervisor.Supervisor != '' ? `https://wa.me/+91${Supervisor.username}` : "https://wa.me/+918943338806"}><img style={{ marginTop: '-5px' }} src="https://img.icons8.com/color/20/000000/whatsapp--v1.png" /></a></li>
                                                <li><a href="#" className="tw"><i ><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" className="svg-inline--fa fa-twitter fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: '.85rem', marginTop: '-5px' }}>
                                                    <path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" >
                                                    </path>
                                                </svg></i></a></li>
                                                <li><a href="#" className="pin"><i><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="pinterest-p" className="svg-inline--fa fa-pinterest-p fa-w-12 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style={{ width: '.85rem' }}>
                                                    <path fill="currentColor" d="M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z" >
                                                    </path>
                                                </svg></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-lg-4 col-md-4 col-sm-6 md-ml-4 footer-pages-link">
                                <div className="footer__widget mb-50">
                                    <div className="footer__widget-head mb-22">
                                        <h3 className="footer__widget-title footer__widget-title-2">Pages</h3>
                                    </div>
                                    <div className="footer__widget-body">
                                        <div className="footer__link footer__link-2">
                                            <ul>
                                                <li><Link to="/">Home</Link></li>
                                                <li><Link to="/university">University</Link></li>
                                                <li><Link to="/course">Courses</Link></li>
                                                {user ?
                                                    <li><Link to="/profile">Profile</Link></li>
                                                    :
                                                    <li><Link to="/sign-in">Sign in</Link></li>
                                                }
                                                {user &&

                                                    user == 'student' ?
                                                    <li><a href='/Studentstermsandcondition.html' target={'_blank'}>Terms & conditions</a></li>
                                                    :
                                                    user == ('agent' || 'staff') &&
                                                    <li><a href="/agentsTERMSANDCONDITION.html" target={'_blank'}>Terms & conditions</a></li>
                                                }
                                                <li><a href="https://sites.google.com/view/vidyaportalprivacypolicy/home" target={'_blank'}>Privacy Policy</a></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                <div className="footer__widget mb-50">
                                    <div className="footer__widget-head mb-22">
                                        <h3 className="footer__widget-title footer__widget-title-2">Contact Us</h3>
                                    </div>
                                    <div className="footer__widget-body">
                                        <div className="footer__link footer__link-2">
                                            <ul>
                                                <li><a className='align-items-center' style={{ display: 'inline-flex' }}>
                                                    <svg id="noun-email-4604134" xmlns="http://www.w3.org/2000/svg" width="18.618" height="13.382" viewBox="0 0 18.618 13.382">
                                                        <path id="Path_112" data-name="Path 112" d="M142.291,119H128.327A2.327,2.327,0,0,0,126,121.327v8.727a2.327,2.327,0,0,0,2.327,2.327h13.964a2.327,2.327,0,0,0,2.327-2.327v-8.727A2.327,2.327,0,0,0,142.291,119Zm1.164,11.054a1.163,1.163,0,0,1-1.164,1.164H128.327a1.163,1.163,0,0,1-1.164-1.164v-8.727a1.163,1.163,0,0,1,1.164-1.164h13.964a1.163,1.163,0,0,1,1.164,1.164Z" transform="translate(-126 -119)" fill="#0f0f0f" />
                                                        <path id="Path_113" data-name="Path 113" d="M194.983,189.151l-6.032,4.934-6.032-4.934a.582.582,0,1,0-.738.9l6.4,5.236a.582.582,0,0,0,.738,0l6.4-5.236a.582.582,0,0,0-.738-.9Z" transform="translate(-179.642 -186.109)" fill="#0f0f0f" />
                                                    </svg>

                                                    <span className='mx-2'>hello@vidyaportal.com</span>
                                                </a></li>
                                                <li><a className='align-items-center' style={{ display: 'inline-flex' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.767" height="17.757" viewBox="0 0 17.767 17.757">
                                                        <path id="noun-call-4602569" d="M98.07,32.488a4.193,4.193,0,0,1-.584-.049,14.895,14.895,0,0,1-12.6-12.6,4.378,4.378,0,0,1,1.231-3.677l.457-.457A2.369,2.369,0,0,1,88.546,15a2.2,2.2,0,0,1,1.644,1.07l.676,1.119a2.471,2.471,0,0,1-.146,2.763L90,20.926a2.024,2.024,0,0,0-.117,2.257,12.56,12.56,0,0,0,4.271,4.276,2.024,2.024,0,0,0,2.257-.117l.973-.72h0a2.462,2.462,0,0,1,2.758-.141l1.1.657a2.272,2.272,0,0,1,.438,3.546l-.535.53a4.378,4.378,0,0,1-3.084,1.274Zm-.486-1.007h0a3.4,3.4,0,0,0,2.865-.973l.53-.535h0a1.284,1.284,0,0,0,.37-1.07,1.27,1.27,0,0,0-.618-.949l-1.1-.661a1.493,1.493,0,0,0-1.654.088l-.973.73a2.982,2.982,0,0,1-3.342.17,13.452,13.452,0,0,1-4.6-4.607,2.982,2.982,0,0,1,.161-3.347l.715-.973h0a1.5,1.5,0,0,0,.088-1.678l-.671-1.119a1.255,1.255,0,0,0-.919-.608,1.425,1.425,0,0,0-1.158.448l-.486.457a3.405,3.405,0,0,0-.973,2.865A13.921,13.921,0,0,0,97.6,31.477Z" transform="translate(-84.71 -14.857)" fill="#0f0f0f" stroke="#0f0f0f" strokeWidth="0.25" />
                                                    </svg>
                                                    <span className='mx-2'>+918943338801</span>
                                                </a></li>

                                                <li><a className='align-items-start' style={{ display: 'inline-flex' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.502" height="28.753" viewBox="0 0 13.502 18.753">
                                                        <path id="Icon_material-location-on" data-name="Icon material-location-on" d="M13.626,3A6.122,6.122,0,0,0,7.5,9.126c0,4.595,6.126,11.377,6.126,11.377s6.126-6.782,6.126-11.377A6.122,6.122,0,0,0,13.626,3Zm0,8.314a2.188,2.188,0,1,1,2.188-2.188A2.189,2.189,0,0,1,13.626,11.314Z" transform="translate(-6.875 -2.375)" fill="none" stroke="#0f0f0f" strokeLinejoin="round" strokeWidth="1.25" />
                                                    </svg>

                                                    <span className='mx-2'>Second Floor, Silver Plaza Building,
                                                        <br />
                                                        Near Govt Hospital
                                                        <br />
                                                        Perinthalmanna, Malappuram
                                                        <br />
                                                        Kerala
                                                        679 322</span>
                                                </a></li>






                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer__top pt-md-190">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 d-flex justify-content-center mb-20 mt-0">
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d979.2123718897652!2d76.23303486951849!3d10.974731215902182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7cd27098eb653%3A0x6eab2ee3099b2468!2sWESTBAY%20INTERNATIONAL%20EDUCATION%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1698783893477!5m2!1sen!2sin" width={400} height={300} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" /> */}

                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d979.2123718897652!2d76.23303486951849!3d10.974731215902182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7cd27098eb653%3A0x6eab2ee3099b2468!2sWESTBAY%20INTERNATIONAL%20EDUCATION%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1698783893477!5m2!1sen!2sin" width={800} height={300} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__bottom footer__bottom-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="footer__copyright footer__copyright-2 text-center">
                                    <p>© 2023 Vidya Portal, All Rights Reserved. Maintained By <a href="mailto:solutionizeit.x@gmail.com">SolutionizeIT</a> | {version}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer