import { DELETE_API, GET_API, PATCH_API, POST_API, PUT_API } from "../../Functionalities/APIS"

export const ManageApplications = async (keyword, data, params) => {
    let path = '/application/'
    // console.log("ManageCustomer Data :", data)


    if (keyword.includes('patch')) {


        let formData = new FormData()



        formData.append('application_id', data.id)

        if (data.docs) {
            for (let i = 0; i < data.docs.length; i++) {
                formData.append('files', data.docs[i])

            }
        }





        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }
        // return
        let result = PATCH_API(formData, '', path)
        return await result.then((response) => {
            console.log('response=======>>', response)
            if (response) {
                console.log("Response return ", response)
                return response
            } else {
                return 0
            }
        })

    }


    if (keyword == "add") {
        // alert('post api triggered')
        let formData = new FormData()
        data.agent && formData.append('id', data.agent)
        data.name && formData.append('name', data.name)
        data.mobile && formData.append('mobile', data.mobile)
        data.email && formData.append('email', data.email)
        formData.append('university', data.university_prefered)
        formData.append('country', data.country)
        formData.append('course', data.course_prefered)
        formData.append('intake', data.intakes_prefered)
        formData.append('passport', data.passport_no)

        for (let i = 0; i < data.up_docs?.length; i++) {
            if (data.up_docs[i].uploader == 'user') {
                formData.append('up_docs', data.up_docs[i].id)
            }

            // console.log('up_docs--->', data.up_docs[i])

        }

        for (let i = 0; i < data.files?.length; i++) {
            formData.append('files', data.files[i])
        }




        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        // return

        let result = POST_API(formData, '', path);

        return await result.then((response) => {
            console.log(response)
            if (response) {
                return response
            } else {
                return 0
            }
        })
    }

    if (keyword == "update") {
        let formData = new FormData()

        formData.append('id', data.id)
        formData.append('image', data.image)
        formData.append('title', data.title)
        formData.append('description', data.description)

        let result = PUT_API(formData, '', path)
        return await result.then((response) => {
            if (response) {
                return response
            } else {
                return 0
            }
        })
    }

    if (keyword == "get") {


        let result = GET_API("", params, path)
        return await result.then((response) => {
            if (response) {
                return response
            } else {

                return 0
            }
        })
    }

    if (keyword == "delete") {
        let formData = new FormData()
        formData.append('id', data.id)

        let result = DELETE_API(formData, '', '', path);

        return await result.then((response) => {
            if (response) {
                return response
            } else {
                return 0
            }
        })
    }



}

