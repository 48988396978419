import React from 'react'
import * as animationData from './Lottie.json'
import Lottie from 'react-lottie';

const Loading = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className='w-100 d-flex justify-content-center align-items-center' style={{
            position: 'fixed',
            zIndex: '100',
            height: '100vh',
            background: 'rgba(255, 255, 255, 0.85)',
            top: '0',
            right: '0',
            left: '0'
        }}>
            {/* <Lottie /> */}
            <Lottie options={defaultOptions}
                height={100}
                width={100}
            />
        </div>
    )
}

export default Loading