import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Context } from '../../common/Context/Context'

export const ApplicationComponent = (props) => {
  const { user } = useContext(Context)
  const { pathname } = useLocation()

  return (
    <div class={props.columnClass}>
      <div class="course__item white-bg mb-30 fix" style={pathname.includes('application') && { height: 'unset' }}>
        <div className="row gx-0">
          {/* <div className="col-xxl-4 col-xl-4 col-lg-4">
            <div className="course__thumb course__thumb-list w-img p-relative fix">
              <a href="/course-details">
                <img src="assets/img/course/list/course-2.jpg" alt="img not found" />
              </a>
              <div className="course__tag">
                <a className="sky-blue" href="/course-details">Art &amp; Design</a>
              </div>
            </div>
          </div> */}
          <div className="col-xxl-12 col-xl-12 col-lg-12">
            <div className="course__right">
              <div className="course__content course__content-3 p-4">
                <div className="course__meta d-flex align-items-center justify-content-between" style={{ flexWrap: 'wrap' }}>
                  <div className="course__lesson mr-20">
                    {user.includes('student') ?
                      `Application ID :- ${props.id}`
                      :
                      `Applicant Name: ${props.studentName}`
                    }
                  </div>
                  <div className="course__lesson ">
                    {user.includes('student') &&
                      <svg width={15} fill={'var(--bs-primary)'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M256 56c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m0-48C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 168c-44.183 0-80 35.817-80 80s35.817 80 80 80 80-35.817 80-80-35.817-80-80-80z" /></svg>
                    }
                    {user.includes('student') ?
                      <span className='ml-5'>{props.status}</span>
                      :
                      `Application ID : ${props.id}`
                    }
                  </div>
                  {/* <div className="course__rating">
                    <span><i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" /></svg></i>4.5 (44)</span>
                  </div> */}
                </div>

                <div className="d-flex justify-content-between">
                  <h4 className="course__title course__title-4">
                    <a >{props.course}</a>
                  </h4>

                </div>

                <div className=" university-intake-container">
                  {user.includes('student') ?
                    <>
                      <div className="course__summary">
                        <p className='mb-0'>{props.university}</p>
                      </div>
                      <div className="course__lesson ">
                        <span><i><svg width={15} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="book" className="svg-inline--fa fa-book fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z" /></svg>
                        </i>{props.duration}</span>
                      </div>
                      <div className="course__lesson ">
                        <span>
                          <i>
                            <svg svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 640 512" width={20}>
                              <path d="M484.6 62C502.6 52.8 522.6 48 542.8 48H600.2C627.2 48 645.9 74.95 636.4 100.2C618.2 148.9 582.1 188.9 535.6 212.2L262.8 348.6C258.3 350.8 253.4 352 248.4 352H110.7C101.4 352 92.5 347.9 86.42 340.8L13.34 255.6C6.562 247.7 9.019 235.5 18.33 230.8L50.49 214.8C59.05 210.5 69.06 210.2 77.8 214.1L135.1 239.1L234.6 189.7L87.64 95.2C77.21 88.49 78.05 72.98 89.14 67.43L135 44.48C150.1 36.52 169.5 35.55 186.1 41.8L381 114.9L484.6 62zM0 480C0 462.3 14.33 448 32 448H608C625.7 448 640 462.3 640 480C640 497.7 625.7 512 608 512H32C14.33 512 0 497.7 0 480z" />
                            </svg>

                          </i>{props.intake}</span>
                      </div>
                    </>
                    :
                    <div className=" university-intake-container" style={{
                      flexDirection: 'column',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                    >
                      <div className="course__summary">
                        <p className='mb-0'>{props.university}</p>
                      </div>
                      <div className="course__lesson ">
                        <span><i><svg width={15} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="book" className="svg-inline--fa fa-book fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z" /></svg>
                        </i>{props.duration}</span>
                      </div>
                      <div className="course__lesson ">
                        <span>
                          <i>
                            <svg svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 640 512" width={20}>
                              <path d="M484.6 62C502.6 52.8 522.6 48 542.8 48H600.2C627.2 48 645.9 74.95 636.4 100.2C618.2 148.9 582.1 188.9 535.6 212.2L262.8 348.6C258.3 350.8 253.4 352 248.4 352H110.7C101.4 352 92.5 347.9 86.42 340.8L13.34 255.6C6.562 247.7 9.019 235.5 18.33 230.8L50.49 214.8C59.05 210.5 69.06 210.2 77.8 214.1L135.1 239.1L234.6 189.7L87.64 95.2C77.21 88.49 78.05 72.98 89.14 67.43L135 44.48C150.1 36.52 169.5 35.55 186.1 41.8L381 114.9L484.6 62zM0 480C0 462.3 14.33 448 32 448H608C625.7 448 640 462.3 640 480C640 497.7 625.7 512 608 512H32C14.33 512 0 497.7 0 480z" />
                            </svg>

                          </i>{props.intake}</span>
                      </div>
                      <div className="course__lesson" style={{
                        flexWrap: 'wrap',
                        maxWidth: '30rem',
                        width: '100%'
                      }}>

                        <span style={{ marginRight: '1rem' }}><i><svg width={15} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="book" className="svg-inline--fa fa-book fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z" /></svg>
                        </i>{`Passport No:- ${props.passportNo}`}</span>

                        <div>
                          <svg width={15} fill={'var(--bs-primary)'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M256 56c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m0-48C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 168c-44.183 0-80 35.817-80 80s35.817 80 80 80 80-35.817 80-80-35.817-80-80-80z" /></svg>
                          <span className='ml-5'>{props.status}</span>
                        </div>

                      </div>
                    </div>
                  }
                </div>


              </div>
              <div className="course__more course__more-2 d-flex justify-content-between align-items-center px-4" style={{ flexWrap: 'wrap' }}>
                <div className="course__status d-flex align-items-center">
                  {/* {user.includes('student') && */}
                  <>
                    <span className="course__summary">{'Course Fee'}</span>
                    <span className="sky-blue">{props.fee}</span>
                  </>
                  {/* } */}

                </div>
                {props.innerPage &&
                  <div className="course__btn">
                    <Link className="link-btn" to={`/application-detail/${props.id}`}>View Details<i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" /></svg></i>
                      <i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" /></svg></i>
                    </Link>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
