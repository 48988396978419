import React, { useEffect } from 'react'
import YellowBg from '../../Assets/img/shape/yellow-bg-2.png'
import CanadaImage from '../../Assets/Countries/pexels-andre-furtado-2916828.jpeg'
import IrelandImage from '../../Assets/Countries/pexels-kelly-l-2382681.jpg'
import UkImage from '../../Assets/Countries/pexels-pixabay-258117.jpeg'
import AustraliaImage from '../../Assets/Countries/pexels-mudassir-ali-1878293.jpeg'
import USAImgae from '../../Assets/Countries/ferdinand-stohr-PeFk7fzxTdk.jpeg'
import SingaporeImage from '../../Assets/Countries/jeremy-kwok-lbOKcBPgoPQ-.jpeg'
import { Link } from 'react-router-dom'
import ScrollContainer from 'react-indiana-drag-scroll'
import { ManageCountries } from '../CommonAPI'

const ServiceSection = () => {

    const defaultData = [
        { name: 'Canada', image: CanadaImage },
        { name: 'Ireland', image: IrelandImage },
        { name: 'United Kingdom', image: UkImage },
        { name: 'Australia', image: AustraliaImage },
        { name: 'USA', image: USAImgae },
        { name: 'Singapore', image: SingaporeImage },
    ]

    const [countires, setCountires] = React.useState([])

    useEffect(() => {
        getCountry()
    }, [])

    const getCountry = () => {
        let params = 'pagination=0'
        // return console.log({params})
        let result = ManageCountries('get', '', params)
        result.then((res) => {
            console.log("DB Countries", res)
            if (res.data) {
                setCountires(res.data)
                // setloading(false)
            }
        })
            .catch((err) => {
                console.log(err)
                setCountires(defaultData)

            })

    }



    return (
        <section className="services__area pt-115 pb-115">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3">
                        <div className="section__title-wrapper section-padding mb-60 text-center">
                            <h2 className="section__title">Choose the  <span className="yellow-bg">best across  <img src={YellowBg} alt="img not found" /></span>
                                the globe</h2>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">

                    {countires.map(item => (

                        <>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 d-none d-sm-block">
                                <Link to='/university' state={{ country: item.name == 'United Kingdom' ? 'United' : item.filter_value }}>

                                    <div className="services__item blue-bg-4 mb-30 country-img" style={{
                                        background: `url(${item.image})`,

                                    }}>
                                        {item.name}
                                    </div>
                                </Link>
                            </div>
                        </>
                    ))}
                    <ScrollContainer horizontal={true} vertical={false} className="scroll-container d-flex d-sm-none">

                        {countires.map(item => (
                            <>

                                <Link to='/university' state={{ country: item.name == 'United Kingdom' ? 'United' : item.filter_value }}>

                                    <div className='px-2'>
                                        <div className="services__item blue-bg-4 mb-30 country-img" style={{
                                            background: `url(${item.image})`,
                                            width: '14rem'
                                        }}>
                                            {item.name}
                                        </div>
                                    </div>
                                </Link>

                            </>
                        ))}
                    </ScrollContainer>


                </div>
            </div>
        </section >
    )
}

export default ServiceSection