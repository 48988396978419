import { DELETE_API, GET_API, POST_API, PUT_API } from "../../Functionalities/APIS"

export const ManageUniversity = async (keyword, data, params) => {


    let path = '/university/'

    if (keyword == "get") {


        let result = GET_API("", params, path)
        return await result.then((response) => {
            if (response) {
                return response
            } else {

                return 0
            }
        })
    }

    if (keyword.includes('add')) {

        console.log(data.Image.new_images)
        // return
        let Data = data.data
        var formData = new FormData()
        // if (data.id) {
        //     formData.append("id", data.id)
        // }



        formData.append("name", Data.name)
        formData.append("address", Data.address)
        formData.append("country", Data.country)
        formData.append("description", Data.description)

        for (let i = 0; i < data.Image.new_images.length; i++) {

            // const element = array[i];
            formData.append("files", data.Image.new_images[i])

        }
        formData.append("website", Data.url)


        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }


        // return
        let result = POST_API(formData, "", path);

        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });

    }


    if (keyword == "delete") {
        // path = "/slot"
        var formData = new FormData()

        let result = DELETE_API("", "", params, path);

        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }

}

export const ManageTestimonials = async (keyword, data, params) => {
    let path = '/testimonial/'
    if (keyword == "get") {
        let result = GET_API("", params, path)
        return await result.then((response) => {
            if (response) {
                return response
            } else {

                return 0
            }
        })
    }
}


export const ManageEvents = async (keyword, data, params) => {
    let path = '/event/'
    if (keyword == "get") {
        let result = GET_API("", params, path)
        return await result.then((response) => {
            if (response) {
                return response
            } else {

                return 0
            }
        })
    }
}

