import React from 'react'
import './slider.css'

export const StatusSlider = (props) => {
    const status = [
        { value: 'Application received', id: 1 },
        { value: 'Pre-screening interview pending', id: 1.1 },
        { value: 'Information documents processing', id: 1.2 },
        { value: 'Application processed', id: 2 },
        { value: 'Conditional offer issued', id: 2.1 },
        { value: 'Unconditional offer issued', id: 3 },
        { value: 'TT copy send', id: 4 },
        { value: 'Waiting for interview', id: 4.1 },
        { value: 'CAS received', id: 4.2 },
        { value: 'Visa copy uploaded', id: 5 },
    ]

// console.log(props.status)

    return (
        <div className='slider-- d-flex'>
            <div className="d-flex align-items-center" style={{ width: '2rem', flexDirection: 'column' }}>
                <div className={props.dotStatus ? 'dot status-success' : 'dot status-pending'}></div>
                {!props.isLastOne &&
                    <div className={props.lineStatus ? 'line status-success' : 'line status-pending'} />
                }
            </div>
            <div className='status-content'>
                <h6 >{status.find(itm => itm.id == props.title)?.value }</h6>
                <p style={{ fontSize: 'smaller', marginBottom: '0' }}>{status.find(itm => itm.id == props.desc)?.value}</p>
                {props.desc2 && <p style={{ fontSize: 'smaller', marginBottom: '0' }}>{status.find(itm => itm.id == props.desc2)?.value}</p>}
            </div>
        </div>
    )
}
