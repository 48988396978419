import { Slide, toast } from "react-toastify"

export const PreventNonNumeric = (e) => {
  if (e.key === "e" || e.key === "+" || e.key === "-" || e.key === "." || e.key === "*") {
    e.preventDefault()
  }
}


export const restrictWhiteSpace = (e, value) => {
  if (!value) {
    if (e.key === " ") {
      e.preventDefault()
    }
  }

  if (value) {
    if ((e.key === " " && value.length === 0)) {
      e.preventDefault()
    }
  }
}

export const DisableScrollTONumberChange = (e) => {
  e.target.blur()
}

export const ValidateEmail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return (true)
  }
  return (false)
}

export const ShowFailedMessage = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'light',
    transition: Slide,
    progress: false,
  });
}

export const ShowSuccessMessage = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'light',
    transition: Slide,
    progress: false,
  });
}

export const formatDate = (date, format) => {
  if (!date) {
    return "Not Available"
  }
  let inputDate = new Date(date)
  // console.log(date, inputDate)
  let day = inputDate.getDate();
  if (day.toString().length == 1) {
    day = "0" + day.toString()
  }
  let month = (inputDate.getMonth() + 1);
  if (month.toString().length == 1) {
    month = "0" + month.toString()
  }
  let year = inputDate.getFullYear();
  let hour = inputDate.getHours();
  if (hour.toString().length == 1) {
    hour = "0" + hour.toString()
  }
  let minute = inputDate.getMinutes();
  if (minute.toString().length == 1) {
    minute = "0" + minute.toString()
  }
  let second = inputDate.getSeconds();
  // console.log(day, month, year)
  if (format == "DD-MM-YYYY/") {
    let formatted_date = day + "-" + month + "-" + year
    return formatted_date;
  }
  if (format == "DD-MM-YYYY") {
    let formatted_date = day + "-" + month + "-" + year + " " + hour + ":" + minute
    return formatted_date;
  }
  if (format == "DD-MM-YY") {
    let formatted_date = day + "-" + month + "-" + year.toString().slice(2, 4)
    return formatted_date;
  }
  if (format == "YYYY-MM-DD") {
    let formatted_date = year + "-" + month + "-" + day + " " + hour + ":" + minute
    return formatted_date;
  }
  if (format == "YYYY-MM-DD/") {
    let formatted_date = year + "-" + month + "-" + day
    return formatted_date;
  }
}
