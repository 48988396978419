import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Context } from '../../common/Context/Context'
import { BreadCrumb } from '../../Components/BreadCrumb/BreadCrumb'
// import { CommentBox } from '../../Components/CommentBox/CommentBox'
import { StatusSlider } from '../../Components/StatusSlider.js/StatusSlider'
import { ManageApplications } from '../ApplyCourse/ApplicationLogic'
import { ApplicationComponent } from './ApplicationComponent'
import { DownloadDoc } from './DownloadDoc'
import CommentSection from '../../Components/CommentBox/CommentBox'
import { TextField } from '../../Components/InputField/TextField'
import { ShowFailedMessage, ShowSuccessMessage } from '../../Functionalities/functions'

const ApplicationDetails = () => {
    const { id } = useParams()
    const { user } = useContext(Context)
    const [IsSuccess, setIsSuccess] = useState(true)
    const [data, setdata] = useState({})
    const [Next, setNext] = useState('')
    const [Prev, setPrev] = useState('')
    const [loading, setloading] = useState(true)
    const [FileArray, setFileArray] = useState([])
    const [docs, setdocs] = useState([])
    useEffect(() => {
        GetApplication()
    }, [])


    const GetApplication = () => {

        let param = 'application_id=' + id


        // return
        let result = ManageApplications('get', '', param)

        result.then((res) => {
            console.log('application details -------------', res)
            // setClearFilter(false)
            if (res.data) {
                setdata(res.data.results[0])

                setNext(res.data.next)
                setPrev(res.data.previous)
                setloading(false)

            } else {
                setloading(false)
                setdata({})
                setNext()
                setPrev()
            }
        })

            .catch((err) => {
                console.log(err)
            })
    }
    // console.log(data.user.user.first_name)

    const status = [
        { value: 'Application received', id: 1 },
        { value: 'Pre-screening interview pending', id: 1.1 },
        { value: 'Information documents processing', id: 1.2 },
        { value: 'Application processed', id: 2 },
        { value: 'Conditional offer issued', id: 2.1 },
        { value: 'Unconditional offer issued', id: 3 },
        { value: 'TT copy send', id: 4 },
        { value: 'Waiting for interview', id: 4.1 },
        { value: 'CAS received', id: 4.2 },
        { value: 'Visa copy uploaded', id: 5 },
    ]
    let fileTypes = []
    const uploadMultipleFile = (e) => {

        console.log(e.target.files)
        if (e.target.files) {

            let size = 0;
            let totalSize = 0;
            for (let i = 0; i < e.target.files.length; i++) {
                size = e.target.files[i].size
                totalSize += size
            }

            let limit = 1024 * 1024 * 20
            if (totalSize <= limit) {


                for (let i = 0; i < e.target.files.length; i++) {
                    fileTypes = [...fileTypes, e.target.files[i].type]
                }
                console.log(fileTypes)
                if (fileTypes.includes('video/x-matroska')) {
                    ShowFailedMessage('unsupported format ')
                } else {


                    let SelectedFile = e.target.files;

                    let temp_array = []

                    for (let i = 0; i < SelectedFile.length; i++) {
                        temp_array.push(SelectedFile[i])

                    }
                    temp_array.push(...FileArray)
                    setFileArray(temp_array)


                    console.log(SelectedFile)
                }

            } else {
                ShowFailedMessage('File too Big, please select files less than 20mb')

            }
        }

    }


    useEffect(() => {

        setdocs(FileArray)

    }, [FileArray])


    const removeItem = (value) => {
        console.log(value)


        if (value.id) {

            docs([...value, value.files.filter((e) => e != value)])
        } else {
            let arr = [...FileArray]
            console.log({ arr })

            arr = arr.filter(function (item) {
                return item !== value
            })
            console.log({ arr })

            setFileArray(arr)
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        let newData = { ...data, docs }
        // console.log({newData})
        let result = ManageApplications('patch', newData, '')
        result.then((res) => {
            console.log('doc upload res', res)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                setdocs([])
                GetApplication()
            } else {
                ShowFailedMessage(res.data.Message)
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }

    const stage = status.find(itm => itm.value == data.status)?.id

    // console.log('-----------stage', stage, (stage >= 3 && stage < 4))

    // console.log('----------------------', data.documents?.find(itm => itm)?.uploader)
    return (
        <div className='container mb-70 pt-50'>
            <BreadCrumb currentPage={'Application '} previousPage={'My Application'} previousPageUrl={'/my-application'} />

            {/* desktop view */}

            <div className="row d-lg-flex d-none">

                <div className="col-lg-8 ">
                    <div className="course__tab-conent">
                        <div className="react-tabs__tab-panel react-tabs__tab-panel--selected" role="tabpanel" id="react-tabs-1" aria-labelledby="react-tabs-0">
                            <div className="row">

                                {data.user &&
                                    <ApplicationComponent
                                        studentName={data.name}
                                        passportNo={data?.passport}
                                        columnClass={'col-12'}
                                        id={data?.id}
                                        status={data.status}
                                        course={data?.courses.course_name}
                                        university={data?.courses.by_university.name}
                                        duration={data?.courses.duration}
                                        intake={data?.courses.intakes}
                                        fee={data?.courses.fees} />
                                }
                            </div>
                        </div>
                    </div>
                    <CommentSection id={id} />
                </div>

                <div className="col-lg-4 ">
                    {!user.includes('student') &&
                        <div className="col-12 mb-3">
                            <Link to={'/add-new-application'} state={data} className='btn btn-primary w-100'>
                                Add One more Application
                            </Link>
                        </div>
                    }


                    <div className="course__item px-4 pt-3 mb-3" style={{ height: 'unset', }}>
                        <h4 className='mb-2'>Documents</h4>
                        <p className='mb-0 mt-4'>{'issued documents'}</p>
                        {data.documents?.filter(itm => itm.uploader == 'admin').map(item => (
                            item.uploader == 'admin' &&
                            <>

                                <DownloadDoc name={item.name} url={item.docfile} isdownloadble={true} />
                            </>



                        ))}
                        <p className='mb-0 mt-4'>{'uploaded documents'}</p>
                        {data.documents?.filter(itm => itm.uploader == 'user').map(item => (

                            item.uploader == 'user' &&
                            <>

                                <DownloadDoc name={item.name} url={item.docfile} isdownloadble={true} />
                            </>


                        ))}



                        <div className="row mt-4">
                            <TextField label={'upload Doc'} type={'file'} multiple={true} buttonName={'Upload Files'} id={'docUpload'} isRequired={false} onchange={(e) => uploadMultipleFile(e)} isDisabled={false} isMultiple={true} acceptFile={"image/*,application/*"} />

                        </div>
                        {docs?.length ?

                            <div className="mb-3 row">


                                <div className='col-12'>
                                    <div className="row">
                                        {docs.map(item => (

                                            <div className="col-6 mb-2">

                                                <div className="d-flex align-items-center" style={{
                                                    flexDirection: 'column',
                                                    background: 'rgb(233, 236, 239)',
                                                    padding: '8px',
                                                    borderRadius: '5px',
                                                    height: '3.75rem',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                    textAlign: 'center',
                                                    cursor: 'pointer'
                                                }}>

                                                    <i style={{ position: 'absolute', top: 2, right: 2, padding: '3px' }} onClick={() => removeItem(item)} class="fas fa-times"></i>
                                                    <i class="far fa-file-alt"></i>
                                                    <p className='mb-0' style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        width: '6rem'
                                                    }}>{item.name.length >= 10 ? `${item.name.split(0, 10)}...` : item.name}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="col-12">
                                    <button type="button" className='btn btn-primary w-100' onClick={handleSubmit} >Submit</button>
                                </div>
                            </div>
                            : ""
                        }
                    </div>




                    <div className="course__item p-lg-5 p-3" style={{ height: 'unset' }}>

                        <h4 className='mb-4'>Application status </h4>
                        <StatusSlider lineStatus={stage > 1 ? 1 : 0} dotStatus={(stage >= 1) ? 1 : 0} title={1} desc={1.1} desc2={1.2} />
                        <StatusSlider lineStatus={(stage > 2) ? 1 : 0} dotStatus={(stage >= 2) ? 1 : 0} title={2} desc={2.1} />
                        <StatusSlider lineStatus={(stage > 3) ? 1 : 0} dotStatus={(stage >= 3) ? 1 : 0} title={3} />
                        <StatusSlider lineStatus={(stage > 4) ? 1 : 0} dotStatus={(stage >= 4) ? 1 : 0} title={4} desc={4.1} desc2={4.2} />
                        <StatusSlider lineStatus={(stage > 5) ? 1 : 0} dotStatus={(stage >= 5) ? 1 : 0} isLastOne={true} title={5} />



                    </div>
                </div>

            </div>
            {/* desktop view */}

            {/* mobile & tab view */}
            <div className="row d-lg-none d-flex">

                <div className="col-lg-8 md-mt-70 mt-0">
                    <div className="course__tab-conent">
                        <div className="react-tabs__tab-panel react-tabs__tab-panel--selected" role="tabpanel" id="react-tabs-1" aria-labelledby="react-tabs-0">
                            <div className="row">


                                {data.user &&
                                    <ApplicationComponent
                                        studentName={data?.user?.user?.first_name}
                                        passportNo={data?.passport}
                                        columnClass={'col-12'}
                                        id={data?.id}
                                        status={data.status}
                                        course={data?.courses.course_name}
                                        university={data?.courses.by_university.name}
                                        duration={data?.courses.duration}
                                        intake={data?.courses.intakes}
                                        fee={data?.courses.fees} />
                                }
                                {!user.includes('student') &&
                                    <div className="col-12 mb-3">
                                        <Link to={'/add-new-application'} state={data} className='btn btn-primary w-100'>
                                            Add One more Application
                                        </Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    {data.documents?.length ?
                        <div className="course__item px-4 pt-3 mb-3" style={{ height: 'unset', }}>
                            <h4 className='mb-2'>Documents</h4>
                            {data.documents.map(item => (
                                item.uploader == 'admin' &&
                                <DownloadDoc name={item.name} url={item.docfile} isdownloadble={true} />
                            ))}

                            <div className="row ">
                                <TextField label={'upload Doc'} type={'file'} multiple={true} buttonName={'Upload Files'} id={'docUpload'} isRequired={false} onchange={(e) => uploadMultipleFile(e)} isDisabled={false} isMultiple={true} acceptFile={"image/*,application/*"} />

                            </div>
                            {docs?.length ?

                                <div className="mb-3 row">


                                    <div className='col-12'>
                                        <div className="row">
                                            {docs.map(item => (

                                                <div className="col-6 mb-2">

                                                    <div className="d-flex align-items-center" style={{
                                                        flexDirection: 'column',
                                                        background: 'rgb(233, 236, 239)',
                                                        padding: '8px',
                                                        borderRadius: '5px',
                                                        height: '3.75rem',
                                                        justifyContent: 'center',
                                                        position: 'relative',
                                                        textAlign: 'center',
                                                        cursor: 'pointer'
                                                    }}>

                                                        <i style={{ position: 'absolute', top: 2, right: 2, padding: '3px' }} onClick={() => removeItem(item)} class="fas fa-times"></i>
                                                        <i class="far fa-file-alt"></i>
                                                        <p className='mb-0' style={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            width: '6rem'
                                                        }}>{item.name.length >= 10 ? `${item.name.split(0, 10)}...` : item.name}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <button type="button" className='btn btn-primary w-100' onClick={handleSubmit} >Submit</button>
                                    </div>
                                </div>
                                : ""
                            }
                        </div>
                        :
                        <>
                        </>
                    }

                </div>

                <div className="col-lg-4 lg-mt-120">

                    <div className="course__item p-lg-5 p-3" style={{ height: 'unset' }}>

                        <h4 className='mb-4'>Application status </h4>
                        <StatusSlider lineStatus={stage > 1 ? 1 : 0} dotStatus={(stage >= 1) ? 1 : 0} title={1} desc={1.1} />
                        <StatusSlider lineStatus={(stage > 2) ? 1 : 0} dotStatus={(stage >= 2) ? 1 : 0} title={2} desc={2.1} />
                        <StatusSlider lineStatus={(stage > 3) ? 1 : 0} dotStatus={(stage >= 3) ? 1 : 0} title={3} />
                        <StatusSlider lineStatus={(stage > 4) ? 1 : 0} dotStatus={(stage >= 4) ? 1 : 0} title={4} desc={4.1} desc2={4.2} />
                        <StatusSlider lineStatus={(stage > 5) ? 1 : 0} dotStatus={(stage >= 5) ? 1 : 0} isLastOne={true} title={5} />



                    </div>
                    <div className="mt-3">

                        <CommentSection id={id} />
                    </div>
                </div>

            </div>
            {/* mobile & tab view */}
        </div >
    )
}

export default ApplicationDetails