import { POST_API, GET_API, PUT_API, DELETE_API, PATCH_API } from '../../Functionalities/APIS'

export const ManageBanner = async (keyword, data, params, path) => {
    console.log(params,)
    let headers = ""



    if (keyword == "add") {

        var formData = new FormData()

        if (path.includes('/admins/commission/')) {

            if (typeof (data.image) === "object" && (data.image)) {
                formData.append("commission", data.image)
            }

        } else {

            if (typeof (data.image) === "object" && (data.image)) {
                formData.append("banner", data.image)
            }
        }





        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        // return

        let result = POST_API(formData, "", path);

        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }

    if (keyword == "update") {


        var formData = new FormData();

        formData.append("id", data.id)
        // formData.append("keyword", data.keyword)
        // formData.append("coins", data.coin_value)

        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        // return
        let result = PUT_API(formData, "", path);

        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }
    if (keyword == "patch") {

        // return console.log({data})

        var formData = new FormData();

        formData.append("banner_id", data.banner_id)
        formData.append("banner_status", data.Status ? 'Active' : 'Inactive')


        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        // return
        let result = PATCH_API(formData, "", path);

        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }

    if (keyword == "get") {

        let result = GET_API(headers, params, path, data);
        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }



    if (keyword == "delete") {
        // path = "/slot"
        var formData = new FormData()

        let result = DELETE_API("", "", params, path);

        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }
}