import { useEffect, useState } from 'react'

export const UseForm = (InitialValue, ClearState, setClearState) => {
    const [value, setvalue] = useState(InitialValue)

    // console.log(InitialValue)
    useEffect(() => {
        setvalue(InitialValue)
    }, [InitialValue])

    if (ClearState) {
        console.log('clearing state')
        setClearState(false)
        setvalue(InitialValue)
    }
    return [value, (e) => {
        if (e.target?.name == 'mobile') {

            setvalue({ ...value, [e.target?.name]: e.target?.value.slice(0, 15) })
        } else {

            setvalue({ ...value, [e.target?.name]: e.target?.value })
        }
    }]





}
