import React from 'react'
import { PreventNonNumeric } from '../../Functionalities/functions'

export const TextField = (props) => {
    return (
        <div className="checkout-form-list">
            <label>{props.label} {props.isRequired ? <span className="required"> * </span> : ''}</label>
            <input type={props.type} name={props.name} id={props.id} onKeyDown={(e) => props.type == 'number' && PreventNonNumeric(e)} placeholder={props.placeholder} value={props.value} onChange={props.onchange} multiple={props.isMultiple} accept={props.acceptFile} />
            {props.type == 'file' &&
                <button type="button" className="btn btn-success mb-1" onClick={() => document.querySelector(`#${props.id}`).click()}>
                    {props.buttonName}
                </button>

                

            }
        </div>

    )
}
