import React, { createRef, useContext, useEffect, useState } from 'react'
import Img1 from '../../Assets/img/icon/sign/man-3.png'
import Img2 from '../../Assets/img/icon/sign/man-2.png'
import Img3 from '../../Assets/img/icon/sign/circle.png'
import Img4 from '../../Assets/img/icon/sign/zigzag.png'
import Img5 from '../../Assets/img/icon/sign/dot.png'
import Img6 from '../../Assets/img/icon/sign/sign-up.png'
import Img7 from '../../Assets/img/icon/sign/flower.png'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { DisableScrollTONumberChange, PreventNonNumeric, ShowFailedMessage, ShowSuccessMessage } from '../../Functionalities/functions'
import { UseForm } from '../../common/Hooks/UseForm.js'
import { Context } from '../../common/Context/Context'
import { url } from '../../Functionalities/config'
import { validateEmail, Validation } from '../../common/Validations/validation'
import Agreement from './Agreement'
import { PDFExport } from '@progress/kendo-react-pdf';
import ConfirmationModal from '../Modal/confirmationModal'


const initialState = {
    name: '',
    email: '',
    mobile: '',
    country_id: "",
    referral_code: '',
    password: '',
    confirm_password: '',
    company_name: '',
    company_address: ''
}




const SignUp = () => {
    const pdfExportComponent = React.useRef(null);
    const location = useLocation()
    const navigate = useNavigate()

    const [AgreementView, setAgreementView] = useState(false)
    const [ShowPassord, setShowPassord] = useState(false)
    const [ClearState, setClearState] = useState(false)
    const [data, setdata] = UseForm(initialState, ClearState, setClearState)
    const [UserType, setUserType] = useState('')
    const [isAgreed, setisAgreed] = useState(false)
    const [otpVerified, setotpVerified] = useState(false)
    const [otp, setotp] = useState('')
    const [otpSend, setotpSend] = useState(false)
    const [counter, setcounter] = useState(29)
    const [counterStart, setcounterStart] = useState(false)
    const [referral, setReferral] = useState('')
    const [isModalOpen, setisModalOpen] = useState(false)
    const [countryList, setcountryList] = useState([])
    const [calling_code, setcalling_code] = useState("")

    const handleBack = () => {
        setUserType('')
        setClearState(true)
        setAgreementView(false)
        setisAgreed(false)
        setotpSend(false)
        setotpVerified(false)
        setotp('')
    }

    useEffect(() => {
        getCountry()
    }, [])

    useEffect(() => {
        console.log("Data ", data)


        let country_array = countryList.filter(item => item.id == data.country_id)
        if (country_array.length > 0) {
            setcalling_code(country_array[0].calling_code)

        }
        else {
            console.log("country not found", country_array)
            setcalling_code("")

        }
        // [0].calling_code

    }, [data.country_id])

    useEffect(() => {
        const params = new URL(window.location.href).searchParams;
        const ref = params.get('referer');
        if (ref) {
            setTimeout(() => { setUserType('student') }, 50)
            setReferral(ref)
        }
        // setdata(pre => ({ ...pre, referral_code: ref }))
    }, [UserType])


    console.log("window", referral, UserType == '', UserType);

    useEffect(() => {
        if (location.state) {
            setUserType(location.state?.typeOfUser)
        } else {
            setUserType('')
        }


    }, [location.state])


    useEffect(() => {


        const timer = (counter > 1 && counterStart) && setInterval(() => { setcounter(counter - 1) }, 1000);
        if (counter == 1) {
            setcounterStart(false)
            setcounter(29)
        }

        return () => { clearInterval(timer) }
    }, [counter, counterStart])

    const getCountry = () => {
        var axios = require('axios');
        var FormData = require('form-data');

        var config = {
            method: 'get',
            url: url + '/country/?pagination=0&is_active=1',
            data: {}
        };

        axios(config)
            .then(function (response) {
                setcountryList(response.data)
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const handleSubmit = (e) => {
        e.preventDefault()



        if (UserType.includes('student')) {
            let validation = Validation(data, ['name', 'email', 'mobile', 'password', 'confirm_password'])

            if (!validation) {
                return
            }
            if (!validateEmail(data.email)) {
                ShowFailedMessage('Invalid email')
                return
            }
            if (data.password !== data.confirm_password) {
                ShowFailedMessage('Passwords does not match')
                return
            }

            var axios = require('axios');
            var FormData = require('form-data');
            var formdata = new FormData();
            formdata.append('keyword', 'student');
            formdata.append('name', data.name);
            formdata.append('email', data.email);
            formdata.append('mobile', data.mobile);
            formdata.append('country_id', data.country_id);
            formdata.append('referral_code', referral != "" ? referral : data.referral_code);
            formdata.append('password', data.password);
            formdata.append('re_password', data.confirm_password);

            for (var pair of formdata.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }


            var config = {
                method: 'post',
                url: url + '/user/register/',
                data: formdata
            };

            axios(config)
                .then(function (res) {
                    if (res.data.Status) {
                        ShowSuccessMessage(res.data.Message)
                        if (referral != '') {
                            setisModalOpen(true)
                        } else {
                            navigate('/sign-in')
                        }
                        setUserType('')
                        setClearState(true)
                        setAgreementView(false)
                        setisAgreed(false)
                    } else {
                        ShowFailedMessage(res.data.Message)
                        setUserType('')
                        setClearState(true)
                        setAgreementView(false)
                        setisAgreed(false)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        if (UserType.includes('agent')) {

            let validation = Validation(data, ['name', 'email', 'mobile', 'password', 'confirm_password', 'company_name', 'company_address'])

            if (!validation) {
                return
            }
            if (data.password !== data.confirm_password) {
                ShowFailedMessage('Passwords does not match')
                return
            }
            window.scrollTo(0, 0)
            setAgreementView(true)


        }
    }

    const handleAgentSignup = (e) => {
        e.preventDefault()


        if (isAgreed) {
            var axios = require('axios');
            var FormData = require('form-data');
            var formdata = new FormData();
            formdata.append('keyword', 'agent');
            formdata.append('name', data.name);
            formdata.append('email', data.email);
            formdata.append('mobile', data.mobile);
            formdata.append('country_id', data.country_id);
            formdata.append('password', data.password);
            formdata.append('re_password', data.confirm_password);
            formdata.append('company_name', data.company_name);
            formdata.append('company_address', data.company_address);

            for (var pair of formdata.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }

            var config = {
                method: 'post',
                url: url + '/user/v2/register/',
                data: formdata
            };

            axios(config)
                .then(function (res) {
                    console.log({ res })
                    if (res.data.Status) {
                        ShowSuccessMessage(res.data.Message)
                        navigate('/sign-in')
                    } else {
                        ShowFailedMessage(res.data.Message)

                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }


    const SendOtp = () => {

        if (calling_code == "") {
            ShowFailedMessage('Please select country')
            return
        }

        let formatted_mobile = calling_code + data.mobile

        var axios = require('axios');
        var FormData = require('form-data');
        var fdata = new FormData();
        fdata.append('mobile', formatted_mobile);
        var config = {
            method: 'post',
            url: url + '/notifications/otp/',
            data: fdata
        };
        axios(config)
            .then(function (response) {
                console.log(response.data)
                if (response.data.Status) {
                    setotpSend(true)
                } else {
                    setotpSend(false)
                    ShowFailedMessage(response.data.Error)
                }

            })
            .catch(function (error) {
                setotpSend(false)
                console.log(error);
            });
    }

    const VerifyOtp = () => {

        let formatted_mobile = calling_code + data.mobile

        var axios = require('axios');
        var FormData = require('form-data');
        var formdata = new FormData();
        formdata.append('mobile', formatted_mobile);
        formdata.append('otp', otp);
        var config = {
            method: 'post',
            url: url + '/notifications/verify/',

            data: formdata
        };
        axios(config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.Status) {
                    setotpVerified(true)
                } else {
                    setotpVerified(false)
                    ShowFailedMessage(response.data.Error)
                }
            })
            .catch(function (error) {
                console.log(error);
                setotpVerified(false)

            });
    }
    const ResendOTP = (e) => {
        e.preventDefault()
        setcounterStart(true)
        SendOtp()

    }

    return (
        <section className="signup__area po-rel-z1 pt-100 pb-145">
            <div className="sign__shape">
                <img className="man-1" src={Img1} alt="img not found" />
                <img className="man-2 man-22" src={Img2} alt="img not found" />
                <img className="circle" src={Img3} alt="img not found" />
                <img className="zigzag" src={Img4} alt="img not found" />
                <img className="dot" src={Img5} alt="img not found" />
                <img className="bg" src={Img6} alt="img not found" />
                <img className="flower" src={Img7} alt="img not found" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
                        <div className="section__title-wrapper text-center mb-55">
                            <h2 className="section__title">Start your <br /> journey here</h2>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2" style={{ marginTop: UserType == '' ? 90 : 0, marginBottom: UserType == '' ? 90 : 0 }}>
                        <div className="sign__wrapper white-bg">
                            {/* <div className="sign__header mb-35">
                                <div className="sign__in text-center"><a href="#" className="sign__social g-plus text-start mb-15"><i><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" className="svg-inline--fa fa-google fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
                                    <path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z">
                                    </path>
                                </svg></i>Sign Up with Google</a>
                                    <p> <span>........</span> Or, <a href="sign-up.html">sign up</a> with your
                                        email<span> ........</span> </p>
                                </div>
                            </div> */}
                            <div className="sign__form">

                                <form >
                                    {UserType &&
                                        <div className="sign__new  mb-20">
                                            <button type='button' className='btn d-flex align-items-center' onClick={handleBack}>
                                                <svg width={15} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" /></svg>
                                                <span style={{ marginLeft: '.5rem' }}>back</span>
                                            </button>
                                        </div>
                                    }

                                    {UserType == '' ?
                                        <>
                                            <div className="sign__input-wrapper mb-20">
                                                <h5>What defines you</h5>
                                                <div className="d-flex">
                                                    <button type='button' className='w-100 mx-1 btn btn-outline-primary' onClick={() => setUserType('student')}>Student</button>
                                                    <button type='button' className='w-100 mx-1 btn btn-outline-primary' onClick={() => setUserType('agent')}>Agent</button>
                                                </div>
                                            </div>
                                        </> :
                                        UserType &&
                                        <>
                                            {!otpVerified ?
                                                <>
                                                    <h5>Country</h5>

                                                    <div className="col-md-12">
                                                        <div className="country-select">
                                                            {/* <label>Country <span className="required">*</span></label> */}
                                                            <select onChange={setdata} name="country_id" value={data.country_id}>
                                                                <option value="">Select Country</option>
                                                                {countryList.map((item, index) => <option value={item.id}> {item.name} (+{item.calling_code})</option>)}

                                                                {/* <option value="UK">UK (+44)</option>
                                                            <option value="Canada">Canada (+1)</option>
                                                            <option value="Australia">Australia (+61)</option> */}
                                                            </select>

                                                        </div>
                                                    </div>


                                                    <div className="sign__input-wrapper mb-25">
                                                        <h5>Mobile</h5>


                                                        <div className="sign__input">
                                                            <input type="number" onKeyDown={PreventNonNumeric} onWheel={e => e.target.blur()} placeholder="Mobile Number" value={data.mobile} name={'mobile'} onChange={setdata} />
                                                            <i>
                                                                <svg xmlns="http://www.w3.org/2000/svg" height={11} fill='#8e8c94' viewBox="0 0 320 512">
                                                                    <path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z" />
                                                                </svg>
                                                            </i></div>
                                                    </div>
                                                    {data.country_id && data.mobile ? <small>Once submit an OTP will be send to : +{countryList.filter(item => item.id == data.country_id)[0].calling_code}  {data.mobile}</small> : <small>Please enter mobile number</small>}
                                                    {otpSend &&
                                                        <div className="sign__input-wrapper mb-10">
                                                            <h5>OTP</h5>
                                                            <div className="sign__input">
                                                                <input type="number" placeholder="Enter 6 digits OTP" onWheel={e => e.target.blur()} value={otp} onChange={(e) => setotp(e.target.value.slice(0, 6))} />
                                                                <i><svg aria-hidden="true" width={11} focusable="false" data-prefix="fas" data-icon="lock" className="svg-inline--fa fa-lock fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                    <path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z">
                                                                    </path>
                                                                </svg></i></div>
                                                        </div>
                                                    }
                                                    <div className="sign__action d-sm-flex justify-content-between mb-30" />
                                                    {!otpSend ?
                                                        <button disabled={data.mobile.length <= 9 ? true : false} className="e-btn  w-100" type={'button'} style={{ textTransform: 'uppercase' }} onClick={SendOtp}> <span /> Send OTP</button>
                                                        :
                                                        <>
                                                            <button className={"btn btn-primary  w-100 "} disabled={otp.length > 0 ? false : true} type={'button'} onClick={VerifyOtp} > <span /> Submit</button>
                                                            <button className='btn btn-primary-outline w-100' style={{ marginRight: '.5rem' }} onClick={ResendOTP} disabled={counterStart} type='button'>{counterStart ? `Resend OTP in ${counter}` : 'Resend OTP'}</button>

                                                        </>
                                                    }
                                                </>
                                                :
                                                UserType == 'student' ?
                                                    <>
                                                        <div className="sign__input-wrapper mb-25">
                                                            <h5>Full Name <span className='required'>*</span></h5>
                                                            <div className="sign__input"><input type="text" placeholder="Full name" value={data.name} name={'name'} onChange={setdata} />
                                                                <i><svg aria-hidden="true" focusable="false" width={11} data-prefix="fas" data-icon="user" className="svg-inline--fa fa-user fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                    <path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z">
                                                                    </path>
                                                                </svg></i></div>
                                                        </div>
                                                        <div className="sign__input-wrapper mb-25">
                                                            <h5> Email <span className='required'>*</span></h5>
                                                            <div className="sign__input"><input type="text" placeholder="e-mail address" value={data.email} name={'email'} onChange={setdata} /><i>
                                                                <svg aria-hidden="true" focusable="false" width={11} data-prefix="fas" data-icon="envelope" className="svg-inline--fa fa-envelope fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                    <path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                                                                    </path>
                                                                </svg></i></div>
                                                        </div>
                                                        <div className="sign__input-wrapper mb-25">
                                                            <h5>Mobile <span className='required'>*</span></h5>
                                                            <div className="sign__input"><input type="number" disabled={true} onKeyDown={PreventNonNumeric} onWheel={e => e.target.blur()} placeholder="Mobile Number" value={data.mobile.slice(0, 10)} name={'mobile'} onChange={setdata} /><i>
                                                                <svg xmlns="http://www.w3.org/2000/svg" height={11} fill='#8e8c94' viewBox="0 0 320 512"><path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z" /></svg>
                                                            </i></div>
                                                        </div>
                                                        <div className="sign__input-wrapper mb-25">
                                                            <h5>Referral Code <span className='required'></span></h5>
                                                            <div className="sign__input">
                                                                {referral != "" ? <input type="text" placeholder="Referral code" value={referral} name={'referral_code'} onChange={setdata} disabled /> :
                                                                    <input type="text" placeholder="Referral code" value={data.referral_code} name={'referral_code'} onChange={setdata} />}
                                                                <i>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height={11} fill='#8e8c94' viewBox="0 0 320 512"><path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z" /></svg>
                                                                </i>
                                                            </div>
                                                        </div>
                                                        <div className="sign__input-wrapper mb-25">
                                                            <h5>Password <span className='required'>*</span></h5>
                                                            <div className="sign__input"><input type={ShowPassord ? 'text' : "password"} placeholder="Password" value={data.password} name={'password'} onChange={setdata} /><i>
                                                                <svg aria-hidden="true" focusable="false" width={11} data-prefix="fas" data-icon="lock" className="svg-inline--fa fa-lock fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                    <path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z">
                                                                    </path>
                                                                </svg></i>

                                                                <span className='password-eye-container' >
                                                                    {ShowPassord ?
                                                                        <button type='button' className='btn' onClick={() => setShowPassord(!ShowPassord)} >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={18} fill={'current-color'}>
                                                                                <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
                                                                            </svg>
                                                                        </button>

                                                                        :
                                                                        <button type='button' className='btn' onClick={() => setShowPassord(!ShowPassord)} >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={18} fill={'current-color'}>
                                                                                <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                                                                            </svg>
                                                                        </button>
                                                                    }

                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="sign__input-wrapper mb-20">
                                                            <h5>Confirm Password <span className='required'>*</span></h5>
                                                            <div className="sign__input"><input type={ShowPassord ? 'text' : "password"} placeholder="Confirm Password" value={data.confirm_password} name={'confirm_password'} onChange={setdata} /><i>
                                                                <svg aria-hidden="true" width={11} focusable="false" data-prefix="fas" data-icon="lock" className="svg-inline--fa fa-lock fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                    <path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z">
                                                                    </path>
                                                                </svg></i>
                                                                <span className='password-eye-container' >
                                                                    {ShowPassord ?
                                                                        <button type='button' className='btn' onClick={() => setShowPassord(!ShowPassord)} >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={18} fill={'current-color'}>
                                                                                <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
                                                                            </svg>
                                                                        </button>

                                                                        :
                                                                        <button type='button' className='btn' onClick={() => setShowPassord(!ShowPassord)} >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={18} fill={'current-color'}>
                                                                                <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                                                                            </svg>
                                                                        </button>
                                                                    }

                                                                </span>
                                                            </div>
                                                        </div>

                                                        {/* <div className="sign__action d-flex justify-content-between mb-30">
                                        <div className="sign__agree d-flex align-items-center"><input type="checkbox" className="m-check-input" id="m-agree" /><label className="m-check-label" htmlFor="m-agree">I agree to the <a href="#">Terms &amp;
                                            Conditions</a></label>
                                        </div>
                                    </div> */}
                                                        <button className="btn btn-primary w-100" button='button' onClick={handleSubmit}> <span /> Sign Up</button>

                                                    </>

                                                    :
                                                    !AgreementView ?
                                                        <>
                                                            <div className="sign__input-wrapper mb-25">
                                                                <h5>Full Name <span className='required'>*</span></h5>
                                                                <div className="sign__input"><input type="text" placeholder="Full name" value={data.name} name={'name'} onChange={setdata} />
                                                                    <i><svg aria-hidden="true" focusable="false" width={11} data-prefix="fas" data-icon="user" className="svg-inline--fa fa-user fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                        <path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z">
                                                                        </path>
                                                                    </svg></i></div>
                                                            </div>
                                                            <div className="sign__input-wrapper mb-25">
                                                                <h5> Email <span className='required'>*</span></h5>
                                                                <div className="sign__input"><input type="text" placeholder="e-mail address" value={data.email} name={'email'} onChange={setdata} /><i>
                                                                    <svg aria-hidden="true" focusable="false" width={11} data-prefix="fas" data-icon="envelope" className="svg-inline--fa fa-envelope fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                        <path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                                                                        </path>
                                                                    </svg></i></div>
                                                            </div>
                                                            <div className="sign__input-wrapper mb-25">
                                                                <h5>Mobile <span className='required'>*</span></h5>
                                                                <div className="sign__input"><input type="number" disabled={true} onKeyDown={PreventNonNumeric} onWheel={DisableScrollTONumberChange} placeholder="Mobile Number" value={data.mobile} name={'mobile'} onChange={setdata} /><i>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height={11} fill='#8e8c94' viewBox="0 0 320 512"><path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z" /></svg>
                                                                </i></div>
                                                            </div>
                                                            <div className="sign__input-wrapper mb-25">
                                                                <h5>Company Name <span className='required'>*</span></h5>
                                                                <div className="sign__input"><input type="text" placeholder="Company Name" value={data.company_name} name={'company_name'} onChange={setdata} /><i>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height={11} fill='#8e8c94' viewBox="0 0 320 512"><path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z" /></svg>
                                                                </i></div>
                                                            </div>
                                                            <div className="sign__input-wrapper mb-25">
                                                                <h5>Company Address <span className='required'>*</span></h5>
                                                                <div className="sign__input"><input type={'text'} placeholder="Company Address" value={data.company_address} name={'company_address'} onChange={setdata} /><i>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height={11} fill='#8e8c94' viewBox="0 0 320 512"><path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z" /></svg>
                                                                </i></div>
                                                            </div>
                                                            <div className="sign__input-wrapper mb-25">
                                                                <h5>Password <span className='required'>*</span></h5>
                                                                <div className="sign__input"><input type={ShowPassord ? 'text' : "password"} placeholder="Password" value={data.password} name={'password'} onChange={setdata} /><i>
                                                                    <svg aria-hidden="true" focusable="false" width={11} data-prefix="fas" data-icon="lock" className="svg-inline--fa fa-lock fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                        <path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z">
                                                                        </path>
                                                                    </svg></i>

                                                                    <span className='password-eye-container' >
                                                                        {ShowPassord ?
                                                                            <button type='button' className='btn' onClick={() => setShowPassord(!ShowPassord)} >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={18} fill={'current-color'}>
                                                                                    <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
                                                                                </svg>
                                                                            </button>

                                                                            :
                                                                            <button type='button' className='btn' onClick={() => setShowPassord(!ShowPassord)} >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={18} fill={'current-color'}>
                                                                                    <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                                                                                </svg>
                                                                            </button>
                                                                        }

                                                                    </span>

                                                                </div>
                                                            </div>
                                                            <div className="sign__input-wrapper mb-20">
                                                                <h5>Confirm Password <span className='required'>*</span></h5>
                                                                <div className="sign__input"><input type={ShowPassord ? 'text' : "password"} placeholder="Re-Password" value={data.confirm_password} name={'confirm_password'} onChange={setdata} /><i>
                                                                    <svg aria-hidden="true" width={11} focusable="false" data-prefix="fas" data-icon="lock" className="svg-inline--fa fa-lock fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                        <path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z">
                                                                        </path>
                                                                    </svg></i>

                                                                    <span className='password-eye-container' >
                                                                        {ShowPassord ?
                                                                            <button type='button' className='btn' onClick={() => setShowPassord(!ShowPassord)} >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={18} fill={'current-color'}>
                                                                                    <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
                                                                                </svg>
                                                                            </button>

                                                                            :
                                                                            <button type='button' className='btn' onClick={() => setShowPassord(!ShowPassord)} >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={18} fill={'current-color'}>
                                                                                    <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                                                                                </svg>
                                                                            </button>
                                                                        }

                                                                    </span>

                                                                </div>
                                                            </div>

                                                            {/* <div className="sign__action d-flex justify-content-between mb-30">
                                        <div className="sign__agree d-flex align-items-center"><input type="checkbox" className="m-check-input" id="m-agree" /><label className="m-check-label" htmlFor="m-agree">I agree to the <a href="#">Terms &amp;
                                            Conditions</a></label>
                                        </div>
                                    </div> */}

                                                            <button className="btn btn-primary w-100" button={'button'} onClick={handleSubmit}> Sign Up</button>

                                                        </>

                                                        :
                                                        <>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <h4>Agent Agreement</h4>

                                                                <button type='button' className='btn' onClick={() => {
                                                                    if (pdfExportComponent.current) {
                                                                        pdfExportComponent.current.save();
                                                                    }
                                                                }}
                                                                >
                                                                    {DownloadSvg}
                                                                    <span className='mx-2'>Download</span></button>

                                                            </div>
                                                            <hr />
                                                            <PDFExport fileName='Vidya Portal Agent Agreement' paperSize="A4" margin="2cm" ref={pdfExportComponent}>
                                                                <Agreement name={data.name} email={data.email} mobile={data.mobile} companyName={data.company_name} companyAddress={data.company_address} />
                                                            </PDFExport>
                                                            <hr />
                                                            <div className="course__sidebar-check mb-10 d-flex align-items-center">
                                                                <input type="checkbox" className="m-check-input" id='agree' onChange={(e) => setisAgreed(e.target.checked)} value={isAgreed} />
                                                                <label className="m-check-label" htmlFor='agree' >Agree</label>
                                                            </div>

                                                            <button className="btn btn-primary w-100" button={'button'} disabled={!isAgreed} onClick={handleAgentSignup}> Submit</button>
                                                        </>
                                            }

                                        </>
                                    }





                                    <div className="sign__new text-center mt-20">
                                        <p>Already have An account ? <Link to="/sign-in">Sign In</Link></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {isModalOpen &&
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <ConfirmationModal onSubmit={() => console.log("no")} onClose={() => setisModalOpen(false)} ModalOpen={isModalOpen} />
                    </div>
                }
            </div >
        </section >

    )
}

export default SignUp

const DownloadSvg = <svg xmlns="http://www.w3.org/2000/svg" width="23.606" height="19.513" viewBox="0 0 23.606 19.513">
    <g id="Icon_feather-download-cloud" data-name="Icon feather-download-cloud" transform="translate(-0.334 -3.461)">
        <path id="Path_224" data-name="Path 224" d="M12,25.5l3.885,3.885L19.769,25.5" transform="translate(-3.707 -7.41)" fill="none" stroke="#0095c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        <path id="Path_225" data-name="Path 225" d="M18,18v8.74" transform="translate(-5.822 -4.766)" fill="none" stroke="#0095c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        <path id="Path_226" data-name="Path 226" d="M20.8,19.148A4.856,4.856,0,0,0,18,10.32H16.781A7.769,7.769,0,1,0,3.437,17.4" transform="translate(0 0)" fill="none" stroke="#0095c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
    </g>
</svg>