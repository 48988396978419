import React, { useContext, useEffect, useState } from 'react'
import { BreadCrumb } from '../../Components/BreadCrumb/BreadCrumb'
import { Context } from '../../common/Context/Context'
import { url } from "../../Functionalities/config";
import { Link, useNavigate } from 'react-router-dom'


const Counselling = () => {
    const { user } = useContext(Context)
    const token = localStorage.getItem('vidya_token')
    const navigate = useNavigate();

    const [loading, setloading] = useState(true)

    const [counselling, setCounselling] = useState([
        {
            question: "Name?",
            answer: "",
        },
        {
            question: "Mobile?",
            answer: "",
        },
        {
            question: "Email?",
            answer: "",
        }
    ])
    const [answer, setAnswer] = useState('')
    const [count, setCount] = useState(0);
    const [data, setdata] = useState([])

    const getQuestionAnswers = () => {
        console.warn("hloo", counselling.length, count);
        if((counselling.length - 1) != count) {
            // setdata(data => ({...data, question: counselling[count].question, answer: answer }))
            data.push({question: counselling[count].question, answer: answer})
            setCount(count+1)
            setAnswer('')
            console.warn("ans", data);
        } else {
            console.warn("yes");
            data.push({question: counselling[count].question, answer: answer})
            sendQA()
            // setdata(pre => ({...pre, question: counselling[count].question, answer: answer}))
        }
    }


    useEffect(() => {
        getQuestions()
    },[])

    console.log("tokentoken-------", token);

    const getQuestions = () => {
        var axios = require('axios');
        var config = {
        method: 'get',
        url: url+'/counselling/questions/',
        };
        axios(config)
        .then(function (response) {
            console.log("res q---",response.data);
            setloading(false)
            if(token){
                setCounselling(response.data.results)
            } else {
                var finalObj = counselling.concat(response.data.results);
                setCounselling(finalObj)
            }
        })
        .catch(function (error) {
            console.log(error);
            setloading(false)
        });
    }


    const sendQA = () => {
        // console.warn("ok", userDetails, userToken);
        var axios = require('axios');
        var FormData = require('form-data');
        var formdata = new FormData();
        if(token){
            formdata.append('answers', `${JSON.stringify(data)}`);
        } else {
            formdata.append('name', data[0].answer);
            formdata.append('mobile', data[1].answer);
            formdata.append('email', data[2].answer);
            formdata.append('answers', `${JSON.stringify(data.slice(3))}`);
        }
        var config = {
        method: 'post',
        url: url+'/counselling/answers/',
        data : formdata,
        headers: {
            'Authorization': token? `Token ${token}` : ''
        }
        };
        console.warn("confg", config.data, data, token);
        axios(config)
        .then(function (response) {
            console.log("QA res",response.data);
            navigate('/')
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleChange = (event) => {
        setAnswer(event.target.value.trim());
    };

    return (
        <section className="course__area">
            <div className="container pt-50 pb-80">
                <BreadCrumb currentPage={'Counselling'} />
                <div style={{flexDirection: 'row'}}>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: 70, marginLeft: 50, flex: 1}}>
                        <div style={{flex: .5}}>
                            <h2 className='course__title'>{count + 1}. {counselling[count].question && counselling[count].question}</h2>
                        </div>
                        <div  style={{flex: .5}}>
                            {/* <input type="text" name="name" style={{width: '80%'}} /> */}
                            <textarea className='col___3' type={counselling[count].question == 'Mobile?'? "number" : "text"} maxLength={counselling[count].question == 'Mobile?'? 10 : null} placeholder={'Type Here...'} onChange={handleChange} value={answer} style={{width: '100%'}} rows="5" cols="33"/>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', paddingBottom: 50, paddingTop: 20 }}>
                        <div class={answer == ''? "e-btn2" : "e-btn"} onClick={() => {answer == ''? console.log("not") : getQuestionAnswers()}}>{(counselling.length - 1) != count? 'Next' : 'Finish'}</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Counselling;