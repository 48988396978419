import React, { useContext, useEffect, useState, useRef } from 'react'
import NumberCounter from 'number-counter';
import YellowBg from '../../Assets/img/shape/yellow-bg-2.png'
import Img1 from '../../Assets/img/what/what-1.jpg'
import Img2 from '../../Assets/img/what/what-2.jpg'
import YellowBg2 from '../../Assets/img/shape/yellow-bg.png'
import UserImg from '../../Assets/img/testimonial/testi-1.jpg'
import UserImg1 from '../../Assets/studentsImg/img-1.jpeg'
import UserImg2 from '../../Assets/studentsImg/img-2.jpeg'
import UserImg3 from '../../Assets/studentsImg/img-3.jpeg'
import TestimonialBg from '../../Assets/img/testimonial/testimonial-bg.jpg'
import Img6 from '../../Assets/img/cta/cta-shape.png'
import UniversityDetail from './UniversityDetail'
import { Link } from 'react-router-dom';
import { Context } from '../../common/Context/Context';
import { ManageEvents, ManageTestimonials, ManageUniversity } from '../University/UniversityLogic';
// import "slick-carousel/slick/slick.css";
import Slider from 'react-slick/lib/slider';
import NoData from '../../Components/NoData/NoData';
import Skeleton from 'react-loading-skeleton';
import EventDetail from './EventDetail';
import { formatDate } from '../../Functionalities/functions';



const settings = {
    // className: "center",

    centerMode: true,
    infinite: true,
    centerPadding: "30",
    slidesToShow: 1,
    speed: 500,
    arrows: true,
    centerPadding: '50px',
    dots: true,
};

const Course = () => {
    const { user } = useContext(Context)
    const ref = useRef('');
    const [data, setdata] = useState([])
    const [event, setevent] = useState([])
    const [loading, setloading] = useState(true)
    const [Tesimonials, setTesimonials] = useState([])




    useEffect(() => {
        GetUniversity()

        GetTestimonials()

        GetEvents()
    }, [])


    const GetUniversity = () => {
        let params = `pagination=0&id__in=[14, 3, 4,10, 13, 57]`
        // return console.log({params})
        let result = ManageUniversity('get', '', params)
        result.then((res) => {
            console.log(res)
            if (res.data) {
                setdata(res.data)
                setloading(false)
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }
    const GetEvents = () => {
        let today = new Date()

        let params = 'event_date_from='+formatDate(today,"YYYY-MM-DD/")+'&is_active=1'
        // return console.log({params})
        let result = ManageEvents('get', '', params)
        result.then((res) => {
            console.log(res)
            if (res.data) {
                setevent(res.data.results)
                setloading(false)
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }

    const GetTestimonials = () => {
        let params = 'pagination=0'
        let result = ManageTestimonials('get', '', params)
        result.then((res) => {
            console.log(res)
            if (res.data) {
                setTesimonials(res.data)
                setloading(false)
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }



    // console.log(data)
    return (
        <section className="course__area pt-100 pb-100 grey-bg">

            {event.length&&
            <section className="course__area pt-md-100 pt-10 grey-bg">
                <div variant="enclosed" id="react-tabs-276" className="react-tabs" data-rttabs="true">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-xxl-5 col-xl-6 col-lg-6">
                                <div className="section__title-wrapper mb-60">
                                    <h2 className="section__title">Upcomming Events </h2>
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                                </div>
                            </div>
                            <div className="react-tabs__tab-panel react-tabs__tab-panel--selected" role="tabpanel" id="react-tabs-1" aria-labelledby="react-tabs-0">
                                <div className="row">

                                    {(event.length > 1 && !loading) && event.map(item => (

                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4">

                                            <EventDetail id={item.id} item={item} image={item.image} description={item.description} event_date={item.event_date} name={item.title} country={item.country} />
                                        </div>
                                    ))}

                                    {loading && [1, 2, 3, 4, 5, 6].map((item, idx) => (

                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4" key={idx}>
                                            <Skeleton className='h-300' />
                                        </div>
                                    ))
                                    }

                                </div>
                            </div>

                        </div>
                    </div></div>
            </section>
            }
            <section className="course__area pt-md-100 pt-10 grey-bg">
                <div variant="enclosed" id="react-tabs-276" className="react-tabs" data-rttabs="true">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-xxl-5 col-xl-6 col-lg-6">
                                <div className="section__title-wrapper mb-60">
                                    <h2 className="section__title">Find the Right<br /> <span className="yellow-bg yellow-bg-big">University<img src={YellowBg2} alt="img not found" /></span> for you
                                    </h2>
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                                </div>
                            </div>
                            <div className="react-tabs__tab-panel react-tabs__tab-panel--selected" role="tabpanel" id="react-tabs-1" aria-labelledby="react-tabs-0">
                                <div className="row">

                                    {(data.length > 1 && !loading) && data.filter((itm, idx) => (itm.id == 14 || itm.id == 3 || itm.id == 4 || itm.id == 10 || itm.id == 13 || itm.id == 57)).map(item => (

                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4">

                                            <UniversityDetail id={item.id} image={item.image[0].docfile} name={item.name} country={item.country} />
                                        </div>
                                    ))}

                                    {loading && [1, 2, 3, 4, 5, 6].map((item, idx) => (

                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4" key={idx}>
                                            <Skeleton className='h-300' />
                                        </div>
                                    ))
                                    }

                                </div>
                            </div>

                        </div>
                    </div></div>
            </section>

            {!(user == 'agent' || user == 'student' || user == 'staff') &&

                <section className="what__area pt-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                                <div className="section__title-wrapper mb-60 text-center">
                                    <h2 className="section__title">What <span className="yellow-bg-big">defines  <img src={YellowBg} alt="img not found" /></span>you ?</h2>
                                    {/* <p>dolor sit amet, consectetur adipiscing elit </p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-6 col-lg-6">
                                <div className="what__item transition-3 mb-30 p-relative fix">
                                    <div className="what__thumb w-img"><img src={Img1} alt="img not found" /></div>
                                    <div className="what__content p-absolute text-center">
                                        <h3 className="what__title white-color">Become <br /> an Agent</h3><Link className="e-btn e-btn-border-2" to={"/sign-up"} state={{ typeOfUser: 'agent' }} >Join Now</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6   col-lg-6">
                                <div className="what__item transition-3 mb-30 p-relative fix">
                                    <div className="what__thumb w-img"><img src={Img2} alt="img not found" /></div>
                                    <div className="what__content p-absolute text-center">
                                        <h3 className="what__title white-color">I am <br />A  Student</h3><Link className="e-btn e-btn-border-2" to='/sign-up' state={{ typeOfUser: 'student' }} >Join Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            <section className="counter__area pt-md-115 pt-100 ">
                <div className="container">
                    <div className="row">
                        <div className="col-12 ">
                            <div className="section__title-wrapper text-center mb-60">
                                <h2 className="section__title">We are <span className="yellow-bg yellow-bg-big">Proud <img src={YellowBg2} alt="img not found" /></span>of</h2>
                                {/* <p>You don't have to struggle alone, you've got our assistance and help.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-3  col-xl-3 col-lg-3 col-md-3 col-6 col-sm-6">
                            <div className="counter__item mb-30 d-flex align-items-center" style={{ flexDirection: 'column' }}>
                                <div className="counter__icon user mb-15">
                                    <div><svg viewBox="0 0 490.7 490.7">
                                        <path className="st0" d="m245.3 98c-39.7 0-72 32.3-72 72s32.3 72 72 72 72-32.3 72-72-32.3-72-72-72zm0 123.3c-28.3 0-51.4-23-51.4-51.4s23-51.4 51.4-51.4 51.4 23 51.4 51.4-23 51.4-51.4 51.4z" />
                                        <path className="st0" d="m389.3 180.3c-28.3 0-51.4 23-51.4 51.4s23 51.4 51.4 51.4c28.3 0 51.4-23 51.4-51.4s-23.1-51.4-51.4-51.4zm0 82.2c-17 0-30.8-13.9-30.8-30.8s13.9-30.8 30.8-30.8 30.8 13.9 30.8 30.8-13.9 30.8-30.8 30.8z" />
                                        <path className="st0" d="m102.9 180.3c-28.3 0-51.4 23-51.4 51.4s23 51.4 51.4 51.4 51.4-23 51.4-51.4-23-51.4-51.4-51.4zm0 82.2c-17 0-30.8-13.9-30.8-30.8s13.9-30.8 30.8-30.8 30.8 13.9 30.8 30.8-13.7 30.8-30.8 30.8z" />
                                        <path className="st0" d="m245.3 262.5c-73.7 0-133.6 59.9-133.6 133.6 0 5.7 4.6 10.3 10.3 10.3s10.3-4.6 10.3-10.3c0-62.3 50.7-113 113-113s113.1 50.7 113.1 113c0 5.7 4.6 10.3 10.3 10.3s10.3-4.6 10.3-10.3c0-73.7-60-133.6-133.7-133.6z" />
                                        <path className="st0" d="m389.3 303.6c-17 0-33.5 4.6-47.9 13.4-4.8 3-6.4 9.2-3.5 14.2 3 4.8 9.2 6.4 14.2 3.5 11.2-6.8 24.1-10.4 37.3-10.4 39.7 0 72 32.3 72 72 0 5.7 4.6 10.3 10.3 10.3s10.3-4.6 10.3-10.3c-0.2-51.3-41.8-92.7-92.7-92.7z" />
                                        <path className="st0" d="m149.4 316.9c-14.5-8.7-30.9-13.3-47.9-13.3-51 0-92.5 41.5-92.5 92.5 0 5.7 4.6 10.3 10.3 10.3s10.3-4.6 10.3-10.3c0-39.7 32.3-72 72-72 13.2 0 26 3.6 37.2 10.4 4.8 3 11.2 1.4 14.2-3.5 2.9-4.9 1.2-11.1-3.6-14.1z" />
                                    </svg></div>
                                </div>
                                <div className="counter__content">
                                    <h4><span className="counter"><span /><span className="plus-icon d-flex justify-content-center d-flex justify-content-center"><NumberCounter end={3454} delay={.5} start={3000} postFix={'+'} /></span></span></h4>
                                    <p>Students Enrolled</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3  col-xl-3 col-lg-3 col-md-3 col-6 col-sm-6">
                            <div className="counter__item mb-30 d-flex align-items-center" style={{ flexDirection: 'column' }}>
                                <div className="counter__icon user mb-15">
                                    <div><svg viewBox="0 0 512 512">
                                        <path className="st0" d="M458.7,512h-384c-29.4,0-53.3-23.9-53.3-53.3V53.3C21.3,23.9,45.3,0,74.7,0H416c5.9,0,10.7,4.8,10.7,10.7v74.7  h32c5.9,0,10.7,4.8,10.7,10.7v405.3C469.3,507.2,464.6,512,458.7,512z M42.7,96v362.7c0,17.6,14.4,32,32,32H448v-384H74.7  C62.7,106.7,51.6,102.7,42.7,96L42.7,96z M74.7,21.3c-17.6,0-32,14.4-32,32s14.4,32,32,32h330.7v-64H74.7z" />
                                        <path className="st0" d="M309.3,298.7c-2.8,0-5.5-1.1-7.6-3.1l-56.4-56.5l-56.4,56.4c-3.1,3.1-7.6,4-11.6,2.3c-4-1.6-6.6-5.5-6.6-9.8V96  c0-5.9,4.8-10.7,10.7-10.7S192,90.1,192,96v166.3l45.8-45.8c4.2-4.2,10.9-4.2,15.1,0l45.8,45.8V96c0-5.9,4.8-10.7,10.7-10.7  S320,90.1,320,96v192c0,4.3-2.6,8.2-6.6,9.9C312.1,298.4,310.7,298.7,309.3,298.7L309.3,298.7z" />
                                    </svg></div>
                                </div>
                                <div className="counter__content">
                                    <h4><span className="counter"><span /><span className="plus-icon d-flex justify-content-center"><NumberCounter end={500} delay={.5} start={100} postFix={'+'} /></span></span></h4>
                                    <p>Total University</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3  col-xl-3  col-lg-3 col-md-3 col-6  col-sm-6">
                            <div className="counter__item mb-30 d-flex align-items-center" style={{ flexDirection: 'column' }}>
                                <div className="counter__icon user mb-15">
                                    <div><svg viewBox="0 0 512 512">
                                        <g id="Page-1">
                                            <g id="_x30_01---Degree">
                                                <path id="Shape" className="st0" d="M500.6,86.3L261.8,1c-3.8-1.3-7.9-1.3-11.7,0L11.3,86.3C4.5,88.7,0,95.2,0,102.4s4.5,13.6,11.3,16.1L128,160.1v53.2c0,33.2,114.9,34.1,128,34.1s128-1,128-34.1v-53.2l25.6-9.1v19.6c0,9.4,7.6,17.1,17.1,17.1h17.1c9.4,0,17.1-7.6,17.1-17.1V145c0-4-1-7.8-2.8-11.4l42.7-15.3c6.8-2.4,11.3-8.9,11.3-16.1S507.5,88.8,500.6,86.3L500.6,86.3zM366.9,194.6c-32.5-14.8-101-15.4-110.9-15.4s-78.4,0.6-110.9,15.4v-74.3c5.1-6.6,45.4-17.8,110.9-17.8s105.8,11.2,110.9,17.8V194.6z M256,230.4c-63.1,0-102.8-10.4-110.2-17.1c7.4-6.6,47.1-17.1,110.2-17.1s102.8,10.4,110.2,17.1C358.8,220,319.1,230.4,256,230.4z M413.6,131.5L384,142v-22.5c0-33.2-114.9-34.1-128-34.1s-128,1-128,34.1V142L17.1,102.4l239.1-85.3L426.7,78v43C421.3,123,416.7,126.6,413.6,131.5z M443.7,170.7h-17.1v-25.6c0-4.7,3.8-8.5,8.5-8.5s8.5,3.8,8.5,8.5v25.6H443.7z M443.7,120.7V84.1l51.2,18.3L443.7,120.7z" />
                                                <path id="Shape_1_" className="st0" d="M486.4,264.5c-0.5,0-1,0-1.5,0.1C409.2,276.4,332.6,282,256,281.5c-76.6,0.5-153.2-5.2-228.9-16.9c-0.5-0.1-1-0.1-1.5-0.1c-6,0-25.6,6.8-25.6,93.9s19.6,93.9,25.6,93.9c0.5,0,1-0.1,1.5-0.2c58.2-9.2,116.9-14.6,175.8-16l-16.7,40c-2.6,6.4-1,13.7,3.9,18.5s12.3,6.1,18.6,3.4l6.5-2.8l2.8,6.5c2.7,6.3,8.9,10.4,15.7,10.4h0.2c6.9-0.1,13.1-4.3,15.6-10.6l14.8-35.5l14.8,35.3c2.6,6.5,8.8,10.7,15.7,10.8h0.3c6.8,0,12.9-4,15.6-10.2l2.9-6.5l6.4,2.8c6.3,2.8,13.8,1.5,18.7-3.4c5-4.8,6.5-12.2,3.9-18.6L326.3,437c53.1,1.9,106,7,158.5,15.4c0.5,0.1,1,0.1,1.5,0.1c6,0,25.6-6.8,25.6-93.9S492.4,264.5,486.4,264.5L486.4,264.5z M283.3,298.4c3.5,13,5.6,26.4,6.2,39.9c-19.3-9-42-6.9-59.4,5.5c-0.4-15.3-2.4-30.6-5.9-45.5c10.3,0.2,20.9,0.3,31.8,0.3C265.3,298.7,274.4,298.6,283.3,298.4z M264.5,435.2c-23.6,0-42.7-19.1-42.7-42.7s19.1-42.7,42.7-42.7s42.7,19.1,42.7,42.7S288.1,435.2,264.5,435.2z M25.6,285.9c6.5,23.6,9.4,48.1,8.5,72.5c0.9,24.5-2,48.9-8.5,72.5c-6.5-23.6-9.4-48.1-8.5-72.5C16.2,333.9,19.1,309.5,25.6,285.9zM42.8,432.4c4.7-13.5,8.4-36.2,8.4-74s-3.7-60.5-8.4-74c54.2,7.5,108.8,12,163.5,13.5c5.1,19.7,7.5,40.1,7,60.5c0,1.2,0,2.4-0.1,3.6c-10.2,17-11.3,38-2.7,55.9l-0.4,0.9C154.2,420.2,98.3,424.7,42.8,432.4L42.8,432.4z M233.9,494.9l-6.2-14.3c-1.9-4.3-6.9-6.3-11.2-4.4l-14.4,6.3l20-48c8.2,8.3,18.7,14.1,30.1,16.5L233.9,494.9z M312.6,476.2c-4.3-1.9-9.3,0.1-11.2,4.4l-6.3,14.2L276.8,451c11.5-2.4,21.9-8.1,30.2-16.5l20,47.8L312.6,476.2z M484.7,434.8c-54.8-8.4-110.1-13.6-165.5-15.4l-0.6-1.5c10.7-22.6,6.1-49.5-11.5-67.3c-0.1-17.7-2.1-35.3-6.1-52.6c61.5-1.4,122.9-6.7,183.7-16.1c4,6.7,10.2,33.3,10.2,76.4S488.6,428,484.7,434.8L484.7,434.8z" />
                                            </g>
                                        </g>
                                    </svg></div>
                                </div>
                                <div className="counter__content">
                                    <h4><span className="counter"><span /><span className="plus-icon d-flex justify-content-center"><NumberCounter end={10000} delay={.5} start={9000} postFix={'+'} /></span></span></h4>
                                    <p>Total Courses</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3  col-lg-3 col-md-3 col-6  col-sm-6">
                            <div className="counter__item mb-30 d-flex align-items-center" style={{ flexDirection: 'column' }}>
                                <div className="counter__icon user mb-15">
                                    <div><svg viewBox="0 0 512 512">
                                        <path className="st0" d="M444.2,150.6c6.9-14.6,10.9-30.4,11.8-46.6c0.1-48.5-39.2-87.9-87.8-88c-28,0-54.4,13.3-71,35.9  C175.7,29.1,58.6,109.2,35.8,230.8s57.3,238.6,178.9,261.4c121.6,22.8,238.6-57.3,261.4-178.9c2.6-13.6,3.8-27.4,3.8-41.3  C480,228.9,467.6,186.7,444.2,150.6z M464,272c0,39.2-11.1,77.6-32.1,110.8c-7.1-34.3-20.4-42.5-36.7-48.8  c-5.3-1.6-10.3-4.4-14.4-8.1c-5.9-6.6-11-13.8-15.2-21.5c-11.4-18.8-25.5-42.1-57.7-58.2l-5.9-2.9c-40.4-20-54-26.8-34.8-84.2  c3.5-10.5,9.5-20.1,17.4-27.9c9.9,32.7,34,71.5,55,101.4c11,15.6,32.6,19.4,48.2,8.4c3.3-2.3,6.1-5.1,8.4-8.4  c14.7-20.6,28-42.3,39.7-64.7C454.4,199.5,464,235.4,464,272z M368,32c39.7,0,72,32.3,72,72c0,24.8-20.2,67.2-56.8,119.4  c-6,8.4-17.6,10.4-26,4.4c-1.7-1.2-3.2-2.7-4.4-4.4C316.2,171.2,296,128.8,296,104C296,64.3,328.3,32,368,32z M48,272  c0-45.4,14.9-89.6,42.4-125.7c12,7.9,65.3,45.5,53.6,86.2c-4.9,14.7-3.4,30.8,4.2,44.3c14.1,24.4,45,32.4,45.6,32.6  c0.3,0.1,26.5,9.1,31.4,27.2c2.7,9.9-1.5,21.5-12.6,34.5c-12.5,15.5-29.2,27.1-48,33.5c-14.5,5.6-27.3,10.6-33.5,33.7  C78.8,399,48,337.4,48,272z M256,480c-39.2,0-77.5-11.1-110.6-32c3.6-20.1,10.6-22.9,25.1-28.5c21.3-7.4,40.1-20.5,54.3-38  c14.8-17.3,20.1-33.8,15.9-49.2c-7.3-26.3-40.4-37.6-42.4-38.2c-0.2-0.1-25.5-6.6-36.3-25.2c-5.3-9.8-6.3-21.4-2.6-31.9  c14.3-50.1-42.1-92-58.8-103.1C140,89.4,196.6,64,256,64c10.9,0,21.7,0.9,32.5,2.6c-5.6,11.7-8.5,24.5-8.5,37.4  c0,3.2,0.3,6.4,0.7,9.5c-13.3,10.4-23.2,24.5-28.6,40.5c-23.6,70.6,1.4,83.1,42.9,103.6l5.8,2.9c28,14,40.3,34.3,51.1,52.2  c4.9,8.8,10.7,17.1,17.5,24.6c5.7,5.3,12.5,9.3,20,11.7c12.9,5,24.1,9.4,29.2,52.4C379.4,451,319.4,480,256,480z M368,152  c26.5,0,48-21.5,48-48s-21.5-48-48-48s-48,21.5-48,48C320,130.5,341.5,152,368,152z M368,72c17.7,0,32,14.3,32,32s-14.3,32-32,32  s-32-14.3-32-32S350.3,72,368,72z" />
                                    </svg></div>
                                </div>
                                <div className="counter__content">
                                    <h4><span className="counter"><span /><span className="plus-icon d-flex justify-content-center"><NumberCounter end={6} delay={.5} start={0} postFix={'+'} /></span></span></h4>
                                    <p>Total Countries</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="testimonial__area  pt-100 pb-100" style={{ overflowX: 'hidden' }}>

                <div className="row justify-content-center" >


                    <Slider {...settings}>


                        {Tesimonials.map(item => (

                            <div key={item} className=" testimonial" >
                                <div className="testimonial-container " style={{ position: 'relative' }} >
                                    <div className='d-flex justify-content-end' style={{ transform: 'rotate(180deg)' }}>
                                        {quotesIcon}
                                    </div>

                                    <div className='p-4' style={{
                                        position: 'absolute',
                                        top: '0',
                                        bottom: '0',
                                        left: '0',
                                        right: '0',
                                        zIndex: 100
                                    }}>
                                        <div className=" testimonial__profile">
                                            <img src={item.profile_pic ? item.profile_pic : UserImg} alt="img not found" className='testimonial-user-img' />
                                            <div className="testimonial__info">
                                                {/* <div> */}
                                                <h4 style={{ color: '#0095C7' }}>{item.author_name}</h4>
                                                <p className='m-0'>{item.course}</p>

                                                {/* </div> */}
                                                <span>{item.university}</span>
                                            </div>
                                        </div>
                                        <div className="testimonial__content mt-3">
                                            <p className='p-0'>{item.message}</p>

                                        </div>
                                    </div>


                                    <div className='d-flex justify-content-end' style={{
                                        position: 'absolute',
                                        bottom: '0',
                                        width: '100%',
                                        right: '0'
                                    }}>
                                        {quotesIcon}
                                    </div>
                                </div>
                            </div>


                        ))}



                    </Slider>


                </div>
            </section >


            <section className="cta__area pb-100">

                <div className="container">

                    <div className="cta__inner cta__inner-2 blue-bg fix">

                        <div className="cta__shape"><img src={Img6} alt="img not found" /></div>
                        <div className="row align-items-center">
                            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-6">
                                <div className="cta__content">
                                    <h3 className="cta__title">Download Now</h3>
                                </div>
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6">
                                <div className="cta__apps d-lg-flex justify-content-end p-relative z-index-1"><a target="_blank" href="https://apps.apple.com/us/app/vidya-portal/id1618585775" className="mr-10"><i><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="apple" className="svg-inline--fa fa-apple fa-w-12 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style={{ width: '0.75rem' }}>
                                    <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z">
                                    </path>
                                </svg></i> Apple Store</a>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.codedady.vidyaportal" className="active"><i><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google-play" className="svg-inline--fa fa-google-play fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: '0.75rem' }}>
                                        <path fill="currentColor" d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z">
                                        </path>
                                    </svg></i> Play Store</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </section >
    )
}

export default Course
export const quotesIcon = <svg xmlns="http://www.w3.org/2000/svg" className='quotes--icon' viewBox="0 0 278.05 185.627">
    <g id="noun-quote-14163" transform="translate(392.415 308.466) rotate(180)">
        <path id="Path_881" data-name="Path 881" d="M249.78,190.363a67.521,67.521,0,0,0-67.523-67.523c-37.294,0-64.364,29.188-67.523,66.348-4.821,56.7,38.251,104.172,90.549,119.279a67.464,67.464,0,0,1-32.332-51.322,65.908,65.908,0,0,0,9.306.74,67.519,67.519,0,0,0,67.523-67.523Z" transform="translate(0)" fill="#eafaff" />
        <path id="Path_882" data-name="Path 882" d="M491.31,190.363a67.521,67.521,0,0,0-67.523-67.523c-37.29,0-64.364,29.188-67.523,66.348-4.821,56.7,38.251,104.172,90.548,119.279a67.47,67.47,0,0,1-32.332-51.322,65.963,65.963,0,0,0,9.306.74,67.517,67.517,0,0,0,67.523-67.523Z" transform="translate(-98.895)" fill="#eafaff" />
    </g>
</svg>