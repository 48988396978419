import React from 'react'
import Course from './Course'
import HeroSection from './HeroSection'
import ServiceSection from './ServiceSection'

const Home = () => {
    return (
        <main>
            <HeroSection />
            <ServiceSection />
            <Course />
        </main >
    )
}

export default Home