

// export const protocol = 'http://'
// export const host = "localhost:8000";

export const protocol = 'https://'
export const host = "live.vidyaportal.com"; //live


export const version = 'V:2.0.1'
// export const url = protocol + host
export const url = protocol + host

// export const OneSignalAppID = '3aa016fb-b951-4c41-9edd-2e246b5187e2'
// export const OneSignalAppID = '3aa016fb-b951-4c41-9edd-2e246b5187e2'
export const RestAPI = 'YWJlOWRjMGQtMzcyZi00ZTRiLWE0MzUtMTM2ODk3YWZjYWM4'

