import React, { useContext, useEffect, useState } from 'react'
import { BreadCrumb } from '../../Components/BreadCrumb/BreadCrumb'
import ReferralBanner from '../../Assets/Banners/Refferal Banner.png'
import './profile.css'
import { UseForm } from '../../common/Hooks/UseForm'
import { PreventNonNumeric, ShowFailedMessage, ShowSuccessMessage } from '../../Functionalities/functions'
import { validateEmail, Validation } from '../../common/Validations/validation'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Context } from '../../common/Context/Context'
import { ManageUsers } from './UserLogic'
import { ManagePasswords } from './passwordsLogic'
import { ManageNotifications } from './NotificationLogic'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import Loading from '../../Components/Lottie/Loading'
import { PDFExport } from '@progress/kendo-react-pdf'
import Agreement from '../SignUp/Agreement'
import { url, version } from '../../Functionalities/config'


export const Profile = () => {
    const { user, setuser } = useContext(Context)
    const navigate = useNavigate()
    const location = useLocation()



    const UserTabs = ['My Account', 'My Referral', 'Notification', 'Change Password', 'Sign Out']
    const AgentTabs = ['My Account', 'My Staff', 'Notification', 'Commission structure', 'Agent Contract', 'Change Password', 'Sign Out']
    // const AgentTabs = ['My Account', 'Notification', 'Commission structure', 'Agent Contract', 'Change Password', 'Sign Out']
    const [ActiveTab, setActiveTab] = useState('My Account')
    const [referralCode, setreferralCode] = useState('')
    const [hideForgotPasswordSection, sethideForgotPasswordSection] = useState(true)
    const [userdata, setuserdata] = useState({ first_name: '', username: '', email: '', company_address: '', company_name: '' })
    const [referralData, setreferralData] = useState([])
    const [supervisor, setsupervisor] = useState({})
    const [commisionStructure, setcommisionStructure] = useState([])
    const [modalOpen, setmodalOpen] = useState(false)
    const [loading, setloading] = useState(true)
    const [defaultPhone, setdefaultPhone] = useState('')

    const handleSignOut = () => {
        localStorage.removeItem('vidya_token')
        localStorage.removeItem('user')
        navigate('/')
        setuser('')
    }


    useEffect(() => {
        console.log({ location })
        if (location.state) {

            setActiveTab(location.state.activeTab)
        }

    }, [location])



    useEffect(() => {
        GetUserData()
        GetReferralData()
        GetCommissionStructure()

    }, [])


    const GetSupervisorData = () => {
        let path = '/user/super/'

        let result = ManageUsers('get', '', '', path)

        result.then((res) => {
            console.log('supervisor--------', res)
            if (res.data) {
                setsupervisor(res.data[0])
                setloading(false)
            } else {
                setsupervisor({})

            }
        })
            .catch((err) => {
                console.log(err)
            })
    }
    const GetUserData = () => {
        let path = '/user/user_profile/'

        let result = ManageUsers('get', '', '', path)

        result.then((res) => {
            console.log('user data-------', res)
            if (res.data) {
                // localStorage.setItem('userdetails', res.data[0].id)

                setuserdata({
                    ...userdata,
                    first_name: res.data[0].user.first_name,
                    username: res.data[0].user.username,
                    email: res.data[0].user.email,
                    company_address: res.data[0].company_address,
                    company_name: res.data[0].company_name,

                })
                setdefaultPhone(res.data[0].user.username)
                setreferralCode(res.data[0].referral_code)
                setloading(false)

                if (res.data[0].is_approved) {
                    GetSupervisorData()
                }

            } else {
                setuserdata({})
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }

    const GetReferralData = () => {
        let path = '/user/myreferrals/'

        let result = ManageUsers('get', '', '', path)

        result.then((res) => {
            console.log(res)
            if (res.data.results) {
                setreferralData(res.data.results)

            } else {
                setreferralData([])
            }
        })
            .catch((err) => {
                console.log(err)
            })

    }
    const GetCommissionStructure = () => {
        let path = '/admins/commission/'

        let result = ManageUsers('get', '', '', path)

        result.then((res) => {
            console.log('commision structure ---------------', res)
            if (res.data.results) {
                setcommisionStructure(res.data.results)

            } else {
                setcommisionStructure([])
            }
        })
            .catch((err) => {
                console.log(err)
            })

    }



    return (
        <section className="course__area ">
            <div className="container pt-50">
                <BreadCrumb currentPage={'Profile'} />
                <div className="row  mb-120" style={{ height: '38rem' }}>

                    <div className="col-lg-3 col-md-4 col-12 main-menu py-2" style={{
                        background: 'white',
                        boxShadow: 'rgb(2 2 26 / 6%) 0 0 20px 0',


                    }} >
                        <ul>
                            {!loading ?
                                <li className='w-100 mt-2'>

                                    <ul className="submenu w-100" style={{ boxShadow: 'unset', opacity: '1', visibility: 'visible', left: 0, position: 'relative' }}>
                                        <div className='d-flex align-items-center mb-2' style={{
                                            background: '#F2F2F2',
                                            padding: '10px 25px',
                                            color: '#000'
                                        }}>
                                            <i >
                                                {supervisor.username ? Supervisor : closeIcon}
                                            </i>

                                            <div className='mx-3'>
                                                <p className='m-0 text-black' style={{ fontSize: '11px', lineHeight: '16px' }}> {supervisor.username ? `Supervisor Details` : `Un Approved`}</p>
                                                {supervisor &&
                                                    <>
                                                        <p className='m-0 text-black' style={{ fontSize: '14px', fontWeight: '600', lineHeight: '20px' }}>{supervisor.first_name}</p>
                                                        <p className='m-0 text-black' style={{ fontSize: '14px', fontWeight: '600', lineHeight: '20px' }}>{supervisor.username}</p>
                                                    </>}
                                            </div>



                                        </div>


                                        {user == 'student' && UserTabs.map(item => (
                                            <li ><a style={ActiveTab == item ? { background: 'var(--bs-primary)', color: "#fff" } : {}} onClick={() => { setActiveTab(item); setmodalOpen(true) }}>{item}</a></li>
                                        ))}
                                        {user == 'agent' && AgentTabs.map(item => (
                                            <li ><a style={ActiveTab == item ? { background: 'var(--bs-primary)', color: "#fff" } : {}} onClick={() => { setActiveTab(item); setmodalOpen(true) }}>{item}</a></li>
                                        ))}
                                        {user == 'staff' && AgentTabs.filter(itm => !itm.includes('Commission structure') && !itm.includes('My Staff') && !itm.includes('Agent Contract')).map(item => (

                                            //  AgentTabs.map(item => (
                                            <li ><a style={ActiveTab == item ? { background: 'var(--bs-primary)', color: "#fff" } : {}} onClick={() => { setActiveTab(item); setmodalOpen(true) }}>{item}</a></li>
                                        ))}
                                        <div class="ver">{version}</div>

                                    </ul>

                                </li>
                                :
                                <>
                                    <Loading />
                                    <Skeleton className='h-50' count={1} />
                                </>
                            }
                        </ul>

                    </div>

                    <div className={`col-lg-9 col-md-8 col-12 ${!modalOpen ? 'profile' : 'profile-active'}`}>
                        <div className=" p-lg-5 p-md-4 p-3 md-mt-50 mt-md-0" style={{ boxShadow: 'rgb(2 2 26 / 6%) 0 0 20px 0', height: '100%', }}>
                            <div className='d-md-none d-block' onClick={() => setmodalOpen(false)} style={{ position: 'absolute' }}>
                                <svg width={15} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" /></svg>

                            </div>
                            {(user.includes('student') || user.includes('agent') || user.includes('staff')) && ActiveTab == 'My Account' &&
                                <MyAccount user={user} data={userdata} />
                            }
                            {(user == 'student' && (ActiveTab == 'My Referral')) &&
                                <MyReferrals code={referralCode} data={referralData} />
                            }
                            {user == 'agent' && (ActiveTab == 'My Staff') &&
                                <MyStaffs />
                            }
                            {(user.includes('student') || user.includes('agent') || user.includes('staff')) && (ActiveTab == 'Notification') &&
                                <Notification />
                            }
                            {user == 'agent' && (ActiveTab == 'Commission structure') &&
                                <CommissionStructure data={commisionStructure} />
                            }
                            {user == 'agent' && (ActiveTab == 'Agent Contract') &&
                                <AgentContract data={userdata} />
                            }
                            {(user.includes('student') || user.includes('agent') || user.includes('staff')) && (ActiveTab == 'Change Password') &&
                                <>
                                    {hideForgotPasswordSection ?
                                        <ChangePassword changeView={() => sethideForgotPasswordSection(false)} />
                                        :
                                        <ForgotPassword changeView={() => sethideForgotPasswordSection(true)} phone={defaultPhone} />
                                    }
                                </>
                            }

                            {(user.includes('student') || user.includes('agent') || user.includes('staff')) && (ActiveTab == 'Sign Out') &&
                                <SignOut handleSignOut={handleSignOut} />
                            }

                            <div>{version}</div>

                        </div>

                    </div>


                </div>
            </div>
        </section >
    )
}

const MyAccount = ({ user, data }) => (
    <div className="checkbox-form">
        <h3 className='text-center text-md-start'>My Account</h3>
        <div className="row">
            <div className="col-12">
                <div className="checkout-form-list">
                    <label>Name </label>
                    <input disabled type="text" placeholder="Enter your Name" value={data.first_name} />
                </div>
            </div>
            <div className="col-12">
                <div className="checkout-form-list">
                    <label>Mobile </label>
                    <input disabled type="number" placeholder="Enter your Mobile" value={data.username} />
                </div>
            </div>
            <div className="col-md-12">
                <div className="checkout-form-list">
                    <label>Email </label>
                    <input disabled type="text" placeholder="Enter your Email" value={data.email} />
                </div>
            </div>
            {!user.includes('student') &&
                <>
                    <div className="col-md-12">
                        <div className="checkout-form-list">
                            <label>Company Name </label>
                            <input disabled type="text" placeholder="Enter your Company Name" value={data.company_name} />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="checkout-form-list">
                            <label>Company Address </label>
                            <input disabled type="text" placeholder="Enter your Company Address" value={data.company_address} />
                        </div>
                    </div>
                </>
            }
        </div>
    </div>
)


const MyReferrals = ({ code, data }) => (
    <div className="checkbox-form">
        <div className="d-flex justify-content-between">
            <h3 className='text-center text-md-start'>My Referral</h3>

        </div>
        <div className="profile-card-container">

            <div className="row">
                <div className="col-12">
                    <div className="referral-container">
                        {/* banner img */}
                        <img src={ReferralBanner} className="referral_banner w-100" />

                        <div className='w-100 px-2'>
                            <div className='p-md-2 py-2'>
                                Refer & Earn
                                <br />
                                copy your referrel code and share your friends
                            </div>
                            <div className='referral-copy' style={{
                                background: '#eafaff',
                                height: '2rem',
                                width: '10rem',
                                color: '#004a63'
                            }}>
                                <button style={{ background: 'none' }} type='button' onClick={() => { navigator.clipboard.writeText(code); ShowSuccessMessage('Referrel code copied') }}>
                                    <i class="far fa-clone"></i>
                                </button>
                                {code}
                            </div>
                        </div>
                    </div>
                    {data.map(item => (

                        <ReferralStatus name={item.name} appId={item.id} stage={item.status} is_claimed={item.is_claimed} />
                    ))}

                </div>
            </div>
        </div>
    </div>
)
const AgentContract = ({ data }) => {
    const pdfExportComponent = React.useRef(null);
    return (
        <div className="checkbox-form">
            <div className="d-flex justify-content-between">
                <h3 className='text-center text-md-start'>Agent Contract</h3>

            </div>
            <div className="row profile-card-container ">
                <div className="col-12">
                    <div className="">



                        <PDFExport fileName='Vidya Portal Agent Agreement' paperSize="A4" margin="2cm" ref={pdfExportComponent}>
                            <Agreement name={data.first_name} email={data.email} mobile={data.username} companyName={data.company_name} companyAddress={data.company_address} />
                        </PDFExport>

                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <button type='button' className='btn'
                    onClick={() => {
                        if (pdfExportComponent.current) {
                            pdfExportComponent.current.save();
                        }
                    }}
                >
                    {DownloadSvg}
                    <span className='mx-2'>Download</span></button>
            </div>

        </div>
    )
}
const CommissionStructure = ({ data }) => (
    <div className="checkbox-form">
        <div className="d-flex justify-content-between">
            <h3 className='text-center text-md-start'>Commission Structure</h3>

        </div>
        <div className="row profile-card-container">
            {data.map(item => (

                <div className="col-12 mb-3">
                    {/* banner img */}
                    <img src={item.banner.docfile} className=" w-100" />
                    <a target={'_blank'} href={item.banner.docfile} className='btn w-100' download>
                        {DownloadSvg}
                        <span className='mx-2'>Download</span></a>
                </div>
            ))}



        </div>
    </div>
)
const MyStaffs = (props) => {
    const initialValue = { id: '', name: '', email: '', mobile: '', password: '', re_password: '' }
    const [staff, setstaff] = useState(initialValue)
    const [hideStaffListing, setHideStaffListing] = useState(false)
    const [data, setdata] = useState([])


    useEffect(() => {
        GetData()
    }, [])


    const GetData = () => {
        let path = '/user/mystaffs/'
        let result = ManageUsers('get', '', '', path)

        result.then((res) => {
            console.log('staffs', res)
            if (res.data.results) {
                setdata(res.data.results)
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        let validation = Validation(staff, ['name', 'email', 'mobile', 'password', 're_password'])
        // let emailValidation = validateEmail
        if (!validation) {
            return
        }
        if (staff.password !== staff.re_password) {
            ShowFailedMessage('passwords doest not match')
            return
        }
        if (!validateEmail(staff.email)) {
            ShowFailedMessage('Invalid Email')
            return
        }

        let path = '/user/mystaffs/'
        let result = ManageUsers('add', staff, '', path)

        result.then((res) => {
            console.log(res)

            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                setstaff(initialValue)
                GetData()
            } else {
                ShowFailedMessage(res.data.Message + " : " + res.data.Error)

            }
        })

            .catch((err) => {
                console.log(err)
            })


    }
    const handleDelete = (e) => {
        e.preventDefault()


        let path = '/user/mystaffs/'
        let result = ManageUsers('delete', '', `staff_id=${staff.id}`, path)

        result.then((res) => {
            console.log(res)

            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                setHideStaffListing(false)
                GetData()
            } else {
                ShowFailedMessage(res.data.Message)

            }
        })

            .catch((err) => {
                console.log(err)
            })

    }
    const handlClick = (item) => {
        setHideStaffListing(true)
        console.log(item.user.first_name)
        setstaff({
            ...data,
            id: item.id,
            name: item.user.first_name,
            mobile: item.mobile,
            email: item.user.email
        })
    }
    return (
        <div className="checkbox-form">
            <div className="d-flex justify-content-between">
                <h3 className='text-center text-md-start'>{hideStaffListing ? staff.id ? 'Staff Detail' : 'Add Staff' : 'My Staffs'} </h3>
                <div className=''>
                    <button className='btn btn-primary' type='button' onClick={() => { setHideStaffListing(!hideStaffListing); setstaff(initialValue) }}>
                        {hideStaffListing ? 'Cancel' : 'Add'}
                    </button>

                </div>
            </div>
            <div className="row">
                <div className="col-12 profile-card-container">

                    {!hideStaffListing ?
                        <>
                            {data.filter(itm => itm.staff_by_agent == true).map(item => (

                                <StaffDetails name={item.user.first_name} mobile={item.mobile} email={item.user.email} onClick={() => handlClick(item)} />
                            ))}

                        </>
                        :
                        <div className="checkbox-form">
                            <div className="checkout-form-list">
                                <div className="col-md-12">
                                    <div className="checkout-form-list">
                                        <label>Name <span className='required'>{!staff.id && '*'}</span> </label>
                                        <input type="text" disabled={staff.id ? true : false} onChange={(e) => setstaff({ ...staff, name: e.target.value })} placeholder="Enter Name" value={staff.name} name={'name'} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="checkout-form-list">
                                        <label>Phone <span className='required'>{!staff.id && '*'}</span> </label>
                                        <input type="number" disabled={staff.id ? true : false} onWheel={e => e.target.blur()} onChange={(e) => setstaff({ ...staff, mobile: e.target.value.slice(0, 10) })} placeholder="Enter Number" value={staff.mobile} name={'mobile'} />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="checkout-form-list">
                                        <label>Email <span className='required'>{!staff.id && '*'}</span> </label>
                                        <input type="text" disabled={staff.id ? true : false} onChange={(e) => setstaff({ ...staff, email: e.target.value })} placeholder="Enter Email" value={staff.email} name={'email'} />
                                    </div>
                                </div>
                                {!staff.id &&
                                    <>
                                        <div className="col-md-12">
                                            <div className="checkout-form-list">
                                                <label>Password <span className='required'>*</span> </label>
                                                <input type="password" onChange={(e) => setstaff({ ...staff, password: e.target.value })} placeholder="Enter password" value={staff.password} name={'password'} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="checkout-form-list">
                                                <label>Confirm Password <span className='required'>*</span> </label>
                                                <input type="password" onChange={(e) => setstaff({ ...staff, re_password: e.target.value })} placeholder="confirm password" value={staff.re_password} name={'re_password'} />
                                            </div>
                                        </div>
                                    </>
                                }

                                <div className="d-flex justify-content-end">
                                    {staff.id ?

                                        <button className='btn btn-danger' onClick={handleDelete}>Delete</button>
                                        :
                                        <button className='btn btn-primary' onClick={handleSubmit}>Submit</button>
                                    }
                                </div>

                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
const Notification = () => {

    const [data, setdata] = useState([])

    useEffect(() => {
        GetNotifications()
    }, [])

    const HandleReadNotification = (id) => {
        let result = ManageNotifications('patch', { id }, '')
        result.then((res) => {
            console.log(res)
        })
            .catch((err) => {
                console.log(err)
            })
    }

    const GetNotifications = () => {
        let result = ManageNotifications('get', '', '')
        result.then((res) => {
            console.log('notifications fetched', res)
            if (res.data.results) {
                setdata(res.data.results)
            }

        })
    }

    return (

        <div className="checkbox-form">
            <div className="d-flex justify-content-between">
                <h3 className='text-center text-md-start'>{'Notification'}</h3>

            </div>
            <div className='profile-card-container'>

                {data.length ? data.map(item => (


                    <Link to={`/application-detail/${item.application_id}`} onClick={() => HandleReadNotification(item.id)} className={`d-flex ${!item.is_read && 'bg-secondary'} p-3`}>
                        <div style={{ width: '4.5rem' }}>

                            <div className=' d-flex justify-content-center align-items-center' style={{
                                height: '2.5rem',
                                width: '2.5rem',
                                borderRadius: '7px',
                                background: !item.is_read ? '#b5e3f2' : '#d8d8d8'
                            }}>
                                {!item.is_read ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width={21} height="20.779" viewBox="0 0 21 20.779">
                                        <path id="noun-notification-4278243" d="M130.509,21.11a7.211,7.211,0,0,0-4.805,6.8v4.805a.8.8,0,0,1-.8.8,2.4,2.4,0,0,0,0,4.805h4.085a4.006,4.006,0,0,0,7.849,0h4.085a2.4,2.4,0,0,0,1.7-4.1l-.01-.01a2.4,2.4,0,0,0-1.688-.694h0a.8.8,0,0,1-.8-.8V27.908a7.211,7.211,0,0,0-4.805-6.8,8.07,8.07,0,0,0-4.805,0Zm.137,17.21h4.532a2.4,2.4,0,0,1-4.532,0ZM132.912,22.3a5.606,5.606,0,0,0-5.606,5.606v4.805a2.4,2.4,0,0,1-2.4,2.4.8.8,0,0,0-.559.227l-.007.007a.8.8,0,0,0,.566,1.367h16.018a.8.8,0,0,0,.566-1.367l-.007-.007a.8.8,0,0,0-.559-.227h0a2.4,2.4,0,0,1-2.4-2.4V27.908a5.606,5.606,0,0,0-5.606-5.606Z" transform="translate(-122.5 -20.744)" fill="#0f0f0f" fillRule="evenodd" />
                                        <circle id="Ellipse_3" data-name="Ellipse 3" cx={5} cy={5} r={5} transform="translate(11)" fill="var(--bs-primary)" />
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width={21} height="20.779" viewBox="0 0 21 20.779">
                                        <path id="noun-notification-4278243" d="M130.509,21.11a7.211,7.211,0,0,0-4.805,6.8v4.805a.8.8,0,0,1-.8.8,2.4,2.4,0,0,0,0,4.805h4.085a4.006,4.006,0,0,0,7.849,0h4.085a2.4,2.4,0,0,0,1.7-4.1l-.01-.01a2.4,2.4,0,0,0-1.688-.694h0a.8.8,0,0,1-.8-.8V27.908a7.211,7.211,0,0,0-4.805-6.8,8.07,8.07,0,0,0-4.805,0Zm.137,17.21h4.532a2.4,2.4,0,0,1-4.532,0ZM132.912,22.3a5.606,5.606,0,0,0-5.606,5.606v4.805a2.4,2.4,0,0,1-2.4,2.4.8.8,0,0,0-.559.227l-.007.007a.8.8,0,0,0,.566,1.367h16.018a.8.8,0,0,0,.566-1.367l-.007-.007a.8.8,0,0,0-.559-.227h0a2.4,2.4,0,0,1-2.4-2.4V27.908a5.606,5.606,0,0,0-5.606-5.606Z" transform="translate(-122.5 -20.744)" fill="#0f0f0f" fillRule="evenodd" />
                                        <circle id="Ellipse_4" data-name="Ellipse 4" cx={5} cy={5} r={5} transform="translate(11)" fill="#747474" />
                                    </svg>
                                }


                            </div>


                        </div>
                        <div className="w-100">
                            <div style={{ fontSize: '12px', fontWeight: '400' }}>{moment(item.created_date).calendar()}</div>
                            <div style={{ fontSize: '15px', fontWeight: '700' }}>{item.heading}</div>
                            <div style={{ fontSize: '13px', fontWeight: '400' }}>{item.content}</div>
                        </div>

                    </Link>
                ))
                    :
                    <div className="d-flex justify-content-center">
                        No notifications
                    </div>
                }
            </div>
            {/* <div className="d-flex p-3">
                <div style={{ width: '4.5rem' }}>
                    <div className=' d-flex justify-content-center align-items-center' style={{
                        height: '2.5rem',
                        width: '2.5rem',
                        borderRadius: '7px',
                        background: '#d8d8d8'
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={21} height="20.779" viewBox="0 0 21 20.779">
                            <path id="noun-notification-4278243" d="M130.509,21.11a7.211,7.211,0,0,0-4.805,6.8v4.805a.8.8,0,0,1-.8.8,2.4,2.4,0,0,0,0,4.805h4.085a4.006,4.006,0,0,0,7.849,0h4.085a2.4,2.4,0,0,0,1.7-4.1l-.01-.01a2.4,2.4,0,0,0-1.688-.694h0a.8.8,0,0,1-.8-.8V27.908a7.211,7.211,0,0,0-4.805-6.8,8.07,8.07,0,0,0-4.805,0Zm.137,17.21h4.532a2.4,2.4,0,0,1-4.532,0ZM132.912,22.3a5.606,5.606,0,0,0-5.606,5.606v4.805a2.4,2.4,0,0,1-2.4,2.4.8.8,0,0,0-.559.227l-.007.007a.8.8,0,0,0,.566,1.367h16.018a.8.8,0,0,0,.566-1.367l-.007-.007a.8.8,0,0,0-.559-.227h0a2.4,2.4,0,0,1-2.4-2.4V27.908a5.606,5.606,0,0,0-5.606-5.606Z" transform="translate(-122.5 -20.744)" fill="#0f0f0f" fillRule="evenodd" />
                            <circle id="Ellipse_4" data-name="Ellipse 4" cx={5} cy={5} r={5} transform="translate(11)" fill="#747474" />
                        </svg>


                    </div>

                </div>
                <div className="w-100">
                    <div style={{ fontSize: '12px', fontWeight: '400' }}>Today 12.30pm</div>
                    <div style={{ fontSize: '15px', fontWeight: '700' }}>New course launched</div>
                    <div style={{ fontSize: '13px', fontWeight: '400' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                </div>

            </div> */}
        </div>
    )
}

const ReferralStatus = (props) => {

    const status = [
        { value: 'Application received', id: '1' },
        { value: 'Pre-screening interview pending', id: '1.1' },
        { value: 'Info docs required for processing', id: '2' },
        { value: 'Unconditional offer issued', id: '2.1' },
        { value: 'Conditional offer issued', id: '3' },
        { value: 'TT copy send', id: '4' },
        { value: 'Waiting for interview', id: '4.1' },
        { value: 'CAS received', id: '4.2' },
        { value: 'Visa copy uploaded', id: '5' },
    ]
    const stage = status.find(itm => itm.value == props.stage).id

    const ClaimReferral = () => {
        let path = '/user/myreferrals/'
        let result = ManageUsers('add', props.appId, '', path)

        result.then((res) => {
            console.log('claim ', res)

            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
            } else {
                ShowFailedMessage(res.data.Message)
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }

    // console.log(props)
    return (
        <div className='my-4'>
            <div className="row justify-content-between align-items-center py-1">
                <div className='col-8'>
                    <div className="row align-items-center  ">
                        <div className='col-2 pr-lg-0' style={{ width: '3.5rem' }}>
                            <div className='bg-secondary text-primary py-1 px-2 letter-avatar d-flex justify-content-center align-items-center'>{props.name.slice(0, 1)}</div>
                        </div>

                        <div className='col-7'>
                            <p className='mb-0'>{props.name}</p>
                            <p className='mb-0'>Application id: {props.appId}</p>
                        </div>
                    </div>

                </div>

                <div className='col-2 d-flex justify-content-end'>
                    {stage < 5 &&
                        <>
                            <span className='bg-secondary text-primary p-2' style={{
                                borderRadius: '10rem',
                                width: '3rem',
                                height: '3rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {stage < 2 ? "0%" : (stage >= 2 && stage < 3) ? '25%' : (stage >= 3 && stage < 4) ? '50%' : (stage >= 4 && stage < 5) && '75%'}
                            </span>

                        </>
                    }

                    {stage >= 5 &&

                        <div className="d-flex justify-content-end mt-10">

                            <button className={`btn ${!props.is_claimed ? 'btn-outline-primary' : 'btn-light'} w-100`} type='button' onClick={ClaimReferral}>{!props.is_claimed ? 'Claim' : 'Claimed'}</button>
                        </div>
                    }

                </div>

            </div>
            <div className="row pt-2">
                <div className="col-3">
                    <div className={(stage >= 2) ? 'w-100 bg-primary status-slider' : 'w-100 bg-secondary status-slider'} ></div>
                </div>
                <div className="col-3">
                    <div className={(stage >= 3) ? 'w-100 bg-primary status-slider' : 'w-100 bg-secondary status-slider'} ></div>
                </div>
                <div className="col-3">
                    <div className={(stage >= 4) ? 'w-100 bg-primary status-slider' : 'w-100 bg-secondary status-slider'} ></div>
                </div>
                <div className="col-3">
                    <div className={(stage >= 5) ? 'w-100 bg-primary status-slider' : 'w-100 bg-secondary status-slider'} ></div>
                </div>

            </div>


        </div>
    )
}
const StaffDetails = (props) => {
    return (
        <div className='my-4'>
            <div className="row justify-content-between align-items-center py-1">
                <div className='col-12' onClick={props.onClick}>
                    <div className="d-flex align-items-center  justify-content-between" style={{ cursor: 'pointer' }}>
                        <div className=' pr-lg-0 d-flex align-items-center' style={{ width: 'max-content' }}>
                            <div className='bg-secondary text-primary py-1 px-2 letter-avatar d-flex justify-content-center align-items-center d-flex'
                                style={{
                                    width: '2.5rem',
                                    borderRadius: '58px',
                                    height: '2.5rem'
                                }} >{props.name.slice(0, 1)}</div>

                            <h5 className='mb-0' style={{ marginLeft: '.5rem' }}>{props.name}</h5>


                        </div>
                        <div className=' d-flex align-items-end' style={{ flexDirection: 'column' }}>
                            <p className='mb-0'>{props.mobile}</p>
                            <p className='mb-0'>{props.email}</p>
                        </div>
                    </div>

                </div>



            </div>

        </div>
    )
}

const ForgotPassword = ({ changeView, phone }) => {
    const navigate = useNavigate()
    const [value, setValue] = useState({
        mobile: '',
        otp: '',
        new_password: '',
        confirm_new_password: ''
    })
    const [otpView, setotpView] = useState(false)
    const [isOtpVerified, setisOtpVerified] = useState(false)
    const [counter, setcounter] = useState(29)
    const [counterStart, setcounterStart] = useState(false)
    useEffect(() => {
        setValue({ ...value, mobile: phone })
    }, [phone])

    useEffect(() => {


        const timer = (counter > 1 && counterStart) && setInterval(() => { setcounter(counter - 1) }, 1000);
        if (counter == 1) {
            setcounterStart(false)
            setcounter(29)
        }

        return () => { clearInterval(timer) }
    }, [counter, counterStart])



    const SendOtp = (e) => {

        e.preventDefault()

        var axios = require('axios');
        var FormData = require('form-data');
        var fdata = new FormData();
        fdata.append('mobile', value.mobile);
        fdata.append('forgot', 1);

        var config = {
            method: 'post',
            url: url + '/notifications/otp/',
            data: fdata
        };
        axios(config)
            .then(function (response) {
                console.log(response.data)
                if (response.data.Status) {
                    setotpView(true)
                } else {
                    setotpView(false)
                    ShowFailedMessage(response.data.Error)
                }

            })
            .catch(function (error) {
                setotpView(false)
                console.log(error);
            });

    }
    const VerifyOtp = (e) => {
        e.preventDefault()

        var axios = require('axios');
        var FormData = require('form-data');
        var formdata = new FormData();
        formdata.append('mobile', value.mobile);
        formdata.append('otp', value.otp);
        var config = {
            method: 'post',
            url: url + '/notifications/verify/',
            data: formdata
        };
        axios(config)
            .then(function (response) {
                console.log(response);
                if (response.data.Status) {
                    setisOtpVerified(true)
                } else {
                    setisOtpVerified(false)
                    ShowFailedMessage(response.data.Error)
                }
            })
            .catch(function (error) {
                console.log(error);
                setisOtpVerified(false)

            });
    }

    const ResendOTP = (e) => {
        e.preventDefault()
        setcounterStart(true)

    }

    const handleForgetPassword = () => {

        if (value.new_password !== value.confirm_new_password) {
            ShowFailedMessage('passwords does not match')
            return
        }

        var axios = require('axios');
        var FormData = require('form-data');
        var formdata = new FormData();
        formdata.append('mobile', value.mobile);
        formdata.append('new_password', value.new_password);
        formdata.append('re_password', value.confirm_new_password);

        var config = {
            method: 'patch',
            url: url + '/user/forgot_password/',
            data: formdata
        };

        axios(config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.Status) {
                    ShowSuccessMessage(response.data.Message)
                    navigate('/')
                    // setloading(false)
                } else {
                    ShowFailedMessage(response.data.Message)
                    // setloading(false)
                }
            })
            .catch(function (error) {
                console.log("error", error.response.data);
                // setloading(false)
            });
    }


    // if (counter >= 1 && counterStart) {
    //     setTimeout(() => {
    //         setcounter(counter - 1)
    //     }, 1000);
    // }


    // console.log('counter---------', counter)

    return (
        <div className="checkbox-form">
            <h3 className='text-center text-md-start'>Forgot password</h3>
            <div className="row">
                {!otpView
                    ?
                    <div className="col-12">
                        <div className="checkout-form-list">
                            <label>Phone</label>
                            <input type="number" placeholder="Enter Your Mobile" disabled={phone ? true : false} name='mobile' onKeyDown={PreventNonNumeric} onWheel={e => e.target.blur()} value={value.mobile} onChange={e => setValue({ ...value, mobile: e.target.value.slice(0, 10) })} />
                        </div>
                    </div>
                    :
                    !isOtpVerified &&
                    <div className="col-12">
                        <div className="checkout-form-list">
                            <label>OTP</label>
                            <input type="number" placeholder="Enter 6 digit OTP" onKeyDown={PreventNonNumeric} onWheel={e => e.target.blur()} value={value.otp} onChange={e => setValue({ ...value, otp: e.target.value.slice(0, 6) })} />
                        </div>
                    </div>
                }

                {isOtpVerified &&
                    <>
                        <div className="col-12">
                            <div className="checkout-form-list">
                                <label>New password </label>
                                <input type="password" placeholder="Enter New password" name='new_password' value={value.new_password} onChange={(e) => setValue({ ...value, new_password: e.target.value })} />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="checkout-form-list">
                                <label>Confirm password </label>
                                <input type="password" placeholder="Enter Confirm password " name='confirm_new_password' value={value.confirm_new_password} onChange={(e) => setValue({ ...value, confirm_new_password: e.target.value })} />
                            </div>
                        </div>
                    </>
                }
                <div className="col-md-12 justify-content-between d-flex">
                    <a onClick={changeView} style={{
                        color: 'var(--bs-primary)',
                        textDecoration: 'underline',
                        fontSize: 'small',
                        cursor: 'pointer'
                    }}>cancel</a>

                    <div>
                        {(otpView && !isOtpVerified) &&
                            <button className='btn btn-primary' style={{ marginRight: '.5rem' }} onClick={ResendOTP} disabled={counterStart} type='button'>{counterStart ? `Resend OTP in ${counter}` : 'Resend OTP'}</button>
                        }
                        {!isOtpVerified ?
                            <button className='btn btn-primary' disabled={otpView && value.otp.length !== 6} onClick={!otpView ? SendOtp : VerifyOtp}>{!otpView ? 'Send OTP' : 'Submit'}</button>
                            :
                            <button className='btn btn-primary' disabled={!value.new_password || !value.confirm_new_password} onClick={handleForgetPassword}>{'change password'}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
const ChangePassword = ({ changeView }) => {
    const navigate = useNavigate()

    const [value, setvalue] = useState({
        current_password: '',
        new_password: '',
        confirm_new_password: ''
    })

    const handleChangePassword = (e) => {
        e.preventDefault()

        let validation = Validation(value, ['current_password', 'new_password', 'confirm_new_password'])

        if (!validation) {
            return
        }
        if (value.new_password !== value.confirm_new_password) {
            ShowFailedMessage(`password does not match`)
            return
        }

        let result = ManagePasswords('patch', value, '')

        result.then((res) => {
            console.log(res)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                setvalue({
                    current_password: '',
                    new_password: '',
                    confirm_new_password: ''
                })
                navigate('/')
            } else {
                ShowFailedMessage(res.data.Message)
            }

        })

            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div className="checkbox-form">
            <h3 className='text-center text-md-start'>Change password</h3>
            <div className="row">
                <div className="col-12">
                    <div className="checkout-form-list">
                        <label>Current password </label>
                        <input type="text" placeholder="Enter current password" name='current_password' value={value.current_password} onChange={(e) => setvalue({ ...value, current_password: e.target.value })} />
                    </div>
                </div>
                <div className="col-12">
                    <div className="checkout-form-list">
                        <label>New password </label>
                        <input type="text" placeholder="Enter New password" name='new_password' value={value.new_password} onChange={(e) => setvalue({ ...value, new_password: e.target.value })} />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="checkout-form-list">
                        <label>Confirm password </label>
                        <input type="text" placeholder="Enter Confirm password " name='confirm_new_password' value={value.confirm_new_password} onChange={(e) => setvalue({ ...value, confirm_new_password: e.target.value })} />
                    </div>
                </div>
                <div className="col-md-12 justify-content-between d-flex">
                    <a onClick={changeView} style={{
                        color: 'var(--bs-primary)',
                        textDecoration: 'underline',
                        fontSize: 'small',
                        cursor: 'pointer'
                    }}>Forgot Password</a>
                    <button className='btn btn-primary' onClick={handleChangePassword}>Update</button>
                </div>
            </div>
        </div>
    )
}

const SignOut = ({ handleSignOut }) => (

    <div className="checkbox-form">
        <div className="d-flex justify-content-between">
            <h3 className='text-center text-md-start'>{'Sign out'}</h3>

        </div>

        <div className="w-100 p-3">

            <div className="w-100">
                <div style={{ fontSize: '15px', fontWeight: '700' }}>Are you sure want to sign out ?</div>
            </div>

            <div className="w-100 d-flex justify-content-end">
                <button onClick={handleSignOut} className='btn btn-danger'>Confirm</button>
            </div>

        </div>

    </div>
)

export const Supervisor = <svg xmlns="http://www.w3.org/2000/svg" width="20.8" height="24.126" viewBox="0 0 24.8 28.126">
    <g id="Group_32" data-name="Group 32" transform="translate(-16 -112.567)">
        <path id="noun-shield-4463013" d="M147.3,25.431a2.862,2.862,0,0,0-1.956-2.715L135.8,19.536a2.866,2.866,0,0,0-1.811,0l-9.538,3.179a2.862,2.862,0,0,0-1.956,2.715V36.254a6.678,6.678,0,0,0,2.973,5.555l7.839,5.226a2.86,2.86,0,0,0,3.175,0l7.839-5.226a6.678,6.678,0,0,0,2.973-5.555Zm-1.908,0V36.254a4.77,4.77,0,0,1-2.123,3.968l-7.84,5.226a.954.954,0,0,1-1.059,0l-7.839-5.226a4.769,4.769,0,0,1-2.124-3.968V25.431a.953.953,0,0,1,.652-.905l9.538-3.179a.946.946,0,0,1,.6,0l9.538,3.179a.953.953,0,0,1,.652.905Z" transform="translate(-106.499 93.177)" fill="#0f0f0f" fillRule="evenodd" />
        <path id="Path_134" data-name="Path 134" d="M4362.4,126.888l3.841,3.371,8.939-8.983" transform="translate(-4340.394 0.863)" fill="none" stroke="#0f0f0f" strokeLinecap="round" strokeWidth={2} />
    </g>
</svg>

export const DownloadSvg = <svg xmlns="http://www.w3.org/2000/svg" width="23.606" height="19.513" viewBox="0 0 23.606 19.513">
    <g id="Icon_feather-download-cloud" data-name="Icon feather-download-cloud" transform="translate(-0.334 -3.461)">
        <path id="Path_224" data-name="Path 224" d="M12,25.5l3.885,3.885L19.769,25.5" transform="translate(-3.707 -7.41)" fill="none" stroke="#0095c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        <path id="Path_225" data-name="Path 225" d="M18,18v8.74" transform="translate(-5.822 -4.766)" fill="none" stroke="#0095c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        <path id="Path_226" data-name="Path 226" d="M20.8,19.148A4.856,4.856,0,0,0,18,10.32H16.781A7.769,7.769,0,1,0,3.437,17.4" transform="translate(0 0)" fill="none" stroke="#0095c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
    </g>
</svg>

export const closeIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20.8" height="20.8" viewBox="0 0 300.928 300.927">
    <g id="Group_589" data-name="Group 589" transform="translate(-8230.771 416.228)">
        <path id="Path_1567" data-name="Path 1567" d="M8243.768-399.593l275.471,275.471" transform="translate(-0.268 -3.907)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="35" />
        <path id="Path_1568" data-name="Path 1568" d="M8519.238-399.593,8243.768-124.122" transform="translate(-0.268 -3.907)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="35" />
    </g>
</svg>
