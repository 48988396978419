import React, { useContext, useEffect, useState } from 'react'
import Img1 from '../../Assets/img/icon/sign/man-1.png'
import Img2 from '../../Assets/img/icon/sign/man-2.png'
import Img3 from '../../Assets/img/icon/sign/circle.png'
import Img4 from '../../Assets/img/icon/sign/zigzag.png'
import Img5 from '../../Assets/img/icon/sign/dot.png'
import Img6 from '../../Assets/img/icon/sign/sign-up.png'
import { Link, useNavigate } from 'react-router-dom'
import { PreventNonNumeric, ShowFailedMessage, ShowSuccessMessage } from '../../Functionalities/functions'
import { Context } from '../../common/Context/Context'
import { UseForm } from '../../common/Hooks/UseForm'
import { ManageSignIn } from './signinLogic'
import { url } from '../../Functionalities/config'


const InitialValue = { mobile: '', password: '' }

const SignIn = () => {
    const { setuser, setToken } = useContext(Context)
    const navigate = useNavigate()

    const [ClearState, setClearState] = useState(false)

    const [LoginData, handleChange] = UseForm(InitialValue, ClearState, setClearState)
    const [passwords, setpasswords] = useState({
        newPassword: '',
        confirmNewPassword: ''
    })
    const [ShowPassord, setShowPassord] = useState(false)
    const [hideSignInSection, sethideSignInSection] = useState(false)
    const [phone, setphone] = useState('')
    const [otp, setotp] = useState('')
    const [otpSend, setotpSend] = useState(false)
    const [otpVerified, setotpVerified] = useState(false)
    const [counter, setcounter] = useState(29)
    const [counterStart, setcounterStart] = useState(false)
    const [countryList, setcountryList] = useState([])
    const [country_id, setcountry_id] = useState('')

    useEffect(() => {

        getCountry()

        const timer = (counter > 1 && counterStart) && setInterval(() => { setcounter(counter - 1) }, 1000);
        if (counter == 1) {
            setcounterStart(false)
            setcounter(29)
        }

        return () => { clearInterval(timer) }
    }, [counter, counterStart])


    const handleSubmit = (e) => {
        e.preventDefault()

        if (LoginData.mobile.length <= 9) {
            ShowFailedMessage('Mobile Number Must be 10')
            return
        }
        if (LoginData.password == '') {
            ShowFailedMessage('password cannot be empty')
            return
        }

        // return console.log({LoginData})
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();
        data.append('username', LoginData.mobile);
        data.append('password', LoginData.password);

        var config = {
            method: 'post',
            url: url + '/user/login/',
            data: data
        };

        axios(config)
            .then(function (res) {
                console.log('login details', res)
                if (res.data.Status) {
                    if (res.data.Data.user_type == 'agent' || res.data.Data.user_type == 'student' || res.data.Data.user_type == 'staff') {
                        ShowSuccessMessage(res.data.Message)
                        localStorage.setItem('user', res.data.Data.user_type)
                        localStorage.setItem('vidya_token', res.data.Data.token)
                        // console.log('1')
                        setuser(res.data.Data.user_type)
                        setToken(res.data.Data.token)
                        // console.log('2')
                        // window.location.replace('/')
                        navigate('/')


                    } else {
                        ShowFailedMessage('Unauthorised user, you dont have permission to access this')
                        return
                    }


                } else {
                    ShowFailedMessage(res.data.Message)
                }
            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const getCountry = () => {
        var axios = require('axios');
        var FormData = require('form-data');

        var config = {
            method: 'get',
            url: url + '/country/?pagination=0&is_active=1',
            data: {}
        };

        // axios(config)
        //     .then(function (response) {
        //         setcountryList(response.data)
        //         console.log(JSON.stringify(response.data));
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });


    }

    const handleBack = () => {

        sethideSignInSection(false)
        setotpVerified(false);
        setotpSend(false);
        setClearState(true)
        setphone('')
        setotp('')
        setotpSend(false)
        setotpVerified(false)
    }


    const SendOtp = () => {

        var axios = require('axios');
        var FormData = require('form-data');
        var fdata = new FormData();
        fdata.append('mobile', phone);
        fdata.append('forgot', 1);

        var config = {
            method: 'post',
            url: url + '/notifications/otp/',
            data: fdata
        };
        axios(config)
            .then(function (response) {
                console.log(response.data)
                if (response.data.Status) {
                    setotpSend(true)
                } else {
                    setotpSend(false)
                    ShowFailedMessage(response.data.Error)

                }

            })
            .catch(function (error) {
                setotpSend(false)
                console.log(error);
            });
    }

    const VerifyOtp = () => {
        var axios = require('axios');
        var FormData = require('form-data');
        var formdata = new FormData();
        formdata.append('mobile', phone);
        formdata.append('otp', otp);
        var config = {
            method: 'post',
            url: url + '/notifications/verify/',

            data: formdata
        };
        axios(config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.Status) {
                    setotpVerified(true)

                } else {
                    setotpVerified(false)
                    ShowFailedMessage(response.data.Error)
                }
            })
            .catch(function (error) {
                console.log(error);
                setotpVerified(false)

            });
    }
    const ResendOTP = (e) => {
        e.preventDefault()
        setcounterStart(true)
        SendOtp()

    }
    const handleForgetPassword = () => {

        if (passwords.newPassword !== passwords.confirmNewPassword) {
            ShowFailedMessage('passwords does not match')
            return
        }

        var axios = require('axios');
        var FormData = require('form-data');
        var formdata = new FormData();
        formdata.append('mobile', phone);
        formdata.append('new_password', passwords.newPassword);
        formdata.append('re_password', passwords.confirmNewPassword);

        var config = {
            method: 'patch',
            url: url + '/user/forgot_password/',
            data: formdata
        };
        console.warn("check all data", phone, passwords.newPassword, passwords.confirmNewPassword);

        axios(config)
            .then(function (response) {
                console.log("check forgot", response.data);
                if (response.data.Status) {
                    ShowSuccessMessage(response.data.Message)
                    // navigate('/sign-in')
                    sethideSignInSection(false)
                    setotpVerified(false);
                    setotpSend(false);
                    setotp('')
                    setpasswords({ newPassword: '' })
                    setpasswords({ confirmNewPassword: '' })
                    // setloading(false)
                } else {
                    ShowFailedMessage(response.data.Message)
                    // setloading(false)
                }
            })
            .catch(function (error) {
                console.log("error", error.response.data);
                // setloading(false)
            });
    }

    return (
        <section className="signup__area po-rel-z1 pt-100 pb-145">
            <div className="sign__shape">
                <img className="man-1" src={Img1} alt="img not found" />
                <img className="man-2" src={Img2} alt="img not found" />
                <img className="circle" src={Img3} alt="img not found" />
                <img className="zigzag" src={Img4} alt="img not found" />
                <img className="dot" src={Img5} alt="img not found" />
                <img className="bg" src={Img6} alt="img not found" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
                        <div className="section__title-wrapper text-center mb-55">
                            <h2 className="section__title">Sign In<br /> </h2>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </p> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                        <div className="sign__wrapper white-bg">
                            {/* <div className="sign__header mb-35">
                                <div className="sign__in text-center"><a href="#" className="sign__social text-start mb-15"><i><svg aria-hidden="true" width={11} focusable="false" data-prefix="fab" data-icon="facebook-f" className="svg-inline--fa fa-facebook-f fa-w-10 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
                                    </path>
                                </svg></i></a>
                                    <p> <span>........</span> Or, <a href="sign-in.html">sign in</a> with your
                                        email<span> ........</span> </p>
                                </div>
                            </div> */}
                            <div className="sign__form">
                                <form method='POST'>
                                    {!hideSignInSection ?
                                        <>
                                            <div className="sign__input-wrapper mb-25">
                                                <h5>Mobile </h5>
                                                {/* <select>
                                                    <option value="India">India (+91)</option>
                                                    <option value="USA">USA (+1)</option>
                                                    <option value="UK">UK (+44)</option>
                                                    <option value="Canada">Canada (+1)</option>
                                                    <option value="Australia">Australia (+61)</option>
                                                </select> */}

                                                {/* <div className="col-md-12">
                                                    <div className="country-select">
                                                        <select onChange={(e) => setcountry_id(e.target.value)} value={country_id}>
                                                            <option value="">Select Country</option>
                                                            {countryList.map((item, index) => <option value={item.id}> {item.name} (+{item.calling_code})</option>)}
                                                        </select>
                                                    </div>
                                                </div> */}


                                                <div className="sign__input">

                                                    <input type="number" onKeyDown={PreventNonNumeric} onWheel={e => e.target.blur()} placeholder="Mobile Number" name={'mobile'} onChange={handleChange} value={LoginData.mobile} /><i>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height={11} fill='#8e8c94' viewBox="0 0 320 512">
                                                            <path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z" />
                                                        </svg>
                                                    </i></div>
                                                {/* {country_id && LoginData.mobile ? <small>OTP will be send to : +{countryList.filter(item => item.id == country_id)[0].calling_code}  {LoginData.mobile}</small> : <small>Please enter mobile number</small>} */}
                                            </div>
                                            <div className="sign__input-wrapper mb-10">
                                                <h5>Password</h5>
                                                <div className="sign__input">
                                                    <input type={ShowPassord ? 'text' : "password"} placeholder="Password" name={'password'} onChange={handleChange} value={LoginData.password} /><i><svg aria-hidden="true" width={11} focusable="false" data-prefix="fas" data-icon="lock" className="svg-inline--fa fa-lock fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                        <path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z">
                                                        </path>
                                                    </svg></i>
                                                    <span className='password-eye-container' >
                                                        {ShowPassord ?
                                                            <button type='button' className='btn' onClick={() => setShowPassord(!ShowPassord)} >
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={18} fill={'current-color'}>
                                                                    <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
                                                                </svg>
                                                            </button>

                                                            :
                                                            <button type='button' className='btn' onClick={() => setShowPassord(!ShowPassord)} >
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={18} fill={'current-color'}>
                                                                    <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                                                                </svg>
                                                            </button>
                                                        }

                                                    </span>
                                                </div>
                                            </div>

                                            <div className="sign__action d-sm-flex justify-content-between mb-30">
                                                {/* <div className="sign__agree d-flex align-items-center"><input type="checkbox" className="m-check-input" id="m-agree" /><label className="m-check-label" htmlFor="m-agree">Keep me signed in</label></div> */}
                                                <div className="sign__forgot"><a style={{ cursor: 'pointer' }} onClick={() => { sethideSignInSection(true); otpVerified(false); otpSend(false); }}>Forgot your password?</a></div>
                                            </div><button className="btn btn-primary w-100" type='button' onClick={handleSubmit}> <span /> Sign In</button>
                                            <div className="sign__new text-center mt-20">
                                                <p>Don't have an account? <Link to="/sign-up">Sign Up</Link></p>
                                            </div>
                                        </>
                                        :

                                        <>
                                            <div className="sign__new  mb-20">
                                                <button type='button' className='btn d-flex align-items-center' onClick={handleBack}>
                                                    <svg width={15} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                        <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" /></svg>
                                                    <span style={{ marginLeft: '.5rem' }}>Cancel</span>
                                                </button>
                                            </div>

                                            {!otpVerified ?
                                                <>

                                                    <div className="sign__input-wrapper mb-25">
                                                        <h5>Mobile 2</h5>
                                                        <div className="sign__input">
                                                            <input type="text" onKeyDown={PreventNonNumeric} value={phone.slice(0, 10)} onChange={e => setphone(e.target.value.slice(0, 10))} /><i>
                                                                <svg xmlns="http://www.w3.org/2000/svg" height={11} fill='#8e8c94' viewBox="0 0 320 512">
                                                                    <path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z" />
                                                                </svg>
                                                            </i></div>
                                                    </div>
                                                    {otpSend &&
                                                        <div className="sign__input-wrapper mb-10">
                                                            <h5>OTP</h5>
                                                            <div className="sign__input">
                                                                <input type="number" placeholder="Enter 6 digits OTP" onWheel={e => e.target.blur()} value={otp.slice(0, 6)} onChange={(e) => setotp(e.target.value)} />
                                                                <i><svg aria-hidden="true" width={11} focusable="false" data-prefix="fas" data-icon="lock" className="svg-inline--fa fa-lock fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                    <path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z">
                                                                    </path>
                                                                </svg></i></div>
                                                        </div>
                                                    }
                                                </>
                                                :
                                                <>

                                                    <div className="sign__input-wrapper mb-25">
                                                        <h5>New Password</h5>
                                                        <div className="sign__input">
                                                            <input type="text" placeholder='Enter New Password' value={passwords.newPassword} onChange={(e) => setpasswords({ ...passwords, newPassword: e.target.value })} name={'newPassword'} /><i>
                                                                <svg aria-hidden="true" width={11} focusable="false" data-prefix="fas" data-icon="lock" className="svg-inline--fa fa-lock fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                    <path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z">
                                                                    </path>
                                                                </svg></i></div>
                                                    </div><div className="sign__input-wrapper mb-25">
                                                        <h5>Re-enter New Password</h5>
                                                        <div className="sign__input">
                                                            <input type="text" placeholder='Confirm New Password' value={passwords.confirmNewPassword} onChange={(e) => setpasswords({ ...passwords, confirmNewPassword: e.target.value })} name={'confirmNewPassword'} /><i>
                                                                <svg aria-hidden="true" width={11} focusable="false" data-prefix="fas" data-icon="lock" className="svg-inline--fa fa-lock fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                    <path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z">
                                                                    </path>
                                                                </svg></i></div>
                                                    </div>
                                                </>
                                            }
                                            <div className="sign__action d-sm-flex justify-content-between mb-30">
                                                {/* <div className="sign__agree d-flex align-items-center"><input type="checkbox" className="m-check-input" id="m-agree" /><label className="m-check-label" htmlFor="m-agree">Keep me signed in</label></div> */}
                                                {/* <div className="sign__forgot"><a onClick={() => sethideSignInSection(false)}>Back</a></div> */}
                                            </div>
                                            {!otpSend ?
                                                <button disabled={phone.length <= 9 ? true : false} className="e-btn  w-100" style={{ textTransform: 'uppercase' }} type={'button'} onClick={SendOtp}> <span /> Send Otp</button>
                                                :
                                                <>
                                                    {otpVerified
                                                        ?

                                                        <button className='btn btn-primary w-100' onClick={handleForgetPassword} type='button'>{'Submit'}</button>
                                                        :
                                                        <>
                                                            <button className={"btn btn-primary  w-100 "} disabled={otp.length > 0 ? false : true} type={'button'} onClick={VerifyOtp} > <span /> Submit</button>
                                                            <button className='btn btn-primary-outline w-100' style={{ marginRight: '.5rem' }} onClick={ResendOTP} disabled={counterStart} type='button'>{counterStart ? `Resend OTP in ${counter}` : 'Resend OTP'}</button>
                                                        </>
                                                    }
                                                </>

                                            }
                                            <div className="sign__new text-center mt-20">
                                                {hideSignInSection ?
                                                    <p>Back to <Link to="/sign-in" onClick={() => { sethideSignInSection(false); }}>Sign in</Link></p>

                                                    :
                                                    <p><Link to="/sign-up">Sign Up</Link></p>
                                                }
                                            </div>
                                        </>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >

    )
}

export default SignIn