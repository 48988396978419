import moment from 'moment'
import React, { memo, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { restrictWhiteSpace, ShowFailedMessage, ShowSuccessMessage } from '../../Functionalities/functions'
import './commentBox.css'
import { ManageMessages } from './CommentsLogic'


const CommentSection = ({ id }) => {
    const userContainer = useRef([])
    const AdminContainer = useRef([])

    const [Message, setMessage] = useState('')
    const [data, setdata] = useState([])

    useEffect(() => {

        GetMessages()


    }, [id])


    const GetMessages = () => {
        let params = `application_id=${id}`
        let result = ManageMessages('get', '', params)

        result.then((res) => {
            console.log(res)
            if (res.data.results) {
                setdata(res.data.results)
            }
        })
            .catch((err) =>
                console.log(err)
            )
    }

    const handleSubmit = () => {



        let result = ManageMessages('add', { Message, id }, '')

        result.then((res) => {
            console.log(res)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                GetMessages()
                setMessage('')
            } else {
                ShowFailedMessage(res.data.Message)
            }
        })

            .catch((err) => {
                console.log(err)
            })
    }

    const handleKeyPress = (e) => {
        if (e.key == 'Enter') {
            e.preventDefault()
            handleSubmit()

        }
    }

    return (
        <div class='col-lg-12'>
            <div class="course__item white-bg mb-30 fix">
                <div className="row gx-0">

                    <div className="col-xxl-12 col-xl-12 col-lg-12">
                        <div className="course__right">
                            <div className="course__content course__content-3 p-4">
                                <div className="course__meta d-flex align-items-center justify-content-between">
                                    <div className="course__lesson mr-20">

                                    </div>
                                    <div className="course__lesson mr-20">

                                    </div>

                                </div>
                                <h5 className="course__title course__title-4">
                                    <a >Comments</a>
                                </h5>

                                <div className="course__sidebar-search mb-4">
                                    <form action="#" style={{ position: 'relative' }}>
                                        <input type="text" placeholder="Type message" onChange={e => { setMessage(e.target.value) }} value={Message} onKeyPress={handleKeyPress} onKeyDown={(e) => restrictWhiteSpace(e, Message)} />
                                        {Message.length > 0 &&
                                            <button type="button" className='btn' onClick={handleSubmit} >
                                                <i>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={15}>
                                                        <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" /></svg></i>

                                            </button>
                                        }
                                    </form>
                                </div>


                                {data.map((item, idx) => (
                                    <>
                                        {item.fr_om == 'User' &&
                                            <>

                                                <div className="row align-items-end mx-0 mt-3" style={{ flexDirection: 'column', position: 'relative' }}>

                                                    <div className="p-2 mb-4  message-box" ref={(el) => AdminContainer.current[idx] = el} style={{ background: 'aliceblue' }} >
                                                        {item.content}
                                                        <span className='p-2 ' style={
                                                            { marginLeft: '1rem', fontWeight: '400', fontSize: '.7rem', position: 'absolute', right: 0, bottom: '-12px', width: 'max-content' }}>
                                                            {moment(item.created_date).calendar()}
                                                        </span>
                                                    </div>


                                                    <div>
                                                    </div>
                                                </div>

                                            </>
                                        }

                                        {item.fr_om == 'Admin' &&
                                            <div className="row justify-content-start mx-0 mt-3" style={{ flexDirection: 'column', position: 'relative' }} >
                                                <div className="p-2 mb-4  message-box" style={{ background: 'bisque' }} ref={(el) => userContainer.current[idx] = el} >
                                                    {item.content}
                                                    <span className=' p-2 ' style={
                                                        { fontWeight: '400', fontSize: '.7rem', position: 'absolute', left: 0, bottom: '-12px', width: 'max-content' }}>
                                                        {moment(item.created_date).calendar()}
                                                    </span>
                                                </div>


                                            </div>
                                        }
                                    </>
                                ))}
                                {/* <hr />
                                <div className='d-flex justify-content-center'>
                                    <a href="">View all message</a>
                                </div> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
export default CommentSection