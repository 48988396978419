import React, { useEffect } from 'react'
import Course from './Course'
import HeroSection from './HeroSection'
import ServiceSection from './ServiceSection'
import { Link } from 'react-router-dom'
import ScrollContainer from 'react-indiana-drag-scroll'
import IOS from "../../Assets/img/ios.png"
import Android from "../../Assets/img/android.png"
import MobileDetect from 'mobile-detect'


const Android_URL = "https://play.google.com/store/apps/details?id=com.codedady.vidyaportal"
const IOS_URL = "https://apps.apple.com/in/app/vidya-portal/id1618585775"

function Redirection() {
    
    


    const countires = [
        { name: 'Canada', bgImg: ""},
        { name: 'Ireland', bgImg: ""},
        { name: 'United Kingdom', bgImg: ""},
        { name: 'Australia', bgImg: ""},
        { name: 'USA', bgImg: ""},
        { name: 'Singapore', bgImg: ""},
    ]

    useEffect(() => {
        let type = new MobileDetect(window.navigator.userAgent)
    
        if (type.os() === "iOS") {
            console.log("IOS User")
            window.location.href = IOS_URL
            
        } else if (type.os() === "AndroidOS") {
            console.log("Android User")
            window.location.href = Android_URL
        }
        else{
            console.log("Nothing happend")
        }
    
    }, [])
    


    return (
        <main>
            <section className="services__area pt-115 pb-115">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3">
                            <div className="section__title-wrapper section-padding mb-60 text-center">
                                {/* <h1>Hi {type.os()} User</h1> */}
                                <h2 className="section__title">We will redirect  <span className="yellow-bg">you shortly  </span>
                                    ...</h2>
                                <small>No need of waiting choose your download below</small>
                                
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">

                        {/* {countires.map(item => (

                            <> */}
                                <div className="d-flex justify-content-center col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 ">
                                    <a
                                    // to={{ pathname: "https://play.google.com/store/apps/details?id=com.codedady.vidyaportal" }}
                                    // to='https://play.google.com/store/apps/details?id=com.codedady.vidyaportal'
                                    target="_blank"
                                    href={Android_URL}
                                     >

                                        {/* <div className="services__item xblue-bg-4 mb-30 country-img" style={{
                                            background: IOS,

                                        }}>
                                            IOS
                                        </div> */}
                                        <img  src={Android} height="100" />
                                    </a>
                                </div>
                                <div className="d-flex justify-content-center col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 ">
                                    <a 
                                    href={IOS_URL}
                                    target="_blank"
                                    >

                                        <img src={IOS} height="100"/>
                                    </a>
                                    
                                </div>
                            {/* </>
                        ))} */}

                        {/* <ScrollContainer horizontal={true} vertical={false} className="scroll-container d-flex d-sm-none">

                            {countires.map(item => (
                                <>

                                    <Link to='/university' state={{ country: item.name == 'United Kingdom' ? 'United' : item.name }}>

                                        <div className='px-2'>
                                            <div className="services__item blue-bg-4 mb-30 country-img" style={{
                                                background: `url(${item.bgImg})`,
                                                width: '14rem'
                                            }}>
                                                {item.name}
                                            </div>
                                        </div>
                                    </Link>

                                </>
                            ))}
                        </ScrollContainer> */}


                    </div>
                </div>
            </section >
        </main >
    )
}

export default Redirection