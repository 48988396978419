import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Playstore from '../../Assets/img/icon/sign/playstore.png'
import Appstore from '../../Assets/img/icon/sign/appstore.png'
import Web from '../../Assets/img/icon/sign/web.png'

const style = {
    // position: 'absolute',
    // top: '25%',
    // left: '30%',
    // transform: 'translate(30%, 30%, 0%)',
    // width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 5,
};

export default function ConfirmationModal(props) {
    // const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);
    // const {t} = useTranslation()

    return (
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12"
      
        >

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.ModalOpen}
                // BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                style={{ display:'flex' ,justifyContent:'center', alignItems:'center'}}
            >
                <Fade in={props.ModalOpen}>
                    <Box sx={style} style={{ border: 'none', borderRadius: 10 }}>
                        <Typography id="transition-modal-title" variant="h6" component="h2" align='center'>
                            It is much easier with mobile applications
                        </Typography>
                        <Typography id="transition-modal-description" variant="body2" sx={{ mt: 2 }} align='center'>
                            Download our mobile application to easly apply for the course you love
                        </Typography>
                        <div className="d-flex justify-content-center mt-3">
                            <a href={'https://apps.apple.com/in/app/vidya-portal/id1618585775'} className="btn btn-secondary2" style={{justifyContent: 'center', alignItems: 'center'}} onClick={props.onClose} >
                                <img src={Appstore} style={{height: 30, width: 30, marginRight:'10px'}} alt="" />
                                Download IOS Application
                            </a>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <a href={'https://play.google.com/store/apps/details?id=com.codedady.vidyaportal'} className="btn btn-secondary2" style={{justifyContent: 'center', alignItems: 'center'}} onClick={props.onClose} >
                                <img src={Playstore} style={{height: 30, width: 30, marginRight:'10px'}} alt="" />
                                Download Android Application
                            </a>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <a href={'/sign-in'} className="btn btn-secondary2" style={{justifyContent: 'center', alignItems: 'center'}} onClick={props.onClose} >
                                <img src={Web} style={{height: 30, width: 30, marginRight:'10px'}} alt="" />
                                Continue with Website
                            </a>
                        </div>
                        <div className="shadow-box-example z-depth-5"></div>
                        <Typography id="transition-modal-description"  variant="body2" sx={{ mt: 2 }} align='center'>
                            *You can fully access the service we provide with the website too
                        </Typography>
                    </Box>
                </Fade>

            </Modal>
        </div>
    );
}
