import { DELETE_API, GET_API, POST_API, PUT_API } from "../Functionalities/APIS"

export const ManageCountries = async (keyword, data, params) => {
    let path = '/filter-country/'
    if (keyword == "get") {
        let result = GET_API("", params, path)
        return await result.then((response) => {
            if (response) {
                return response
            } else {

                return 0
            }
        })
    }
}

