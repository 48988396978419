import logo from './logo.svg';
import './App.css';
import Router from './Router';
import './Styles/c2817adebe74aa9d569e.css'
import './Styles/e93ffd0fd4ff00993878.css'
import './Styles/ecbcc13824f5a3f2e1b9.css'
import './Styles/f8fc90abf546df04bbcb.css'
import './Styles/fdb73d5e2cdd19ea0883.css'
import Toast from './Components/Toast/Toast';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
  return (
    <>
      <Toast />
      <Router />
    </>
  );
}

export default App;
