import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Img5 from '../../Assets/img/course/teacher/teacher-1.jpg'
import { Context } from '../../common/Context/Context'

const UniversityDetail = (props) => {
    const { user } = useContext(Context)
    const { pathname } = useLocation()

    // console.log(props.selectedCourse.includes(props.CourseData.id))
    // console.log(props.CourseData.id)
    // console.log({user})

    return (

        <div className="course__item white-bg fix" style={props.isSelectActive ? { border: `1px solid ${props.selectedCourse?.includes(props.CourseData.id) ? '#0095c7' : ' #dbdbdb'}` } : {}}>
            <div className="course__item white-bg fix" >
                <div className="course__thumb w-img p-relative fix">

                    <Link to={`/university-details/${props.id}`}>
                        {props.image &&
                            <img src={props.image} style={{
                                height: '220px',
                                objectFit: 'cover'
                            }} />
                        }
                    </Link>
                    <div className="course__tag"><a className='text-white'>{props.country ? props.country == 'United' ? 'UK' : props.country : props.level ? props.level : ''}</a></div>
                </div>
                <div className="course__content">
                    {props.courseName &&
                        <div className="course__meta d-flex align-items-center justify-content-between">
                            <div className="course__lesson d-flex justify-content-between w-100" style={{ flexWrap: 'wrap' }}>
                                <span style={{ marginRight: '1rem' }}><i>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="book" className="svg-inline--fa fa-book fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ width: '0.875em' }}>
                                        <path fill="currentColor" d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z">
                                        </path>
                                    </svg></i>{props.duration}</span>

                                <span>
                                    Fee: {props.fees}
                                </span>
                            </div>

                        </div>
                    }
                    <h3 className="course__title">
                        {props.name &&
                            <Link to={pathname == '/university' ? `/university-details/${props.id}` : ''}>{props.name}</Link>
                        }
                        {
                            props.courseName && `${props.courseName} (${props.level})`
                        }
                    </h3>
                    {props.courseName &&
                        <>
                            <div className="course__teacher d-flex" style={{
                                flexDirection: 'column',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                {/* <div className="course__teacher-thumb mr-15"><img src={Img5} alt="img not found" /></div> */}
                                <h6 className='mb-2'><a > <svg svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 640 512" width={20} style={{ marginRight: '.5rem' }}>
                                    <path d="M484.6 62C502.6 52.8 522.6 48 542.8 48H600.2C627.2 48 645.9 74.95 636.4 100.2C618.2 148.9 582.1 188.9 535.6 212.2L262.8 348.6C258.3 350.8 253.4 352 248.4 352H110.7C101.4 352 92.5 347.9 86.42 340.8L13.34 255.6C6.562 247.7 9.019 235.5 18.33 230.8L50.49 214.8C59.05 210.5 69.06 210.2 77.8 214.1L135.1 239.1L234.6 189.7L87.64 95.2C77.21 88.49 78.05 72.98 89.14 67.43L135 44.48C150.1 36.52 169.5 35.55 186.1 41.8L381 114.9L484.6 62zM0 480C0 462.3 14.33 448 32 448H608C625.7 448 640 462.3 640 480C640 497.7 625.7 512 608 512H32C14.33 512 0 497.7 0 480z" />
                                </svg>
                                    {props.intakes}</a></h6>
                                <h6 className='mb-2'><a > <i><svg width={15} aria-hidden="true" style={{ marginRight: '.5rem' }} focusable="false" data-prefix="fas" data-icon="book" className="svg-inline--fa fa-book fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z" /></svg>
                                </i>
                                    {props.University?.name}</a></h6>
                                <h6 className='mb-2'><a >

                                    <svg xmlns="http://www.w3.org/2000/svg" width={15} fill="currentColor" style={{ marginRight: '.5rem' }} viewBox="0 0 512 512">
                                        <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM57.71 192.1L67.07 209.4C75.36 223.9 88.99 234.6 105.1 239.2L162.1 255.7C180.2 260.6 192 276.3 192 294.2V334.1C192 345.1 198.2 355.1 208 359.1C217.8 364.9 224 374.9 224 385.9V424.9C224 440.5 238.9 451.7 253.9 447.4C270.1 442.8 282.5 429.1 286.6 413.7L289.4 402.5C293.6 385.6 304.6 371.1 319.7 362.4L327.8 357.8C342.8 349.3 352 333.4 352 316.1V307.9C352 295.1 346.9 282.9 337.9 273.9L334.1 270.1C325.1 261.1 312.8 255.1 300.1 255.1H256.1C245.9 255.1 234.9 253.1 225.2 247.6L190.7 227.8C186.4 225.4 183.1 221.4 181.6 216.7C178.4 207.1 182.7 196.7 191.7 192.1L197.7 189.2C204.3 185.9 211.9 185.3 218.1 187.7L242.2 195.4C250.3 198.1 259.3 195 264.1 187.9C268.8 180.8 268.3 171.5 262.9 165L249.3 148.8C239.3 136.8 239.4 119.3 249.6 107.5L265.3 89.12C274.1 78.85 275.5 64.16 268.8 52.42L266.4 48.26C262.1 48.09 259.5 48 256 48C163.1 48 84.4 108.9 57.71 192.1L57.71 192.1zM437.6 154.5L412 164.8C396.3 171.1 388.2 188.5 393.5 204.6L410.4 255.3C413.9 265.7 422.4 273.6 433 276.3L462.2 283.5C463.4 274.5 464 265.3 464 256C464 219.2 454.4 184.6 437.6 154.5H437.6z" />
                                    </svg>

                                    {props.University?.country  == 'United' ? 'United Kingdom':props.University?.country}</a></h6>
                            </div>


                        </>
                    }
                </div>
                {props.PrimaryBtnFunc &&
                    <div className="course__more d-flex justify-content-between align-items-center">
                        {/* <div className="course__status"><span>Free</span></div> */}
                        <div className="course__btn">
                            <button onClick={props.PrimaryBtnFunc} className="link-btn btn" >{props.PrimaryBtnName}<i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                                </path>
                            </svg></i><i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                                </path>
                            </svg></i></button></div>
                    </div>
                }
                {props.pageUrl &&
                    <div className="course__more d-flex justify-content-between align-items-center">
                        {/* <div className="course__status"><span>Free</span></div> */}
                        <div className="course__btn">
                            {props.isSelectActive ?

                                <div>
                                    <input type="checkbox" onChange={props.handleSelect} checked={props.selectedCourse.includes(props.courseId) ? true : false} />
                                </div>
                                :
                                <Link to={props.pageUrl} state={user ? props.CourseData : '/sign-in'} className="link-btn btn" >{props.PrimaryBtnName}<i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                                    </path>
                                </svg></i><i><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                                    </path>
                                </svg></i></Link>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )

}


export default UniversityDetail