import React, { useEffect, useState } from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    useLocation,
} from "react-router-dom"; import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header'
import { Context } from './common/Context/Context';
import About from './Views/About/About';
import { ApplyCourse } from './Views/ApplyCourse/ApplyCourse';
import Courses from './Views/Course/Courses';
import Course from './Views/Home/Course';

import Home from './Views/Home/Home';
import ApplicationDetails from './Views/MyApplication/ApplicationDetails';
import { MyApplication } from './Views/MyApplication/MyApplication';
import { Profile, } from './Views/Profile/profile';
import SignIn from './Views/SignIn/SignIn';
import SignUp from './Views/SignUp/SignUp';
import University from './Views/University/University';
import UniversityDetails from './Views/University/UniversityDetails';
import ScrollTop from './ScrollTop';
import ErrorPage from './Views/404/ErrorPage';
import { ManageApplications } from './Views/ApplyCourse/ApplicationLogic';
import Redirection from './Views/Home/Redirection';
import Blogs from './Views/Blogs/Blogs';
import BlogDetails from './Views/Blogs/BlogDetails';
import Counselling from './Views/Counselling/Counselling';
import { url } from './Functionalities/config';

const getIsMobile = () => window.innerWidth <= 991;

const Router = () => {

    const [user, setuser] = useState('')
    const [isMobile, setIsMobile] = useState(getIsMobile());
    const [StudentUploadedDocs, setStudentUploadedDocs] = useState([])
    const [token, setToken] = useState('')
    const [Supervisor, setSupervisor] = useState('')
    const [usersPath, setusersPath] = useState([
        { path: '/', component: <Home /> },
        { path: '/university', component: <University /> },
        { path: '/university-details/:id', component: <UniversityDetails /> },
        { path: '/course', component: <Courses /> },
        { path: '/apply-course', component: <ApplyCourse /> },
        { path: '/add-new-application', component: <ApplyCourse /> },
        { path: '/my-application', component: <MyApplication /> },
        { path: '/applications', component: <MyApplication /> },
        { path: '/profile', component: <Profile /> },
        { path: '/application-detail/:id', component: <ApplicationDetails /> },
        { path: '/blog', component: <Blogs /> },
        { path: '/blog-details/:id', component: <BlogDetails /> },
        { path: '/counselling', component: <Counselling /> },
    ])
    const [guestPath, setguestPath] = useState([
        { path: '/', component: <Home /> },
        { path: '/sign-up', component: <SignUp /> },
        { path: '/sign-up/?refer=test', component: <SignUp /> },
        { path: '/sign-in', component: <SignIn /> },
        { path: '/university', component: <University /> },
        { path: '/university-details/:id', component: <UniversityDetails /> },
        { path: '/course', component: <Courses /> },
        { path: '/blog', component: <Blogs /> },
        { path: '/blog-details/:id', component: <BlogDetails /> },
        { path: '/counselling', component: <Counselling /> },
    ])
    useEffect(() => {
        if (localStorage.getItem('user')) {
            setuser(localStorage.getItem('user'))
        }

    }, [setuser])


    useEffect(() => {
        const onResize = () => {
            setIsMobile(getIsMobile());
        }

        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        }
    })

    useEffect(() => {
        if (!token) {
            setToken(localStorage.getItem('vidya_token'))
        }
        if (!user) {
            setuser(localStorage.getItem('user'))
        }

    }, [])

    useEffect(() => {
        user == 'student' && GetApplication()
    }, [user])

    const GetApplication = () => {

        let params = ''

        // return
        let result = ManageApplications('get', '', params)

        result.then((res) => {
            console.log('applications--------->', res)
            // setClearFilter(false)
            if (res.data.results.length >= 1) {
                let result = res.data.results
                let up_docs = []

                for (let i = 0; i < result.length; i++) {
                    // const element = array[i];
                    for (let j = 0; j < result[i].documents.length; j++) {
                        // console.log(result[i].documents[j])
                        // const element = array[j];
                        up_docs.push(result[i].documents[j])

                    }

                    setStudentUploadedDocs(up_docs)

                }
            } else {

            }
        })

            .catch((err) => {
                console.log(err)
            })
    }


    useEffect(() => {
        getSupervisor()
    },[token])

    const getSupervisor = () => {
        var axios = require('axios');
        var config = {
        method: 'get',
        url: url+`/user/super/`,
        headers: {
            Authorization: token? token : ''
        }
        };
        axios(config)
        .then(function (response) {
            console.log("supervsr--------",response.data);
            setSupervisor(response.data[0])
        })
        .catch(function (error) {
            console.log("err",error);
        });
    }



    console.log({ token })

    // alert(user)

    return (
        <Context.Provider
            value={{
                user, setuser,
                isMobile, setIsMobile,
                usersPath, guestPath,
                token, setToken,
                StudentUploadedDocs
            }}
        >


            <BrowserRouter>
                <ScrollTop />
                {window.location.pathname=="/redirecting"? "":<Header />}
                <Routes>
                    {(token && (user == 'agent' || user == 'student' || user == 'staff')) ?
                        <>

                            <Route path='/' element={<Home />} />
                            <Route path='/blog' element={<Blogs />} />
                            <Route path='/blog-details/:id'  element={<BlogDetails /> } />
                            <Route path='/counselling' element={<Counselling />} />
                            <Route path='/university' element={<University />} />
                            <Route path='/university-details/:id' element={<UniversityDetails />} />
                            <Route path='/course' element={<Courses />} />
                            <Route path='/apply-course' element={<ApplyCourse />} />
                            <Route path='/add-new-application' element={<ApplyCourse />} />
                            <Route path='/my-application' element={<MyApplication />} />
                            <Route path='/applications' element={<MyApplication />} />
                            <Route path='/profile' element={<Profile />} />
                            <Route path='/application-detail/:id' element={<ApplicationDetails />} />


                        </>
                        :
                        <>
                            {guestPath.map(item => (

                                <Route path={item.path} element={item.component} />
                            ))}
                        </>
                    }
                    {/* {setTimeout(() => { */}
                    <Route path='/redirecting' element={<Redirection />} />

                    <Route exact path={'*'} element={<ErrorPage />} />
                    {/* }, 1000)} */}

                </Routes>
                <Footer Supervisor={Supervisor}/>
            </BrowserRouter>
        </Context.Provider>
    )
}

export default Router